import { documentService } from '../../../services';

export function flattenObjectWithAliasKeys(data, parentKey = '', result = {}) {
    Object.keys(data).forEach((key) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        const alias = parentKey
            ? `${parentKey.replace(/\./g, '_')}_${key}`
            : key;

        if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
                flattenObjectWithAliasKeys(item, `${newKey}`, result);
            });
        } else if (typeof data[key] === 'object' && data[key] !== null) {
            flattenObjectWithAliasKeys(data[key], newKey, result);
        } else {
            result[alias] = data[key];
        }
    });

    return { result };
}

export const transformObject = (inputObj) => {
    const result = {};

    Object.entries(inputObj).forEach(([key, value]) => {
        if (
            ['progress', 'task_updates_progress'].includes(key) &&
            value >= 0 &&
            value <= 1
        ) {
            // Convert progress values to percentages
            result[key] = `${(value * 100).toFixed(2)}%`;
        } else if (key === 'lifetime_duration') {
            // Convert lifetime duration to human-readable format
            const seconds = value;
            const years = Math.floor(seconds / 31536000);
            const days = Math.floor((seconds % 31536000) / 86400);
            const hours = Math.floor((seconds % 86400) / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = Math.floor(seconds % 60);

            const parts = [];
            if (years) parts.push(`${years} year${years > 1 ? 's' : ''}`);
            if (days) parts.push(`${days} day${days > 1 ? 's' : ''}`);
            if (hours) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
            if (minutes)
                parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
            if (secs) parts.push(`${secs} second${secs > 1 ? 's' : ''}`);

            result[key] = parts.join(', ') || '0 seconds';
        } else {
            result[key] = value;
        }
    });

    return result;
};

export function parseComplianceData(data) {
    const flattened = [];

    data?.forEach((record) => {
        const complianceGroupName = record.name;
        const compliacneGroupCreateAt = record.createdAt;

        record.complianceCategories.forEach((category) => {
            const categoryName = category.name;
            const categoryCreateAt = record.createdAt;

            category.complianceTypes.forEach(async (compliance) => {
                const complianceDocs =
                    await documentService.getComplianceTypeDocuments(
                        compliance.id,
                        'compliance',
                        '',
                        ''
                    );

                flattened.push({
                    'Comliance Group Name': complianceGroupName,
                    'Comliance Group Created At': compliacneGroupCreateAt,
                    'Comliance Category Name': categoryName,
                    'Comliance Category Created At': categoryCreateAt,
                    'Comliance Type Name': compliance.name,
                    'Comliance Type applicable': compliance.applicable,
                    'Comliance Type completed': compliance.completed,
                    documents: complianceDocs,
                });
            });
        });
    });

    return flattened;
}

const MAX_CELL_LENGTH = 32767;

export const splitArrayByTextLength = (arr) => {
    const joinedString = arr.map((link) => link.downloadLink).join('\n');
    if (joinedString.length <= MAX_CELL_LENGTH) {
        return [arr, []];
    }

    const midpoint = Math.ceil(arr.length / 2);
    const part1 = arr.slice(0, midpoint);
    const part2 = arr.slice(midpoint);

    while (
        part1.map((link) => link.downloadLink).join('\n').length >
        MAX_CELL_LENGTH
    ) {
        part2.unshift(part1.pop());
    }

    return [part1, part2];
};
