import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Pane, TextInputField } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { errorToast } from '../../toasts';
import { loginShape, registerModel } from '../../../models';

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required').email('Invalid email'),
    mobileNumber: Yup.string().required('Mobile number is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(
            /[@$!%*?&]/,
            'Password must contain at least one special character'
        )
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

const DefaultSignupForm = ({ initialValues, submitForm, onSuccess }) => {
    const dispatch = useDispatch();

    const handleFormSubmission = async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
            await dispatch(submitForm(registerModel(values)));
            onSuccess();
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message || error.message;
            errorToast(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmission}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Pane marginTop={30}>
                        <TextInputField
                            label="First Name"
                            type="text"
                            name="firstName"
                            placeholder="John"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.firstName && errors.firstName}
                            validationMessage={
                                touched.firstName && errors.firstName
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane>
                        <TextInputField
                            label="Last Name"
                            type="text"
                            name="lastName"
                            placeholder="Doe"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.lastName && errors.lastName}
                            validationMessage={
                                touched.lastName && errors.lastName
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane>
                        <TextInputField
                            label="Email"
                            type="email"
                            name="email"
                            placeholder="john@example.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && errors.email}
                            validationMessage={touched.email && errors.email}
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane marginTop="16px">
                        <TextInputField
                            label="Password"
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.password && errors.password}
                            validationMessage={
                                touched.password && errors.password
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                    </Pane>
                    <Pane marginTop="16px">
                        <TextInputField
                            label="Confirm Password"
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm your password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                touched.confirmPassword &&
                                errors.confirmPassword
                            }
                            validationMessage={
                                touched.confirmPassword &&
                                errors.confirmPassword
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                    </Pane>
                    <Pane>
                        <TextInputField
                            label="Mobile Number"
                            type="text"
                            name="mobileNumber"
                            placeholder="+27712526996"
                            value={values.mobileNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                touched.mobileNumber && errors.mobileNumber
                            }
                            validationMessage={
                                touched.mobileNumber && errors.mobileNumber
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane display="flex" flexDirection="column">
                        <Button
                            appearance="primary"
                            height={40}
                            marginTop="10px"
                            isLoading={isSubmitting}
                            disabled={!isValid || isSubmitting}
                        >
                            Sign Up
                        </Button>
                    </Pane>
                </Form>
            )}
        </Formik>
    );
};

DefaultSignupForm.propTypes = {
    initialValues: loginShape.isRequired,
    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default DefaultSignupForm;
