import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Dialog, Pane, Text } from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { successToast, errorToast } from '../../toasts';

import { portfolioSelector } from '../../../reducers/portfolio-reducer/portfolio.reducer';
import { assetService } from '../../../services';
import { assetModel } from '../../../models';
import {
    getAllPortfoliosForBusiness,
    createPortfolio,
} from '../../../reducers/portfolio-reducer/portfolio.actions';

import {
    getAsset,
    getBuilding,
    getAssets,
} from '../../../reducers/asset-reducer/asset.actions';
import {
    setSearchAction,
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';

function AssetUnarchiving({
    asset,
    onOpen,
    handCloseArchive,
    handleDefautSelectedIndex,
}) {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { portfolios } = useSelector(portfolioSelector);

    const archivedPortfolio = useMemo(() => 'Archived', []);

    const { updateUnarchivedAsset } = assetService;

    const onSuccess = useCallback(
        async (updatedAsset) => {
            const url = '/portfolio/properties';
            if (updatedAsset.type === 'park') {
                dispatch(setCurrentAssetAction(assetModel(updatedAsset)));
            }
            if (updatedAsset.type === 'building') {
                dispatch(setCurrentBuildingAction(assetModel(updatedAsset)));
                if (updatedAsset.parentId) {
                    dispatch(getAsset(updatedAsset.parentId, false, false));
                }
            }
            if (updatedAsset.type === 'tenant') {
                dispatch(setCurrentTenantAction(assetModel(updatedAsset)));
                const building = await dispatch(
                    getBuilding(updatedAsset.parentId)
                );
                if (building.parentId) {
                    dispatch(getAsset(building.parentId, false, false));
                }
            }
            dispatch(getAssets());
            handCloseArchive();
            handleDefautSelectedIndex();
            dispatch(setSearchAction(''));
            dispatch(setCurrentAssetAction(null));
            dispatch(setCurrentBuildingAction(null));
            dispatch(setCurrentTenantAction(null));
            navigate(url);
            successToast('Asset successfully unarchived');
        },
        [dispatch, handCloseArchive, handleDefautSelectedIndex, navigate]
    );

    const onFailure = useCallback(
        (errorMessage) => {
            handCloseArchive();
            errorToast(errorMessage);
        },
        [handCloseArchive]
    );

    const handleUnarchiveSubmit = useCallback(async () => {
        try {
            setIsLoading(true);
            let portfolio = _.find(portfolios, {
                name: archivedPortfolio,
            });

            if (!portfolio) {
                portfolio = await dispatch(
                    createPortfolio({
                        portfolioType: archivedPortfolio,
                    })
                );
                await dispatch(getAllPortfoliosForBusiness());
            }

            await updateUnarchivedAsset({
                ...asset,
                archived_restoring: true,
                portfolioId: portfolio.id,
            });
            const updatedAsset = await dispatch(
                getAsset(asset.id, false, false, false)
            );
            onSuccess(updatedAsset);
        } catch (error) {
            const errorMessage = _.get(
                error,
                'response.data.message',
                error.message
            );
            onFailure(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }, [
        portfolios,
        archivedPortfolio,
        asset,
        dispatch,
        updateUnarchivedAsset,
        onSuccess,
        onFailure,
    ]);

    return (
        <Dialog
            isShown={onOpen}
            onConfirm={handleUnarchiveSubmit}
            onCloseComplete={handCloseArchive}
            title="Confirm Unarchiving"
            confirmLabel="Confirm"
            hasCancel={false}
            onCancel={handCloseArchive}
            isConfirmLoading={isLoading}
        >
            <Pane>
                <Text>
                    Unarchive the asset to regain access to it and all
                    associated documents. You will receive a notification once
                    the asset has been unarchived. This process can take up to
                    48 hours.
                </Text>
            </Pane>
        </Dialog>
    );
}

AssetUnarchiving.propTypes = {
    asset: PropTypes.string.isRequired,
    onOpen: PropTypes.string.isRequired,
    handCloseArchive: PropTypes.string.isRequired,
    handleDefautSelectedIndex: PropTypes.func.isRequired,
};

export default AssetUnarchiving;
