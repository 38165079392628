import React, { useEffect, useState, useMemo } from 'react';
import { Alert, Heading, Pane, Spinner, Text } from 'evergreen-ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
    getSystemStatus,
    defaultLogin,
    getAuthToken,
} from '../../reducers/auth-reducer/auth.actions';

import AuthScreenLayout from '../../components/layouts/auth-screen-layout';
import { Image } from '../../components/atoms';
import loginImage from '../../assets/images/illustrations/login.svg';

import UpdatePasswordForm from '../../components/forms/auth-forms/forgot-password-form';
import { authSelector } from '../../reducers/auth-reducer/auth.reducer';
import { defaultLoginModel } from '../../models';
import { errorToast } from '../../components/toasts';
import { authService } from '../../services';

const UpdatePasswordPage = () => {
    const [userData, setUserData] = useState({});

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    const token = useMemo(() => searchParams.get('token'), [searchParams]);

    const {
        isFetchingAuthToken,
        fetchAuthTokenError,
        maintenance: { isUnderMaintenance, message },
    } = useSelector(authSelector);

    useEffect(() => {
        dispatch(getSystemStatus());

        const confirmToken = async () => {
            if (!token) {
                navigate('/reset-password', { replace: true });
            } else {
                const data = { email_token: token };
                try {
                    const response = await authService.confirmResetPassword(
                        data
                    );
                    if (response.response.status !== 200) {
                        navigate('/reset-password', { replace: true });
                    }
                    setUserData(response.response.data);
                } catch (error) {
                    navigate('/reset-password', { replace: true });
                }
            }
        };

        confirmToken();
    }, [token, navigate, dispatch]);

    const onSuccess = async (response, values) => {
        if (response) {
            try {
                const updatedData = {
                    ...values,
                    email: response.email,
                };
                const { authToken } = await dispatch(
                    defaultLogin(defaultLoginModel(updatedData))
                );
                if (authToken) {
                    dispatch(getAuthToken(authToken, false));
                    navigate(`/login?token=${authToken}`);
                }
            } catch (error) {
                const errorMessage =
                    _.get(error, 'response.data.message', error.message) ||
                    error.message;
                errorToast(errorMessage);
            }
        }
    };

    const renderHeroComponent = () => (
        <Image src={loginImage} alt="signup illustration" maxWidth="300px" />
    );

    const renderLoader = () => (
        <Pane
            height={350}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner marginBottom="1rem" />
            <Text size={500}>Logging you in...</Text>
        </Pane>
    );

    const renderAlert = () => {
        if (!fetchAuthTokenError) {
            return null;
        }

        return (
            <Pane padding={20}>
                <Alert intent="danger" title="Login Failed">
                    {fetchAuthTokenError}
                </Alert>
            </Pane>
        );
    };

    const renderUpdateForm = () => {
        if (isUnderMaintenance) {
            return (
                <>
                    <Heading size={600} marginBottom="0.5rem">
                        Welcome to Asset Records!{' '}
                        <span role="img" aria-label="waving">
                            👋
                        </span>
                    </Heading>
                    <Text size={500}>{message}</Text>
                </>
            );
        }
        return (
            <>
                <Heading size={600} marginBottom="0.5rem">
                    Welcome to Asset Records!{' '}
                    <span role="img" aria-label="waving">
                        👋
                    </span>
                </Heading>
                <>
                    <Text size={500} marginBottom={16}>
                        Update your password
                    </Text>
                    {renderAlert()}
                    <UpdatePasswordForm onSuccess={onSuccess} user={userData} />
                </>
            </>
        );
    };

    return (
        <AuthScreenLayout heroComponent={renderHeroComponent()}>
            <Pane
                display="flex"
                flexDirection="column"
                maxWidth="400px"
                width="100%"
            >
                {isFetchingAuthToken ? renderLoader() : renderUpdateForm()}
            </Pane>
        </AuthScreenLayout>
    );
};

export default UpdatePasswordPage;
