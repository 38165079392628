import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pane, Button } from 'evergreen-ui';

import { useDispatch } from 'react-redux';

import ComplianceFrom from '../compliance-forms/ComplianceForm';
import {
    confirmComplianceTemplates,
    getComplianceTemplate,
} from '../../../reducers/compliance-template-reducer/compliance-template.actions';

function AssetsComlianceForm({
    setCurrentStep,
    currentStep,
    type,
    setShowComplianceModal,
}) {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const getData = useCallback(async () => {
        await dispatch(getComplianceTemplate(type));
        setLoading(false);
    }, [type, dispatch]);

    const handleNextStep = async () => {
        setLoading(true);
        try {
            await dispatch(confirmComplianceTemplates(type));
            setCurrentStep(2);
        } finally {
            setLoading(false);
        }
    };

    const goBack = async () => {
        await dispatch(confirmComplianceTemplates(type));
        setLoading(false);
        setCurrentStep(currentStep - 1);
    };

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div>
            <Pane>
                <ComplianceFrom
                    type={type}
                    loading={loading}
                    setShowComplianceModal={setShowComplianceModal}
                />
            </Pane>
            <Pane display="flex" flexDirection="column">
                <Button
                    appearance="primary"
                    height={40}
                    marginTop="32px"
                    type="submit"
                    onClick={handleNextStep}
                >
                    Next
                </Button>
                <Pane marginTop={12} display="flex">
                    <Button
                        appearance="minimal"
                        height={40}
                        type="button"
                        display="flex"
                        onClick={goBack}
                        flex={1}
                    >
                        Back
                    </Button>
                </Pane>
            </Pane>
        </div>
    );
}

AssetsComlianceForm.propTypes = {
    setCurrentStep: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    setShowComplianceModal: PropTypes.string.isRequired,
};
export default AssetsComlianceForm;
