import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Pane, Text, TextInputField } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import VerticalStepperStep from '../../molecules/vertical-stepper-step';
import VerticalStepper from '../../molecules/vertical-stepper';
import {
    createPasswordModel,
    defaultLoginModel,
    loginModel,
    loginShape,
} from '../../../models';
import { authService } from '../../../services';
import { errorToast } from '../../toasts';

const DefaultLoginForm = ({ initialValues, submitForm, onSuccess }) => {
    const [loginUserHasPassword, setLoginUserHasPassword] = useState(true);
    const [userLogin, setUserLogin] = useState({});
    const [emailError, setEmailError] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);

    const dispatch = useDispatch();
    const passwordInputRef = useRef(null);

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                email: Yup.string()
                    .email('Invalid email')
                    .required('Email is required'),
                password: Yup.string()
                    .min(8, 'Password must be at least 8 characters')
                    .matches(
                        /[a-z]/,
                        'Password must contain at least one lowercase letter'
                    )
                    .matches(
                        /[A-Z]/,
                        'Password must contain at least one uppercase letter'
                    )
                    .matches(
                        /[0-9]/,
                        'Password must contain at least one number'
                    )
                    .matches(
                        /[@$!%*?&]/,
                        'Password must contain at least one special character'
                    )
                    .required('Password is required'),
                confirmPassword: Yup.string().oneOf(
                    [Yup.ref('password'), null],
                    'Passwords must match'
                ),
            }),
        []
    );

    const handleEmailSubmit = useCallback(async (email) => {
        if (!email) return;

        try {
            setEmailError(null);
            const response = await authService.passwordConfirmation(
                loginModel({ email })
            );

            if (
                response?.response.message === 'User does not have a password'
            ) {
                setLoginUserHasPassword(false);
                setUserLogin(response.response.user);
                setCurrentStep(1);
            } else if (
                response?.response.message ===
                'Input password field cant be empty'
            ) {
                setLoginUserHasPassword(true);
                setCurrentStep(1);
            }

            setTimeout(() => {
                passwordInputRef.current?.focus();
            }, 0);
        } catch (error) {
            setEmailError(_.get(error, 'response.data.message', error.message));
        }
    }, []);

    const handleFormSubmission = useCallback(
        async (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            try {
                const { authToken } = await dispatch(
                    submitForm(defaultLoginModel(values))
                );
                onSuccess(authToken);
            } catch (error) {
                setFieldError(
                    'email',
                    error?.response?.data?.message || error.message
                );
                const errorMessage = _.get(
                    error,
                    'response.data.message',
                    error.message
                );
                errorToast(errorMessage);
            } finally {
                setSubmitting(false);
            }
        },
        [dispatch, onSuccess, submitForm]
    );

    const handleCreatePassword = useCallback(
        async (values, { setSubmitting, resetForm, setFieldError }) => {
            setSubmitting(true);
            try {
                const userData = createPasswordModel({
                    ...values,
                    id: userLogin.id,
                });
                const response = await authService.createPassword(userData);
                if (response.status === 200) {
                    resetForm();
                    handleFormSubmission(values, {
                        setSubmitting,
                        resetForm,
                        setFieldError,
                    });
                }
            } catch (error) {
                setFieldError(
                    'email',
                    _.get(error, 'response.data.message', error.message)
                );
            } finally {
                setSubmitting(false);
            }
        },
        [userLogin.id, handleFormSubmission]
    );

    const handleFinalSubmit = useCallback(
        (values, formikHelpers) => {
            if (loginUserHasPassword) {
                handleFormSubmission(values, formikHelpers);
            } else {
                handleCreatePassword(values, formikHelpers);
            }
        },
        [handleFormSubmission, handleCreatePassword, loginUserHasPassword]
    );

    const handleNext = useCallback(
        (email) => {
            handleEmailSubmit(email);
        },
        [handleEmailSubmit]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFinalSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                resetForm,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <VerticalStepper currentStep={currentStep}>
                        <VerticalStepperStep title="Email">
                            <Pane marginX={2}>
                                <Pane marginTop="16px">
                                    <TextInputField
                                        required
                                        label="Email"
                                        type="email"
                                        name="email"
                                        placeholder="john@example.com"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={
                                            !!touched.email && !!errors.email
                                        }
                                        validationMessage={
                                            (touched.email && errors.email) ||
                                            emailError
                                        }
                                        spellCheck={false}
                                        width="100%"
                                        inputHeight={40}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                setFieldTouched(
                                                    'email',
                                                    true,
                                                    true
                                                );
                                                if (
                                                    !errors.email &&
                                                    values.email
                                                ) {
                                                    handleNext(values.email);
                                                }
                                            }
                                        }}
                                    />
                                </Pane>
                                <Button
                                    appearance="primary"
                                    marginTop={8}
                                    height={40}
                                    onClick={() => handleNext(values.email)}
                                    width="100%"
                                    disabled={!!errors.email || !touched.email}
                                >
                                    Next
                                </Button>
                            </Pane>
                        </VerticalStepperStep>
                        <VerticalStepperStep title="Password">
                            <Pane marginX={2}>
                                {loginUserHasPassword ? (
                                    <>
                                        <Text>Enter Password to login</Text>
                                        <Pane marginTop="16px">
                                            <TextInputField
                                                ref={passwordInputRef}
                                                label="Password"
                                                type="password"
                                                name="password"
                                                placeholder="Enter your password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={
                                                    !!touched.password &&
                                                    !!errors.password
                                                }
                                                validationMessage={
                                                    touched.password &&
                                                    errors.password
                                                }
                                                spellCheck={false}
                                                width="100%"
                                                inputHeight={40}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        setFieldTouched(
                                                            'email',
                                                            true,
                                                            true
                                                        );
                                                        if (
                                                            !errors.password &&
                                                            values.password
                                                        ) {
                                                            handleSubmit();
                                                        }
                                                    }
                                                }}
                                            />
                                        </Pane>
                                        <Button
                                            appearance="primary"
                                            marginTop={8}
                                            type="submit"
                                            isLoading={isSubmitting}
                                            height={40}
                                            width="100%"
                                            disabled={
                                                !!errors.password ||
                                                !touched.password
                                            }
                                        >
                                            Login
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Text>Create a password</Text>
                                        <Pane marginTop="16px">
                                            <TextInputField
                                                ref={passwordInputRef}
                                                label="Password"
                                                type="password"
                                                name="password"
                                                placeholder="Enter your password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={
                                                    !!touched.password &&
                                                    !!errors.password
                                                }
                                                validationMessage={
                                                    touched.password &&
                                                    errors.password
                                                }
                                                spellCheck={false}
                                                width="100%"
                                                inputHeight={40}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        setFieldTouched(
                                                            'password',
                                                            true,
                                                            true
                                                        );
                                                        if (
                                                            !errors.password &&
                                                            values.password &&
                                                            values.confirmPassword ===
                                                                values.password
                                                        ) {
                                                            handleSubmit();
                                                        }
                                                    }
                                                }}
                                            />
                                        </Pane>
                                        <Pane marginTop="16px">
                                            <TextInputField
                                                label="Confirm Password"
                                                type="password"
                                                name="confirmPassword"
                                                placeholder="Confirm your password"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={
                                                    !!touched.confirmPassword &&
                                                    (!!errors.confirmPassword ||
                                                        values.confirmPassword !==
                                                            values.password)
                                                }
                                                validationMessage={
                                                    touched.confirmPassword &&
                                                    (errors.confirmPassword ||
                                                        (values.confirmPassword !==
                                                        values.password
                                                            ? 'Passwords do not match'
                                                            : undefined))
                                                }
                                                spellCheck={false}
                                                width="100%"
                                                inputHeight={40}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        setFieldTouched(
                                                            'email',
                                                            true,
                                                            true
                                                        );
                                                        if (
                                                            !errors.confirmPassword &&
                                                            values.password ===
                                                                values.confirmPassword
                                                        ) {
                                                            handleSubmit();
                                                        }
                                                    }
                                                }}
                                            />
                                        </Pane>
                                        <Button
                                            appearance="primary"
                                            marginTop={8}
                                            type="submit"
                                            isLoading={isSubmitting}
                                            height={40}
                                            width="100%"
                                            disabled={
                                                !!errors.password ||
                                                !!errors.confirmPassword ||
                                                values.password !==
                                                    values.confirmPassword
                                            }
                                        >
                                            Set Password
                                        </Button>
                                    </>
                                )}
                                <Button
                                    appearance="primary"
                                    marginTop={8}
                                    height={40}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentStep(0);
                                        setLoginUserHasPassword(true);
                                        setUserLogin({});
                                        setEmailError(null);
                                        resetForm({
                                            values: {
                                                email: values.email,
                                                password: '',
                                                confirmPassword: '',
                                            },
                                        });
                                    }}
                                    width="100%"
                                >
                                    Back
                                </Button>
                            </Pane>
                        </VerticalStepperStep>
                    </VerticalStepper>
                </Form>
            )}
        </Formik>
    );
};

DefaultLoginForm.propTypes = {
    initialValues: loginShape.isRequired,
    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default DefaultLoginForm;
