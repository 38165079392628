import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Pane,
    CogIcon,
    NotificationsIcon,
    Popover,
    Position,
    Menu,
    LogOutIcon,
    PeopleIcon,
    LabelIcon,
    Text,
} from 'evergreen-ui';
import styled from 'styled-components';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import { Logo } from '../atoms';
import { colors } from '../../theme/theme';
import { userSelector } from '../../reducers/user-reducer/user.reducer';
import { logout } from '../../reducers/auth-reducer/auth.actions';
import UploadManagerMenu from '../organisms/upload-manager/upload-manager-menu';
import DashboardNavbarSearch from './search-navbar/dashboard-navbar-search';
import { notificationsSelector } from '../../reducers/notifications-reducer/notifications.reducer';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import NotificationsSidebar from '../notifications/notifications-sidebar';
import { useUserAccess } from '../../context/user-access-context';

const DashboardNavbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(userSelector);

    const { pathname } = useLocation();

    const { modules } = useUserAccess();

    const [showNotifications, setShowNotifications] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [showUserOptions, setShowUserOptions] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({ modalName: '' });

    const hasManagementAccess = useMemo(
        () => modules['user-management'] || modules['template-management'],
        [modules]
    );

    useEffect(() => {
        dispatch(getNotifications());
    }, [dispatch]);

    useEffect(() => {
        const modalName = searchParams.get('modalName');
        setShowOptions(modalName === 'settingsMenu');
        setShowNotifications(modalName === 'notification');
        setShowUserOptions(modalName === 'userMenu');
    }, [searchParams]);

    const signOut = () => {
        setShowUserOptions(false);
        dispatch(logout());
        navigate('/login', { replace: true });
    };

    const handleUrlParamSet = useCallback(
        (id) => {
            const nextSearchParams = new URLSearchParams(searchParams);
            const value = id;
            nextSearchParams.set('modalName', value);
            setSearchParams(nextSearchParams);
        },
        [setSearchParams, searchParams]
    );

    const handleUrlParamDelete = useCallback(() => {
        const nextSearchParams = new URLSearchParams(searchParams);
        nextSearchParams.delete('modalName');
        setSearchParams(nextSearchParams);
    }, [setSearchParams, searchParams]);

    const { notifications } = useSelector(notificationsSelector);

    const openNotificationsSidebar = useCallback(
        (id) => {
            setShowOptions(false);
            handleUrlParamSet(id);
            setShowNotifications(true);
        },
        [setShowNotifications, setShowOptions, handleUrlParamSet]
    );

    const closeNotificationsSidebar = useCallback(() => {
        handleUrlParamDelete();
        setShowNotifications(false);
    }, [handleUrlParamDelete, setShowNotifications]);

    const handleMouseLeave = useCallback(() => {
        handleUrlParamDelete();
        setShowOptions(false);
    }, [handleUrlParamDelete, setShowOptions]);

    const handleSettingMenu = useCallback(
        (id) => {
            if (showOptions) {
                handleUrlParamDelete();
                setShowOptions(false);
            } else {
                handleUrlParamSet(id);
                setShowOptions(true);
            }
        },
        [showOptions, handleUrlParamDelete, handleUrlParamSet]
    );

    const handleSettingsClose = useCallback(() => {
        handleUrlParamDelete();
        setShowOptions(false);
    }, [handleUrlParamDelete, setShowOptions]);

    const openAccountMenu = useCallback(
        (id) => {
            setShowOptions(false);
            setShowNotifications(false);
            if (showUserOptions) {
                handleUrlParamDelete();
                setShowUserOptions(false);
            } else {
                handleUrlParamSet(id);
                setShowUserOptions(true);
            }
        },
        [
            showUserOptions,
            handleUrlParamDelete,
            handleUrlParamSet,
            setShowOptions,
            setShowNotifications,
            setShowUserOptions,
        ]
    );

    const handleAccountMouseLeave = useCallback(() => {
        handleUrlParamDelete();
        setShowUserOptions(false);
    }, [handleUrlParamDelete]);

    return (
        <>
            <Pane
                paddingX={20}
                height={65}
                backgroundColor={colors.primary500}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="fixed"
                zIndex={5}
            >
                <Logo color="white" />
                <Pane display="flex" alignItems="center" height="100%">
                    {user && <UploadManagerMenu />}
                    {hasManagementAccess && (
                        <>
                            <Popover
                                isShown={showOptions}
                                position={Position.BOTTOM_RIGHT}
                                shouldCloseOnExternalClick
                                onMouse
                                content={
                                    <div onMouseLeave={handleMouseLeave}>
                                        <Menu>
                                            <Menu.Group>
                                                {modules[
                                                    'template-management'
                                                ] && (
                                                    <Menu.Item
                                                        icon={LabelIcon}
                                                        onSelect={() => {
                                                            handleSettingsClose();
                                                            navigate(
                                                                '/template-management'
                                                            );
                                                        }}
                                                    >
                                                        Template Management
                                                    </Menu.Item>
                                                )}
                                                {modules['user-management'] && (
                                                    <Menu.Item
                                                        icon={PeopleIcon}
                                                        onSelect={() => {
                                                            handleSettingsClose();
                                                            navigate(
                                                                '/user-management'
                                                            );
                                                        }}
                                                    >
                                                        User Management
                                                    </Menu.Item>
                                                )}
                                            </Menu.Group>
                                        </Menu>
                                    </div>
                                }
                            >
                                <Pane height="100%">
                                    <BackgroundHover
                                        className="button-setting-icon-overview"
                                        id="template-icon"
                                        isActive={pathname.includes(
                                            'management'
                                        )}
                                        onClick={() => {
                                            setShowUserOptions(false);
                                            handleSettingMenu('settingsMenu');
                                        }}
                                    >
                                        <Pane
                                            display="flex"
                                            justifyContent="center"
                                            width={40}
                                            position="relative"
                                        >
                                            <CogIcon
                                                color="#f2f4fa"
                                                size={20}
                                            />
                                        </Pane>
                                    </BackgroundHover>
                                </Pane>
                            </Popover>
                            {showOptions && (
                                <Pane
                                    height="100%"
                                    width="100%"
                                    position="fixed"
                                    top={0}
                                    left={0}
                                    onClick={() => handleSettingsClose()}
                                />
                            )}
                        </>
                    )}
                    {!pathname.includes('upload-request') && (
                        <BackgroundHover
                            className="button-notifications-icon-overview"
                            id="notifications"
                            onClick={() =>
                                openNotificationsSidebar('notification')
                            }
                        >
                            <Pane
                                display="flex"
                                justifyContent="center"
                                width={40}
                                position="relative"
                                role="button"
                            >
                                <NotificationsIcon color="#f2f4fa" size={20} />
                                {!!notifications.length && (
                                    <Pane
                                        borderRadius={99999}
                                        background="#df2c2c"
                                        position="absolute"
                                        top={-4}
                                        right={3}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        width={14}
                                        height={14}
                                        zIndex={10}
                                    >
                                        <Text fontSize={10} color="white">
                                            {notifications.length}
                                        </Text>
                                    </Pane>
                                )}
                            </Pane>
                        </BackgroundHover>
                    )}

                    {user && (
                        <Popover
                            isShown={showUserOptions}
                            position={Position.BOTTOM_RIGHT}
                            content={
                                <div onMouseLeave={handleAccountMouseLeave}>
                                    <Menu>
                                        <Menu.Group>
                                            <Menu.Item
                                                icon={LogOutIcon}
                                                onSelect={signOut}
                                            >
                                                Logout
                                            </Menu.Item>
                                        </Menu.Group>
                                    </Menu>
                                </div>
                            }
                        >
                            <Pane height="100%">
                                <BackgroundHover
                                    className="button-avatar-icon-overview"
                                    onClick={() => {
                                        handleSettingsClose();
                                        openAccountMenu('userMenu');
                                    }}
                                >
                                    <Avatar
                                        name={`${user.firstName} ${user.lastName}`}
                                        size={40}
                                    />
                                </BackgroundHover>
                            </Pane>
                        </Popover>
                    )}
                </Pane>
            </Pane>
            {!pathname.includes('upload-request') && (
                <>
                    <DashboardNavbarSearch />
                    <NotificationsSidebar
                        isShown={showNotifications}
                        close={closeNotificationsSidebar}
                        notifications={notifications}
                    />
                </>
            )}
        </>
    );
};

export default DashboardNavbar;

const BackgroundHover = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${({ isActive }) =>
        isActive ? '#24374b' : 'transparent'};

    &:hover {
        background-color: #24374b;
    }
`;
