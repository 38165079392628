import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Pane, TextInputField } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { authService } from '../../../services';

import { updatePasswordModel } from '../../../models';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(
            /[@$!%*?&]/,
            'Password must contain at least one special character'
        )
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

const UpdatePasswordForm = ({ onSuccess, user }) => {
    const initialValues = useMemo(
        () => ({
            password: '',
            confirmPassword: '',
        }),
        []
    );

    const handleFormSubmission = async (
        values,
        { setSubmitting, setFieldError }
    ) => {
        if (!values.password && !values.confirmPassword) {
            return;
        }
        setSubmitting(true);

        try {
            const data = {
                ...values,
                id: user.id,
            };
            const response = await authService.updatePassword(
                updatePasswordModel(data)
            );
            if (response.status === 200) onSuccess(response, values);
        } catch (error) {
            setFieldError(
                'email',
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmission}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Pane marginX={2}>
                        <Pane marginTop="16px">
                            <TextInputField
                                label="Password"
                                type="password"
                                name="password"
                                placeholder="Enter your password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    !!touched.password && !!errors.password
                                }
                                validationMessage={
                                    touched.password && errors.password
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        setFieldTouched('password', true, true);
                                        if (
                                            !errors.password &&
                                            values.password &&
                                            values.confirmPassword ===
                                                values.password
                                        ) {
                                            handleSubmit();
                                        }
                                    }
                                }}
                            />
                        </Pane>
                        <Pane marginTop="16px">
                            <TextInputField
                                label="Confirm Password"
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirm your password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    !!touched.confirmPassword &&
                                    (!!errors.confirmPassword ||
                                        values.confirmPassword !==
                                            values.password)
                                }
                                validationMessage={
                                    touched.confirmPassword &&
                                    (errors.confirmPassword ||
                                        (values.confirmPassword !==
                                        values.password
                                            ? 'Passwords do not match'
                                            : undefined))
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        setFieldTouched('email', true, true);
                                        if (
                                            !errors.confirmPassword &&
                                            values.password ===
                                                values.confirmPassword
                                        ) {
                                            handleSubmit();
                                        }
                                    }
                                }}
                            />
                        </Pane>
                        <Button
                            appearance="primary"
                            marginTop={8}
                            type="submit"
                            isLoading={isSubmitting}
                            height={40}
                            width="100%"
                            disabled={
                                !values.password ||
                                values.password !== values.confirmPassword ||
                                !!errors.password ||
                                !!errors.confirmPassword
                            }
                        >
                            Set Password
                        </Button>
                    </Pane>
                </Form>
            )}
        </Formik>
    );
};

UpdatePasswordForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
};

export default UpdatePasswordForm;
