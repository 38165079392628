import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
    Button,
    CrossIcon,
    TrashIcon,
    EditIcon,
    Pane,
    SelectMenu,
    Spinner,
    Text,
    TextInput,
    Tooltip,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { updatePropertyGroup } from '../../reducers/user-management-reducer/user-management.actions';
import { errorToast, successToast } from '../toasts';

function PropertyGroupItem({
    group,
    properties,
    setDeletingPropertyGroup,
    setIsConfirmDisabled,
    currentRights,
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(group.name);
    const [selectedProperties, setSelectedProperties] = useState(
        group.buildingIds
    );
    const [showOptions, setShowOptions] = useState(false);

    const dispatch = useDispatch();

    const handleSelect = async ({ value }) => {
        setSelectedProperties((state) => {
            const buildingIds = [...state, value];
            try {
                dispatch(
                    updatePropertyGroup({
                        id: group.id,
                        name,
                        buildingIds,
                    })
                );
            } catch (error) {
                const propertyName = options.find(
                    ({ value: id }) => Number(id) === Number(value)
                )?.name;
                errorToast(
                    `An Error Occurred: ${get(
                        error,
                        'response.data.message',
                        `Failed to add property ${
                            propertyName && `(${propertyName})`
                        } to group: ${name}`
                    )}`
                );
            }

            return buildingIds;
        });
    };

    const handleDeselect = async ({ value }) => {
        setSelectedProperties((state) => {
            const buildingIds = state.filter((id) => id !== value);
            try {
                dispatch(
                    updatePropertyGroup({
                        id: group.id,
                        name,
                        buildingIds,
                    })
                );
            } catch (error) {
                const propertyName = options.find(
                    ({ value: id }) => Number(id) === Number(value)
                )?.name;
                errorToast(
                    `An Error Occurred: ${get(
                        error,
                        'response.data.message',
                        `Failed to add property ${
                            propertyName && `(${propertyName})`
                        } to group: ${name}`
                    )}`
                );
            }

            return buildingIds;
        });
    };

    const handleEnter = useCallback(
        async ({ key, target: { value } }) => {
            if (key === 'Enter' && value.length > 1) {
                setIsLoading(true);
                try {
                    await dispatch(
                        updatePropertyGroup({
                            id: group.id,
                            name,
                            buildingIds: selectedProperties,
                        })
                    );
                    successToast(`Successfully updated group name to: ${name}`);
                    setIsEditing(false);
                } catch (error) {
                    errorToast(
                        `An Error Occurred: ${get(
                            error,
                            'response.data.message',
                            `Failed to update ${name}`
                        )}`
                    );
                } finally {
                    setIsLoading(false);
                }
            }
        },
        [dispatch, group, name, selectedProperties]
    );
    const handleNameChange = ({ target: { value } }) => {
        setName(value);
    };

    const options = useMemo(() => {
        const sortedProperties = [...properties].sort(({ id }) => {
            if (selectedProperties?.includes(Number(id))) {
                return -1;
            }
            return 1;
        });

        return sortedProperties.map((property) => ({
            label: property.assetName,
            value: Number(property.id),
        }));
    }, [properties, selectedProperties]);

    const buttonText = useMemo(() => {
        if (!selectedProperties.length) {
            return 'Select Properties';
        }
        return options
            .map(({ label, value }) => {
                if (selectedProperties?.includes(value)) {
                    return label;
                }
                return null;
            })
            .filter((value) => !!value)
            .join(', ');
    }, [options, selectedProperties]);

    const renderName = useCallback(() => {
        if (isEditing) {
            return isLoading ? (
                <Button width={200} height={40}>
                    <Spinner color="#696f8b" size={28} />
                </Button>
            ) : (
                <Tooltip isShown={isEditing} content="Press Enter to Confirm">
                    <TextInput
                        height={40}
                        value={name}
                        border="1px solid #c1c4d6"
                        onKeyDown={handleEnter}
                        onChange={handleNameChange}
                        placeholder={group.name}
                        paddingRight={0}
                        width={200}
                        autoFocus
                    />
                </Tooltip>
            );
        }
        return <Text width={200}>{group.name}</Text>;
    }, [isEditing, group.name, isLoading, name, handleEnter]);

    useEffect(() => {
        if (!selectedProperties.length) {
            setIsConfirmDisabled(true);
        } else {
            setIsConfirmDisabled(false);
        }
    }, [selectedProperties, setIsConfirmDisabled]);

    return (
        <Pane
            display="flex"
            alignItems="center"
            gap={12}
            justifyContent="space-between"
        >
            {renderName()}
            <Pane display="flex" width="100%" maxWidth="75%">
                <SelectMenu
                    isMultiSelect
                    title="Select Properties"
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    options={options}
                    selected={selectedProperties}
                    isShown={currentRights?.update && showOptions}
                    filterPlaceholder="Search Properties"
                    onClose={() => setShowOptions(false)}
                >
                    <Pane display="flex" width="100%" gap={6}>
                        <Button
                            justifyContent="space-between"
                            display="flex"
                            flex={5}
                            width="100%"
                            height={40}
                            disabled={!currentRights?.update}
                            onClick={() => setShowOptions(true)}
                        >
                            <Text
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                            >
                                {buttonText}
                            </Text>
                        </Button>
                        {(currentRights?.update || currentRights?.delete) && (
                            <Pane
                                display="flex"
                                gap={16}
                                justifyContent="space-between"
                            >
                                {currentRights?.update && (
                                    <Pane
                                        display="flex"
                                        flex={1}
                                        alignItems="center"
                                        justifyContent="center"
                                        padding={3}
                                        className="edit-contact"
                                        hoverElevation={1}
                                        borderRadius="4px"
                                        role="button"
                                        background="#f5f6fa"
                                        cursor="pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsEditing((state) => !state);
                                        }}
                                        marginRight={-8}
                                        width={45}
                                    >
                                        {isEditing ? (
                                            <CrossIcon color="#696f8b" />
                                        ) : (
                                            <EditIcon
                                                color="#696f8b"
                                                className="edit-group"
                                            />
                                        )}
                                    </Pane>
                                )}
                                {currentRights?.delete &&
                                    group.name !== 'All Properties' && (
                                        <Pane
                                            display="flex"
                                            flex={1}
                                            alignItems="center"
                                            justifyContent="center"
                                            padding={3}
                                            className="edit-contact"
                                            hoverElevation={1}
                                            borderRadius="4px"
                                            role="button"
                                            background="#f5f6fa"
                                            cursor="pointer"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDeletingPropertyGroup(group);
                                            }}
                                            marginRight={-8}
                                            width={45}
                                        >
                                            <TrashIcon
                                                color="#c60b0b"
                                                className="edit-group"
                                            />
                                        </Pane>
                                    )}
                            </Pane>
                        )}
                    </Pane>
                </SelectMenu>
            </Pane>
        </Pane>
    );
}

PropertyGroupItem.propTypes = {
    group: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        buildingIds: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setDeletingPropertyGroup: PropTypes.func.isRequired,
    setIsConfirmDisabled: PropTypes.func.isRequired,
    currentRights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

export default PropertyGroupItem;
