import networkService from '../network-service/network.service';
import authUrls from './auth-service-urls';
import {
    XANO_ACCESS_TOKEN_KEY,
    API_ACCESS_TOKEN_KEY,
} from '../../../local-storage-keys';

export const defaultAuthConfig = {
    baseURL: 'https://xkrn-4n4o-aozy.e2.xano.io/api:JwId6eGi',
};

function register({ name, surname, email, mobileNumber }) {
    const url = authUrls.registerUrl();
    const data = { name, surname, email, mobileno: mobileNumber };
    return networkService.makePost(url, data, defaultAuthConfig);
}

function signUp(data) {
    const url = authUrls.signUpUrl();
    return networkService.makePost(url, data, defaultAuthConfig);
}

function login({ email }) {
    const url = authUrls.loginUrl();
    const config = { ...defaultAuthConfig, params: { email } };
    return networkService.makeGet(url, config);
}

function defaultLogin({ email, password }) {
    const url = authUrls.defaultLoginUrl();
    const data = { email, password };
    return networkService.makePost(url, data, defaultAuthConfig);
}

function createPassword(data) {
    const url = authUrls.createPasswordUrl();
    return networkService.makePost(url, data, defaultAuthConfig);
}

function updatePassword(data) {
    const url = authUrls.updatePasswordUrl();
    return networkService.makePost(url, data, defaultAuthConfig);
}

function resetPassword(data) {
    const url = authUrls.resetPasswordUrl();
    return networkService.makePost(url, data, defaultAuthConfig);
}

function confirmResetPassword(data) {
    const url = authUrls.confirmEmailResetUrl();
    return networkService.makePost(url, data, defaultAuthConfig);
}

function passwordConfirmation(data) {
    const url = authUrls.passwordConfirmationUrl();
    return networkService.makePost(url, data, defaultAuthConfig);
}

async function getAuthToken(magicToken, shouldConfirmEmail) {
    localStorage.removeItem(XANO_ACCESS_TOKEN_KEY);
    localStorage.removeItem(API_ACCESS_TOKEN_KEY);
    const url = shouldConfirmEmail
        ? authUrls.confirmEmailUrl()
        : authUrls.getAuthTokenUrl();
    const data = shouldConfirmEmail
        ? { token: magicToken }
        : { magic_token: magicToken };
    const { v1accessToken, v2accessToken } = await networkService.makePost(
        url,
        data,
        defaultAuthConfig
    );

    localStorage.setItem(XANO_ACCESS_TOKEN_KEY, v1accessToken);
    localStorage.setItem(API_ACCESS_TOKEN_KEY, v2accessToken);

    return { xanoAccessToken: v1accessToken, apiAccessToken: v2accessToken };
}

function logout() {
    localStorage.removeItem(XANO_ACCESS_TOKEN_KEY);
    localStorage.removeItem(API_ACCESS_TOKEN_KEY);
}

async function refreshAuthToken() {
    const url = authUrls.refreshAuthToken();
    const { v1accessToken, v2accessToken } = await networkService.makeGet(
        url,
        defaultAuthConfig
    );

    localStorage.setItem(XANO_ACCESS_TOKEN_KEY, v1accessToken);
    localStorage.setItem(API_ACCESS_TOKEN_KEY, v2accessToken);

    return { xanoAccessToken: v1accessToken, apiAccessToken: v2accessToken };
}

function getSystemStatus() {
    const url = authUrls.systemStatusUrl();
    return networkService.makeGet(url, {
        baseURL: 'https://xkrn-4n4o-aozy.e2.xano.io/api:dWln0Zf8',
    });
}

export default {
    register,
    signUp,
    login,
    defaultLogin,
    createPassword,
    updatePassword,
    logout,
    getAuthToken,
    refreshAuthToken,
    getSystemStatus,
    resetPassword,
    confirmResetPassword,
    passwordConfirmation,
};
