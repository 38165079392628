import React, { useEffect } from 'react';
import { Heading, Link, Pane, Spinner, Text } from 'evergreen-ui';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    getAuthToken,
    defaultLogin,
    getSystemStatus,
} from '../../reducers/auth-reducer/auth.actions';

import DefaultLoginForm from '../../components/forms/auth-forms/default-login-form';
import { defaultLoginModel } from '../../models';
import { authSelector } from '../../reducers/auth-reducer/auth.reducer';

const DefaultLoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        email,
        isFetchingAuthToken,
        isAuthenticated,
        maintenance: { isUnderMaintenance, message },
    } = useSelector(authSelector);

    useEffect(() => {
        dispatch(getSystemStatus());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/portfolio', { replace: true });
        }
    }, [navigate, isAuthenticated]);

    const onLoginSuccess = (token) => {
        if (token) {
            dispatch(getAuthToken(token, false));
        }
        navigate(`/login?token=${token}`);
    };

    const submitLoginForm = defaultLogin;

    const renderLoader = () => (
        <Pane
            height={350}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner marginBottom="1rem" />
            <Text size={500}>Logging you in...</Text>
        </Pane>
    );

    const renderLoginForm = () => {
        if (isUnderMaintenance) {
            return (
                <>
                    <Heading size={600} marginBottom="0.5rem">
                        Welcome to Asset Records!{' '}
                        <span role="img" aria-label="waving">
                            👋
                        </span>
                    </Heading>
                    <Text size={500}>{message}</Text>
                </>
            );
        }
        return (
            <>
                <Heading size={600} marginBottom="0.5rem">
                    Welcome to Asset Records!{' '}
                    <span role="img" aria-label="waving">
                        👋
                    </span>
                </Heading>
                <>
                    <Text size={500} marginBottom={16}>
                        Please login to your account to get started
                    </Text>
                    <DefaultLoginForm
                        onSuccess={onLoginSuccess}
                        submitForm={submitLoginForm}
                        initialValues={defaultLoginModel({ email })}
                    />
                    <Text size={400} alignSelf="center" marginTop="24px">
                        Don&apos;t have an account?{' '}
                        <Link is={RouterLink} to="/signup">
                            Sign Up
                        </Link>
                    </Text>
                    <Text size={400} alignSelf="center" marginTop="24px">
                        Forgot Password?{' '}
                        <Link is={RouterLink} to="/reset-password">
                            Reset Password
                        </Link>
                    </Text>
                </>
            </>
        );
    };

    return (
        <Pane
            display="flex"
            flexDirection="column"
            maxWidth="400px"
            width="100%"
        >
            {isFetchingAuthToken ? renderLoader() : renderLoginForm()}
        </Pane>
    );
};

export default DefaultLoginPage;
