import React, { useState } from 'react';
import { Dialog, TextInputField } from 'evergreen-ui';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { complianceService } from '../../../services';
import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../../reducers/asset-reducer/asset.actions';

const AddComplianceTypeDialog = ({
    complianceCategory,
    isShown,
    setIsShown,
}) => {
    const dispatch = useDispatch();
    const { assetId, buildingId, tenantId } = useParams();

    const [complianceTypeName, setComplianceTypeName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const close = () => {
        setIsShown(false);
    };

    const onChange = (event) => {
        setComplianceTypeName(event.target.value);
    };

    const submit = async () => {
        setIsSubmitting(true);

        try {
            await complianceService.createComplianceType({
                complianceCategoryId: complianceCategory.id,
                name: complianceTypeName,
            });
            if (tenantId) {
                await dispatch(getTenant(tenantId));
                close();
                return;
            }
            if (buildingId) {
                await dispatch(getBuilding(buildingId));
                close();
                return;
            }
            if (assetId) {
                await dispatch(getAsset(assetId, false, true, false));
                close();
                return;
            }
        } catch (error) {
            setSubmitError(
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog
            isShown={isShown}
            title="Add Compliance Type"
            onCloseComplete={close}
            confirmLabel="Confirm"
            hasClose={false}
            onConfirm={submit}
            isConfirmLoading={isSubmitting}
        >
            <TextInputField
                isInvalid={!!submitError}
                required
                label="Compliance Type Name"
                validationMessage={submitError}
                onChange={onChange}
                tabindex="0"
            />
        </Dialog>
    );
};

AddComplianceTypeDialog.propTypes = {
    complianceCategory: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    isShown: PropTypes.bool.isRequired,
    setIsShown: PropTypes.func.isRequired,
};

export default AddComplianceTypeDialog;
