import React, { useMemo } from 'react';
import { SelectField, Text } from 'evergreen-ui';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { portfolioSelector } from '../../reducers/portfolio-reducer/portfolio.reducer';

export const PORTFOLIO_TYPES = [
    'Retail',
    'Residential',
    'Commercial',
    'Industrial',
    'Educational',
    'Medical',
    'Hotel & Leisure',
    'Mixed Use',
];

const PortfolioTypeSelect = ({ showAllOptions, label, ...props }) => {
    const [field, meta] = useField(props);

    const { portfolios } = useSelector(portfolioSelector);

    const portfolioItems = useMemo(() => {
        if (showAllOptions) {
            return PORTFOLIO_TYPES;
        }

        return _.filter(
            PORTFOLIO_TYPES,
            (portfolio) => !_.find(portfolios, { name: portfolio })
        );
    }, [showAllOptions, portfolios]);

    return (
        <SelectField
            width="100%"
            inputHeight={40}
            label={
                <Text textTransform="capitalize" color="#101840">
                    {label}
                </Text>
            }
            isInvalid={meta.touched && meta.error}
            validationMessage={meta.touched && meta.error}
            required
            {...field}
        >
            <option value="" disabled>
                Select {label}
            </option>
            {_.map(portfolioItems, (item) => (
                <option key={item} value={item}>
                    {item}
                </option>
            ))}
        </SelectField>
    );
};

PortfolioTypeSelect.propTypes = {
    showAllOptions: PropTypes.bool,
    label: PropTypes.string.isRequired,
};

PortfolioTypeSelect.defaultProps = {
    showAllOptions: false,
};

export default PortfolioTypeSelect;
