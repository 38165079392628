import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Pane, Heading, Button, PlusIcon } from 'evergreen-ui';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams as useSearchModalParams } from 'react-router-dom';
import { colors } from '../../theme/theme';
import Breadcrumbs from '../../components/molecules/bread-crumbs';
import ContactsTable from '../../components/contacts/contacts-table';
import PageLoading from '../../components/molecules/page-loading';
import ContactsSidebar from '../../components/contacts/contacts-sidebar';
import { getBusinessContacts } from '../../reducers/contacts-reducer/contacts.actions';
import { contactsSelector } from '../../reducers/contacts-reducer/contacts.reducer';
import ConfirmContactDeleteModal from '../../components/contacts/confirm-contact-delete';
import RelativeBuildingsModal from '../../components/contacts/relative-buildings-modal';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import { useUserAccess } from '../../context/user-access-context';
import { useSearchParams } from '../../context/search-params-context';

function ContactsOverviewPage() {
    const dispatch = useDispatch();

    const { currentRights } = useUserAccess();
    const { currentAssetId, currentBuildingId, currentTenantId } =
        useSearchParams();

    const [searchParams, setSearchParams] = useSearchModalParams({
        modalName: '',
    });

    useEffect(() => {
        const modalName = searchParams.get('modalName');
        if (modalName === 'addContact') {
            setContactsSidebar({
                isShown: true,
                isEditing: false,
                addingStaff: false,
                businessContact: null,
                staffContact: null,
            });
        }
    }, [searchParams]);

    const {
        create,
        update,
        delete: _delete,
    } = useMemo(
        () =>
            currentRights || {
                create: false,
                update: false,
                delete: false,
            },
        [currentRights]
    );

    const [loading, setLoading] = useState(true);
    const [contactsSidebar, setContactsSidebar] = useState({
        isShown: false,
        isEditing: false,
        addingStaff: false,
        contact: null,
    });
    const [deleteContact, setDeleteContact] = useState({
        showModal: false,
        contact: null,
    });
    const [relativeBuildingsModal, setRelativeBuildingsModal] = useState({
        isShown: false,
        contactName: null,
        buildings: [],
    });

    const { businessContacts } = useSelector(contactsSelector);

    const handleUrlParamSet = (id) => {
        const nextSearchParams = new URLSearchParams(searchParams);
        const value = id;
        nextSearchParams.set('modalName', value);
        setSearchParams(nextSearchParams);
    };

    const handleUrlParamDelete = () => {
        const nextSearchParams = new URLSearchParams(searchParams);
        nextSearchParams.delete('modalName');
        setSearchParams(nextSearchParams);
    };

    const getData = useCallback(async () => {
        try {
            await Promise.all([
                await dispatch(getBusinessContacts()),
                await dispatch(getNotifications()),
            ]);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    const handleAddNewBusinessContact = (id) => {
        handleUrlParamSet(id);
        setContactsSidebar({
            isShown: true,
            isEditing: false,
            addingStaff: false,
            businessContact: null,
            staffContact: null,
        });
    };

    const handleEditBusinessContact = (businessContact) => {
        setContactsSidebar({
            isShown: true,
            isEditing: true,
            addingStaff: false,
            staffContact: null,
            businessContact,
        });
    };

    const handleAddStaffContact = (businessContact, id) => {
        handleUrlParamSet(id);
        setContactsSidebar({
            isShown: true,
            isEditing: false,
            addingStaff: true,
            businessContact,
            staffContact: null,
        });
    };

    const handleEditStaffContact = (staffContact) => {
        setContactsSidebar({
            isShown: true,
            isEditing: true,
            addingStaff: true,
            businessContact: null,
            staffContact,
        });
    };

    const closeContactsSidebar = () => {
        handleUrlParamDelete();
        setContactsSidebar({
            isShown: false,
            isEditing: false,
            addingStaff: false,
            staffContact: null,
            businessContact: null,
        });
    };

    const openConfirmModal = (contact) => {
        setDeleteContact({
            showModal: true,
            contact,
        });
    };

    const closeConfirmModal = () => {
        setDeleteContact({
            showModal: false,
            contact: null,
        });
    };

    const openRelativeBuildingsModal = (e, name, buildings) => {
        e.stopPropagation();
        setRelativeBuildingsModal({
            isShown: true,
            contactName: name,
            buildings,
        });
    };

    const closeRelativeBuildingsModal = () => {
        setRelativeBuildingsModal({
            isShown: false,
            contactName: null,
            buildings: [],
        });
    };

    useEffect(() => {
        getData();
    }, [currentAssetId, currentBuildingId, currentTenantId, getData]);

    return loading ? (
        <PageLoading />
    ) : (
        <>
            <Pane
                display="flex"
                width="95%"
                maxWidth={1440}
                marginX="auto"
                paddingTop={24}
            >
                <Pane
                    display="flex"
                    flexDirection="column"
                    backgroundColor={colors.neutral300}
                    paddingX={20}
                    width="100%"
                    gap={24}
                    flex={1}
                >
                    <Pane
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        gap={24}
                    >
                        <Pane
                            width="100%"
                            display="flex"
                            justifyContent="start"
                        >
                            <Breadcrumbs />
                        </Pane>
                        <Pane
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            gap={24}
                        >
                            <Heading
                                fontSize={24}
                                fontWeight={700}
                                color="#333333"
                            >
                                Contacts
                            </Heading>
                            {create && (
                                <Button
                                    className="button-add-contact-overview-page"
                                    appearance="primary"
                                    iconBefore={PlusIcon}
                                    onClick={() =>
                                        handleAddNewBusinessContact(
                                            'addContact'
                                        )
                                    }
                                >
                                    Add new Contact
                                </Button>
                            )}
                        </Pane>
                    </Pane>
                    <ContactsTable
                        data={businessContacts}
                        handleEditBusinessContact={handleEditBusinessContact}
                        handleAddStaffContact={handleAddStaffContact}
                        handleEditStaffContact={handleEditStaffContact}
                        openConfirmModal={openConfirmModal}
                        openRelativeBuildingsModal={openRelativeBuildingsModal}
                        close={closeContactsSidebar}
                    />
                </Pane>
            </Pane>
            {(create || update) && contactsSidebar.isShown && (
                <ContactsSidebar
                    isShown={contactsSidebar.isShown}
                    isEditing={contactsSidebar.isEditing}
                    addingStaff={contactsSidebar.addingStaff}
                    businessContact={contactsSidebar.businessContact}
                    staffContact={contactsSidebar.staffContact}
                    close={closeContactsSidebar}
                />
            )}
            {_delete && deleteContact.showModal && (
                <ConfirmContactDeleteModal
                    isShown={deleteContact.showModal}
                    contact={deleteContact.contact}
                    close={closeConfirmModal}
                />
            )}
            {relativeBuildingsModal.isShown && (
                <RelativeBuildingsModal
                    isShown={relativeBuildingsModal.isShown}
                    contactName={relativeBuildingsModal.contactName}
                    buildings={relativeBuildingsModal.buildings}
                    close={closeRelativeBuildingsModal}
                />
            )}
        </>
    );
}

export default ContactsOverviewPage;
