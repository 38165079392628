import React, { useEffect, useState } from 'react';
import { Heading, Pane, Text, Link, Spinner, Alert } from 'evergreen-ui';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AuthScreenLayout from '../../components/layouts/auth-screen-layout';
import signupImage from '../../assets/images/illustrations/signup.svg';
import { Image } from '../../components/atoms';
import { getAuthToken, signUp } from '../../reducers/auth-reducer/auth.actions';
import useQueryParams from '../../hooks/use-query-params';
import DefaultSignupForm from '../../components/forms/auth-forms/default-sign-up-form';
import { registerModel } from '../../models';

const DefaultSignUpPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useQueryParams();

    const [didSubmit, setDidSubmit] = useState(false);

    const { isFetchingAuthToken, isAuthenticated, fetchAuthTokenError } =
        useSelector((state) => state.authReducer);

    useEffect(() => {
        if (token) {
            dispatch(getAuthToken(token, true));
        }
    }, [dispatch, token]);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/portfolio');
        }
    }, [navigate, isAuthenticated]);

    const submitRegisterForm = signUp;

    const onRegisterSuccess = () => {
        setDidSubmit(true);
        navigate('/login');
    };

    const renderHeroComponent = () => (
        <Image src={signupImage} alt="signup illustration" maxWidth="300px" />
    );

    const renderLoader = () => (
        <Pane
            height={350}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner marginBottom="1rem" />
            <Text size={500}>Signing Up...</Text>
        </Pane>
    );

    const renderAlert = () => {
        if (!fetchAuthTokenError) {
            return null;
        }

        return (
            <Alert
                intent="danger"
                title="Email verification failed"
                marginTop={16}
            >
                {fetchAuthTokenError}
            </Alert>
        );
    };

    const renderSignUpForm = () => (
        <>
            <Heading is="h3" size={600} marginBottom="0.5rem">
                Convenient record keeping starts here{' '}
                <span role="img" aria-label="waving">
                    🚀
                </span>
            </Heading>
            <Text size={500}>
                Sign up and start capturing your asset records in just minutes.
            </Text>
            {renderAlert()}
            <DefaultSignupForm
                onSuccess={onRegisterSuccess}
                submitForm={submitRegisterForm}
                initialValues={registerModel({ email: '' })}
            />
            <Text size={400} alignSelf="center" marginTop="24px">
                Already have an account?{' '}
                <Link is={RouterLink} to="/login">
                    Log In
                </Link>
            </Text>
        </>
    );

    return (
        <AuthScreenLayout heroComponent={renderHeroComponent()}>
            <Pane
                display="flex"
                flexDirection="column"
                maxWidth="500px"
                width="100%"
            >
                {isFetchingAuthToken || didSubmit
                    ? renderLoader()
                    : renderSignUpForm()}
            </Pane>
        </AuthScreenLayout>
    );
};

export default DefaultSignUpPage;
