const authBaseUrl = '/auth';

export default {
    loginUrl: () => `${authBaseUrl}/magic-link`,
    defaultLoginUrl: () => `${authBaseUrl}/login`,
    createPasswordUrl: () => `${authBaseUrl}/update-password`,
    updatePasswordUrl: () => `${authBaseUrl}/update-password`,
    resetPasswordUrl: () => `${authBaseUrl}/reset-password`,
    registerUrl: () => `${authBaseUrl}/signup`,
    signUpUrl: () => `${authBaseUrl}/signup_v2`,
    confirmEmailUrl: () => `${authBaseUrl}/confirmation-v2`,
    confirmEmailResetUrl: () => `${authBaseUrl}/reset-password-confirmation`,
    getAuthTokenUrl: () => `${authBaseUrl}/magic-login-v2`,
    refreshAuthToken: () => `${authBaseUrl}/refresh-v2`,
    systemStatusUrl: () => '/system/status',
    passwordConfirmationUrl: () => `${authBaseUrl}/password-confirmation`,
};
