import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from 'react-router-dom';

import LandingPage from '../pages/landing-page/landing-page';
import LoginPage from '../pages/auth-pages/login-page';
import DefaultSignUpPage from '../pages/auth-pages/default-sign-up-page';
import PortfolioOverviewPage from '../pages/portfolio-page/portfolio-overview-page';
import CreateBusinessPage from '../pages/business-pages/create-business-page';
import AssetPage from '../pages/asset-pages/asset-page';
import UploadRequestPage from '../pages/upload-request/upload-request-page';
import DashboardLayout from '../components/layouts/dashboard-layout';
import ErrorPage from '../pages/other-pages/error-page';
import TaskOverviewPage from '../pages/task-pages/task-overview-page';
import MaintenanceOverviewPage from '../pages/maintenance-pages/maintenance-overview-page';
import PortfolioBuildingsPage from '../pages/portfolio-page/portfolio-buildings-page';
import AssetDocumentsPage from '../pages/asset-pages/asset-documents-page';
import ContactsOverviewPage from '../pages/contact-pages/contacts-overview-page';
import TemplateManagement from '../pages/template-management-pages/template-management';
import UserManagement from '../pages/user-management-pages/user-management';
import UpdatePasswordPage from '../pages/auth-pages/update-password-page';
import ResetPasswordPage from '../pages/auth-pages/reset-password-page';

const AppRoutes = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<ErrorPage />}>
            <Route
                path="/"
                key="ROOT"
                element={<LandingPage />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="login"
                key="LOGIN"
                element={<LoginPage />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="signup"
                key="SIGNUP"
                element={<DefaultSignUpPage />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/reset-password"
                key="RESET_PASSWORD"
                element={<ResetPasswordPage />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/update-password"
                key="UPDATE_PASSWORD"
                element={<UpdatePasswordPage />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/"
                element={<DashboardLayout />}
                errorElement={<ErrorPage />}
            >
                <Route
                    path="portfolio"
                    key="PORTFOLIO"
                    element={<PortfolioOverviewPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="portfolio/properties"
                    element={<PortfolioBuildingsPage />}
                />
                {/* currentAsset */}
                <Route
                    path="portfolio/properties/parks/:assetId/info"
                    element={<AssetPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="portfolio/properties/parks/:assetId/buildings/:buildingId/info"
                    element={<AssetPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="portfolio/properties/parks/:assetId/buildings/:buildingId/tenants/:tenantId/info"
                    element={<AssetPage />}
                    errorElement={<ErrorPage />}
                />
                {/* currentBuilding (No parent) */}
                <Route
                    path="portfolio/properties/buildings/:buildingId/info"
                    element={<AssetPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="portfolio/properties/buildings/:buildingId/tenants/:tenantId/info"
                    element={<AssetPage />}
                    errorElement={<ErrorPage />}
                />
                {/* currentTenant (No Parent) */}
                <Route
                    path="portfolio/properties/tenants/:tenantId/info"
                    element={<AssetPage />}
                    errorElement={<ErrorPage />}
                />
                {/* currentAsset */}
                <Route
                    path="portfolio/properties/parks/:assetId/compliance"
                    element={<AssetDocumentsPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="portfolio/properties/parks/:assetId/buildings/:buildingId/compliance"
                    element={<AssetDocumentsPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="portfolio/properties/parks/:assetId/buildings/:buildingId/tenants/:tenantId/compliance"
                    element={<AssetDocumentsPage />}
                    errorElement={<ErrorPage />}
                />

                {/* currentBuilding (No Parent) */}
                <Route
                    path="portfolio/properties/buildings/:buildingId/compliance"
                    element={<AssetDocumentsPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="portfolio/properties/buildings/:buildingId/tenants/:tenantId/compliance"
                    element={<AssetDocumentsPage />}
                    errorElement={<ErrorPage />}
                />
                {/* currentTenant (No Parent) */}
                <Route
                    path="portfolio/properties/tenants/:tenantId/compliance"
                    element={<AssetDocumentsPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="maintenance"
                    key="OVERVIEW_PAGE"
                    element={<MaintenanceOverviewPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="tasks"
                    element={<TaskOverviewPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="contacts"
                    element={<ContactsOverviewPage />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="template-management"
                    element={<TemplateManagement />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="user-management"
                    element={<UserManagement />}
                    errorElement={<ErrorPage />}
                />
            </Route>
            <Route
                path="/create-business"
                key="CREATE_BUSINESS"
                element={<CreateBusinessPage />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/upload-request"
                key="UPLOAD_REQUEST"
                element={<UploadRequestPage />}
                errorElement={<ErrorPage />}
            />
        </Route>
    )
);

export default AppRoutes;
