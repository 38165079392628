import React from 'react';
import {
    Pane,
    Tooltip,
    TextInputField,
    CrossIcon,
    TickIcon,
} from 'evergreen-ui';

import PropTypes from 'prop-types';

function EditForm({ value, handleEdit, cancelEdit, ref, onChangeValue }) {
    return (
        <Pane
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
        >
            <Tooltip content="Press Enter to Confirm">
                <TextInputField
                    label={`Editing ${value}`}
                    placeholder={value}
                    onKeyDown={handleEdit}
                    maxWidth={270}
                    inputWidth={270}
                    ref={ref}
                    marginRight={10}
                    autoFocus
                    onChange={onChangeValue}
                />
            </Tooltip>
            <Pane display="flex" alignItems="center">
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    hoverElevation={1}
                    borderRadius={9999}
                    padding={4}
                    role="button"
                    onClick={handleEdit}
                    height={25}
                    marginY="auto"
                    marginRight={8}
                >
                    <TickIcon size={20} color="#47B881" cursor="pointer" />
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    hoverElevation={1}
                    borderRadius={9999}
                    padding={4}
                    role="button"
                    onClick={cancelEdit}
                    height={25}
                    marginY="auto"
                >
                    <CrossIcon size={20} color="#8f95b2" cursor="pointer" />
                </Pane>
            </Pane>
        </Pane>
    );
}

EditForm.propTypes = {
    value: PropTypes.string.isRequired,
    handleEdit: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
    ref: PropTypes.string.isRequired,
    onChangeValue: PropTypes.func.isRequired,
};

export default EditForm;
