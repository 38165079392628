import React, { useMemo, useState } from 'react';
import {
    Pane,
    Text,
    EditIcon,
    InheritanceIcon,
    DocumentIcon,
    Badge,
    ChevronDownIcon,
    RemoveIcon,
    TickCircleIcon,
    Button,
    Tooltip,
    TrashIcon,
    Dialog,
} from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../theme/theme';
import { getTaskModel, taskUpdateModel } from '../../models/task.models';
import getDate from '../../helpers/get-date';
import useQueryParams from '../../hooks/use-query-params';
import { Collapsible } from '../atoms';
import { useUserAccess } from '../../context/user-access-context';
import showSuccessToastMessage from '../toasts/success-toast';
import showErrorToastMessage from '../toasts/error-toast';

function TaskStepper({
    task,
    openEditTaskSidesheet,
    openUpdateTaskSidesheet,
    openViewTasksDocumentsSidesheet,
    deleteTask,
}) {
    const [showSteps, setShowSteps] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const { currentRights } = useUserAccess();

    const {
        create,
        read,
        update,
        delete: _delete,
    } = useMemo(
        () =>
            currentRights || {
                create: false,
                read: false,
                update: false,
                delete: false,
            },
        [currentRights]
    );

    const { taskItemId } = useQueryParams();

    const {
        description,
        dueDate,
        progress,
        status,
        taskType,
        updates,
        uploadPending,
        building,
    } = getTaskModel(task);

    const isOverdue = dueDate <= Date.now() && status !== 'completed';

    const statusColor = {
        'up to date': { color: '#50C878', backgroundColor: '#50C87840' },
        completed: { color: '#50C878', backgroundColor: '#50C87840' },
        overdue: { color: '#B3261E', backgroundColor: '#B3261E50' },
        pending: { color: '#FFA500', backgroundColor: '#FFA50050' },
        notified: { color: '#696F8C', backgroundColor: '#696F8C50' },
        'in progress': { color: '#6495ED', backgroundColor: '#6495ED50' },
        new: { color: '#7F00FF', backgroundColor: '#7F00FF30' },
        default: {
            backgroundColor: '#D3F5F7',
            color: '#0F5156',
        },
    };

    const deleteCurrentTask = async () => {
        setDeleting(true);
        try {
            await deleteTask(task.id);
            showSuccessToastMessage('Task deleted successfully');
        } catch (e) {
            showErrorToastMessage('Error deleting task');
        } finally {
            setDeleting(false);
        }
        setShowConfirmDelete(false);
    };

    return (
        <TaskContainer width="100%" showSteps={showSteps}>
            <Pane
                display="flex"
                justifyItems="space-evenly"
                alignItems="center"
                maxWidth="100%"
                flex={1}
                cursor="pointer"
                position="relative"
                onClick={() => setShowSteps(!showSteps)}
                paddingY={10}
                minHeight="80px"
                background={`${
                    Number(task.id) === Number(taskItemId)
                        ? '#f2f4fa'
                        : 'transparent'
                }`}
            >
                <Pane
                    display="flex"
                    flex={1}
                    alignItems="center"
                    gap={15}
                    paddingX={15}
                >
                    <ChevronDownIcon
                        className="icon"
                        transform={showSteps && 'rotate(90deg)'}
                        transition="transform 250ms"
                    />
                    <Pane
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        minWidth={178}
                    >
                        <Text
                            textTransform="capitalize"
                            fontWeight={600}
                            color={colors.primary500}
                        >
                            {description} - {taskType.name}
                        </Text>
                        <Text>{building?.name}</Text>
                    </Pane>
                </Pane>
                <Pane maxWidth={120} flex={1} display="flex" paddingLeft={15}>
                    <Badge
                        color={
                            statusColor[status.toLowerCase()].backgroundColor ||
                            statusColor.default.backgroundColor
                        }
                        display="flex"
                        alignItems="center"
                    >
                        <Text
                            fontWeight={700}
                            fontSize={12}
                            color={
                                statusColor[status.toLowerCase()].color ||
                                statusColor.default.color
                            }
                        >
                            {status}
                        </Text>
                    </Badge>
                </Pane>
                <Pane
                    minWidth={150}
                    maxWidth={200}
                    display="flex"
                    flex={1}
                    paddingX={15}
                >
                    <Pane
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap={4}
                        maxWidth={230}
                    >
                        <Text color="#3A3541DE">
                            {uploadPending
                                ? `${Math.ceil(progress * 100)} (pending)`
                                : `${Math.ceil(progress * 100)}%`}
                        </Text>
                        <Pane
                            width="100%"
                            height={6}
                            backgroundColor="#E6E8F0"
                            borderRadius={999}
                            overflow="hidden"
                        >
                            <Pane
                                width={`${Math.ceil(progress * 100)}%`}
                                backgroundColor="#B2C9DF"
                                height={6}
                            />
                        </Pane>
                    </Pane>
                </Pane>
                <Pane maxWidth={120} flex={1} paddingLeft={15}>
                    <Text color={isOverdue ? '#B3261E' : colors.neutral700}>
                        {getDate(dueDate)}
                    </Text>
                </Pane>
                {/* Options */}
                <Pane
                    display="flex"
                    paddingX={8}
                    flex={1}
                    justifyContent="center"
                    marginLeft="auto"
                    maxWidth={150}
                    onClick={(e) => e.stopPropagation()}
                >
                    {(update || read || _delete) && (
                        <Pane gap={5} marginLeft="auto" className="options">
                            <Pane display={update ? 'flex' : 'none'} gap={10}>
                                <Tooltip
                                    content="Edit Task"
                                    showDelay={300}
                                    hideDelay={0}
                                >
                                    <Pane
                                        alignItems="center"
                                        justifyContent="center"
                                        padding={12}
                                        className="edit-contact"
                                        hoverElevation={1}
                                        borderRadius="4px"
                                        role="button"
                                        background="#f5f6fa"
                                        onClick={() =>
                                            openEditTaskSidesheet(task)
                                        }
                                    >
                                        <EditIcon
                                            color="#696f8c"
                                            className="edit-contact"
                                        />
                                    </Pane>
                                </Tooltip>
                            </Pane>
                            <Pane display={read ? 'flex' : 'none'} gap={10}>
                                <Tooltip
                                    content="View Task Attachments"
                                    showDelay={300}
                                    hideDelay={0}
                                >
                                    <Pane
                                        alignItems="center"
                                        justifyContent="center"
                                        padding={12}
                                        className="delete-contact"
                                        background="#f5f6fa"
                                        hoverElevation={1}
                                        borderRadius="4px"
                                        role="button"
                                        onClick={() =>
                                            openViewTasksDocumentsSidesheet(
                                                task,
                                                'viewDocuments'
                                            )
                                        }
                                    >
                                        <DocumentIcon
                                            color="#696f8c"
                                            className="delete-contact"
                                        />
                                    </Pane>
                                </Tooltip>
                            </Pane>
                            <Pane display={_delete ? 'flex' : 'none'} gap={10}>
                                <Tooltip
                                    content="Delete Task"
                                    showDelay={300}
                                    hideDelay={0}
                                >
                                    <Pane
                                        alignItems="center"
                                        justifyContent="center"
                                        padding={12}
                                        className="edit-contact"
                                        hoverElevation={1}
                                        borderRadius="4px"
                                        role="button"
                                        background="#f5f6fa"
                                        onClick={() =>
                                            setShowConfirmDelete(true)
                                        }
                                    >
                                        <TrashIcon
                                            color="#B3261E"
                                            className="delete-contact"
                                        />
                                    </Pane>
                                </Tooltip>
                            </Pane>
                        </Pane>
                    )}
                </Pane>
            </Pane>
            <Pane
                paddingLeft={55}
                position="relative"
                marginBottom={showSteps ? 10 : 0}
                maxHeight="500px"
                overflowY="auto"
            >
                {showSteps && (create || update) && (
                    <Button
                        marginY={10}
                        marginTop={15}
                        iconAfter={<InheritanceIcon />}
                        onClick={() => openUpdateTaskSidesheet(task)}
                    >
                        Update Task
                    </Button>
                )}
                {updates.length >= 1 &&
                    [...updates].reverse().map((_update) => {
                        const {
                            id: updateId,
                            description: taskDescription,
                            title,
                            createdAt,
                        } = taskUpdateModel(_update);
                        const updateComplete = _update.status === 'completed';

                        return (
                            <Collapsible isOpen={showSteps} key={updateId}>
                                <Pane
                                    borderLeft={`2.5px solid ${colors.neutral300}`}
                                    display="flex"
                                    flexDirection="column"
                                    paddingLeft={20}
                                    paddingY={10}
                                    gap={12}
                                >
                                    <Pane
                                        display="flex"
                                        alignItems="center"
                                        gap={4}
                                    >
                                        {updateComplete ? (
                                            <TickCircleIcon
                                                width="100%"
                                                height="100%"
                                                color="#50C878"
                                                opacity={0.7}
                                                position="absolute"
                                                left={48}
                                                backgroundColor="white"
                                            />
                                        ) : (
                                            <RemoveIcon
                                                color="grey"
                                                position="absolute"
                                                left={48}
                                                backgroundColor="white"
                                            />
                                        )}
                                        <Text
                                            fontSize={16}
                                            fontWeight={600}
                                            color="#696F8C"
                                        >
                                            {title}
                                        </Text>
                                    </Pane>
                                    {taskDescription && (
                                        <Text fontSize={14} color="#474D66">
                                            {taskDescription}
                                        </Text>
                                    )}
                                    <Text>{getDate(createdAt)}</Text>
                                </Pane>
                            </Collapsible>
                        );
                    })}
            </Pane>
            <Dialog
                isShown={showConfirmDelete}
                title="Delete Task?"
                intent="danger"
                onCloseComplete={() => setShowConfirmDelete(false)}
                confirmLabel="Delete"
                onConfirm={deleteCurrentTask}
                isConfirmLoading={deleting}
            >
                Are you sure you want to delete task {description}?
            </Dialog>
        </TaskContainer>
    );
}

const TaskContainer = styled(Pane)`
    transition: all 300ms ease;
    .options {
        display: none;
        flex-direction: row;
    }
    &:hover {
        .options {
            display: flex;
        }
    }
    .icon {
        transform: rotate(
            ${({ showSteps }) => (!showSteps ? '-90deg' : '0deg')}
        );
    }
`;

TaskStepper.propTypes = {
    assignedUser: PropTypes.shape({}).isRequired,
    taskCategory: PropTypes.shape({}).isRequired,
    taskType: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    openEditTaskSidesheet: PropTypes.func.isRequired,
    openUpdateTaskSidesheet: PropTypes.func.isRequired,
    task: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    openViewTasksDocumentsSidesheet: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
};

export default TaskStepper;
