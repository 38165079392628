import React, { useEffect, useMemo } from 'react';
import { Alert, Pane, Spinner, Text } from 'evergreen-ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    getAuthToken,
    getSystemStatus,
} from '../../reducers/auth-reducer/auth.actions';
import AuthScreenLayout from '../../components/layouts/auth-screen-layout';
import { Image } from '../../components/atoms';
import loginImage from '../../assets/images/illustrations/login.svg';

import DefaultLoginPage from './default-login-page';
import { defaultLoginModel } from '../../models';
import { authSelector } from '../../reducers/auth-reducer/auth.reducer';

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const token = useMemo(() => searchParams.get('token'), [searchParams]);

    const { email, isFetchingAuthToken, isAuthenticated, fetchAuthTokenError } =
        useSelector(authSelector);

    useEffect(() => {
        dispatch(getSystemStatus());
        if (token) {
            dispatch(getAuthToken(token, false, setSearchParams));
        }
    }, [dispatch, token, setSearchParams]);

    useEffect(() => {
        if (isAuthenticated && !token) {
            navigate('/portfolio', { replace: true });
        }
    }, [navigate, isAuthenticated, token]);

    const renderHeroComponent = () => (
        <Image src={loginImage} alt="signup illustration" maxWidth="300px" />
    );

    const renderAlert = () => {
        if (!fetchAuthTokenError) {
            return null;
        }

        return (
            <Alert intent="danger" title="Login failed" marginTop={16}>
                {fetchAuthTokenError}
            </Alert>
        );
    };

    const renderFetchMagicTokenLoader = () => (
        <Pane
            height={350}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner marginBottom="1rem" />
            <Text size={500}>Logging you in...</Text>
        </Pane>
    );

    const renderDefaultLoginPage = () => (
        <DefaultLoginPage initialValues={defaultLoginModel({ email })} />
    );

    return (
        <AuthScreenLayout heroComponent={renderHeroComponent()}>
            <Pane
                display="flex"
                flexDirection="column"
                maxWidth="400px"
                width="100%"
            >
                {renderAlert()}
                {isFetchingAuthToken
                    ? renderFetchMagicTokenLoader()
                    : renderDefaultLoginPage()}
                <image href="../assets/images/illustrations/placeholder-building.svg" />
            </Pane>
        </AuthScreenLayout>
    );
};

export default LoginPage;
