import React, { useState } from 'react';
import _ from 'lodash';
import {
    Button,
    InfoSignIcon,
    Pane,
    PlusIcon,
    Spinner,
    Text,
    Tooltip,
} from 'evergreen-ui';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AddForm from './forms-templates/AddForm';
import { addComplianceGroup } from '../../../reducers/compliance-template-reducer/compliance-template.actions';

import { complianceTemplateSelector } from '../../../reducers/compliance-template-reducer/compliance-template.reducer';

import ComplianceTemplateGroup from './compliance-group-template-form/ComplianceTemplateGroup';

function ComplianceFrom({ type, loading, setShowComplianceModal }) {
    const [newGroup, setNewGroup] = useState({
        group: '',
        type,
        order: 4,
        category: [],
        isAdding: false,
    });

    const dispatch = useDispatch();

    const { complianceTemplate } = useSelector(complianceTemplateSelector);

    const addGroup = async ({ key, type: buttonType }) => {
        if (buttonType === 'click' || key === 'Enter') {
            dispatch(addComplianceGroup(newGroup));
            cancelAddGroup();
        }
    };

    const handleNewGroup = async ({ target: { value } }) => {
        setNewGroup((state) => ({
            ...state,
            group: value,
        }));
    };

    const cancelAddGroup = () => {
        setNewGroup({
            group: '',
            type,
            order: 4,
            category: [],
            isAdding: false,
        });
    };

    return (
        <div
            style={{
                height: '500px',
                display: 'flex',
                overflow: 'auto',
                width: '420px',
            }}
        >
            <Pane display="flex" width={400} flexDirection="column" gap={10}>
                {newGroup.isAdding ? (
                    <Pane paddingLeft={20}>
                        <AddForm
                            add={addGroup}
                            placeholder="Add New Group"
                            handleChange={handleNewGroup}
                            cancelAdd={cancelAddGroup}
                        />
                        <Text>Expand</Text>
                    </Pane>
                ) : (
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={10}
                    >
                        <Pane display="flex" alignItems="center" gap={10}>
                            <Button
                                appearance="minimal"
                                iconBefore={<PlusIcon />}
                                marginLeft={20}
                                onClick={() =>
                                    setNewGroup({
                                        group: '',
                                        type,
                                        order: 4,
                                        category: [],
                                        isAdding: true,
                                    })
                                }
                            >
                                Add Group
                            </Button>
                            <Tooltip content="Here you can create a grouping for your folders. You can give it a name that best represents the overall category or theme of your compliance documents. For Example: 'Maintenance Compliance' or 'Finance Compliance'">
                                <InfoSignIcon size={14} color="#c8cacf" />
                            </Tooltip>
                        </Pane>
                        <Text
                            marginRight={10}
                            cursor="pointer"
                            role="button"
                            onClick={() => setShowComplianceModal(true)}
                            style={{
                                transition: 'color 0.2s ease',
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.color = '#5EC090';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.color = 'inherit';
                            }}
                        >
                            Expand
                        </Text>
                    </Pane>
                )}
                {loading ? (
                    <Pane
                        display="flex"
                        flexDirection="column"
                        gap={10}
                        marginY={30}
                        alignItems="center"
                    >
                        <Spinner />
                        <Text>Loading...</Text>
                    </Pane>
                ) : (
                    _.orderBy(complianceTemplate, ['order'], 'asc').map(
                        (group) => <ComplianceTemplateGroup group={group} />
                    )
                )}
            </Pane>
        </div>
    );
}

ComplianceFrom.propTypes = {
    type: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    setShowComplianceModal: PropTypes.string.isRequired,
};

export default ComplianceFrom;
