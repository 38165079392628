import React, { useState, useMemo } from 'react';
import { Pane, Popover, Menu, MoreIcon, Position } from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useUserAccess } from '../../../context/user-access-context';

function ManagementMenu({
    asset,
    handleOpenSideBar,
    handleOpenArchive,
    handleOpenDelete,
    handleOpenExport,
}) {
    const [selected, setSelected] = useState(false);

    const { allRights } = useUserAccess();

    const rights = useMemo(
        () =>
            allRights?.info || {
                create: false,
                update: false,
                delete: false,
            },
        [allRights]
    );

    const handleMouseLeave = () => {
        setSelected(false);
    };

    return (
        <Popover
            isShown={selected}
            position={Position.BOTTOM_RIGHT}
            shouldCloseOnExternalClick
            content={
                <div onMouseLeave={handleMouseLeave}>
                    <Menu>
                        <Menu.Group>
                            {asset?.type !== 'park' &&
                                (rights.update || rights.create) && (
                                    <Menu.Item
                                        onSelect={() => {
                                            if (
                                                rights.update ||
                                                rights.create
                                            ) {
                                                setSelected(false);
                                                handleOpenSideBar('migration');
                                            }
                                        }}
                                        isDisabled={
                                            !rights.update || rights.create
                                        }
                                    >
                                        Migrate/Linking
                                    </Menu.Item>
                                )}
                            {rights.delete && (
                                <Menu.Item
                                    onSelect={() => {
                                        setSelected(false);
                                        handleOpenArchive('archive');
                                    }}
                                >
                                    Archive
                                </Menu.Item>
                            )}
                            {rights.delete && (
                                <Menu.Item
                                    onSelect={() => {
                                        setSelected(false);
                                        handleOpenDelete('delete');
                                    }}
                                >
                                    Delete property permanently
                                </Menu.Item>
                            )}
                            <Menu.Item
                                onSelect={() => {
                                    setSelected(false);
                                    handleOpenExport('exportData');
                                }}
                            >
                                Export
                            </Menu.Item>
                        </Menu.Group>
                    </Menu>
                </div>
            }
        >
            <BackgroundHover id="template-icon">
                <Pane onClick={() => setSelected(!selected)}>
                    <MoreIcon transform="rotate(90deg)" />
                </Pane>
            </BackgroundHover>
        </Popover>
    );
}

const BackgroundHover = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

ManagementMenu.propTypes = {
    asset: PropTypes.string.isRequired,
    handleOpenSideBar: PropTypes.func.isRequired,
    handleOpenArchive: PropTypes.func.isRequired,
    handleOpenDelete: PropTypes.func.isRequired,
    handleOpenExport: PropTypes.func.isRequired,
};

export default ManagementMenu;
