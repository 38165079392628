import React, { useMemo, useState } from 'react';
import {
    Pane,
    TextInputField,
    Button,
    SelectField,
    Text,
    ErrorIcon,
    SelectMenu,
    Checkbox,
    InfoSignIcon,
    Tooltip,
} from 'evergreen-ui';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';

import {
    addUser,
    linkPropertiesToUser,
} from '../../reducers/user-management-reducer/user-management.actions';
import { successToast } from '../toasts';
import { getAvailableAssignees } from '../../reducers/task-reducer/task.actions';

function AddUserForm({
    close,
    roles,
    properties,
    propertyGroups,
    setShowPropertyGroupsDialog,
    setShowRolesDialog,
}) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const allPropGroup = propertyGroups.find(
        (prop) => prop.name === 'All Properties'
    );

    const initialValues = {
        name: '',
        surname: '',
        email: '',
        mobileNo: '',
        isTenant: false,
        roleId: '',
        propertyIds: [],
        propertyGroupIds: [allPropGroup.id],
    };

    const validate = ({ name, surname, email, mobileNo, roleId }) => {
        const errors = {};
        if (!name) {
            errors.name = 'Name is Required';
        }
        if (!surname) {
            errors.surname = 'Surname is Required';
        }
        if (!email) {
            errors.email = 'Email is Required';
        }
        if (!mobileNo) {
            errors.mobileNo = 'Mobile Number is Required';
        }
        if (!roleId) {
            errors.role = 'Role is Required';
        }

        return errors;
    };

    const onSubmit = async (user) => {
        setLoading(true);
        try {
            const { id: userId } = await dispatch(
                addUser({
                    ...user,
                    role: user.isTenant ? 'tenant' : 'assetmanager',
                })
            );

            await dispatch(
                linkPropertiesToUser({
                    buildingIds: user.propertyIds,
                    propertyGroupIds: user.propertyGroupIds,
                    userId,
                    isLinkingGroup: user.propertyGroupIds.length > 0,
                })
            );
            await dispatch(getAvailableAssignees());
            successToast(`Successfully Added ${user.name} ${user.surname}`);

            close();
        } catch (error) {
            if (error) {
                setErrors({
                    ...errors,
                    propertyGroupIds: `An Error Occurred: ${get(
                        error,
                        'response.data.message',
                        `Failed to create ${user.name} ${user.surname}`
                    )}`,
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const { handleChange, values, handleSubmit, errors, setValues, setErrors } =
        useFormik({
            validateOnChange: false,
            validateOnBlur: false,
            initialValues,
            validate,
            onSubmit,
        });

    const propertyGroupOptions = useMemo(() => {
        const sortedPropertyGroups = [...propertyGroups].sort(({ id }) => {
            if (values.propertyIds?.includes(Number(id))) {
                return -1;
            }
            return 1;
        });
        return sortedPropertyGroups.map(({ id, name }) => ({
            value: Number(id),
            label: name,
        }));
    }, [propertyGroups, values]);

    const propertyOptions = useMemo(() => {
        const sortedProperties = [...properties].sort(({ id }) => {
            if (values.propertyIds?.includes(Number(id))) {
                return -1;
            }
            return 1;
        });
        return sortedProperties.map(({ id, assetName }) => ({
            value: Number(id),
            label: assetName,
        }));
    }, [properties, values]);

    const propertyButtonText = useMemo(() => {
        if (!values.propertyIds.length) {
            return 'Select Properties';
        }
        return propertyOptions
            .map(({ label, value }) => {
                if (values.propertyIds?.includes(value)) {
                    return label;
                }
                return null;
            })
            .filter((value) => !!value)
            .join(', ');
    }, [propertyOptions, values]);

    const propertyGroupButtonText = useMemo(() => {
        if (!values.propertyGroupIds.length) {
            return 'Select Property Groups';
        }

        return propertyGroupOptions
            .map(({ label, value }) => {
                if (values.propertyGroupIds?.includes(value)) {
                    return label;
                }
                return null;
            })
            .filter((value) => !!value)
            .join(', ');
    }, [propertyGroupOptions, values]);

    const onPropertySelect = ({ value }) => {
        setValues({
            ...values,
            propertyIds: [...values.propertyIds, Number(value)],
        });
    };
    const onPropertyDeselect = ({ value }) => {
        setValues({
            ...values,
            propertyIds: values.propertyIds.filter(
                (id) => Number(id) !== Number(value)
            ),
        });
    };

    const onPropertyGroupSelect = ({ value }) => {
        setValues({
            ...values,
            propertyGroupIds: [...values.propertyGroupIds, Number(value)],
        });
    };
    const onPropertyGroupDeselect = ({ value }) => {
        setValues({
            ...values,
            propertyGroupIds: values.propertyGroupIds.filter(
                (id) => Number(id) !== Number(value)
            ),
        });
    };

    const handleRole = ({ target: { value } }) => {
        setValues({
            ...values,
            roleId: value === 'add' ? value : Number(value),
        });
    };

    const handleIsTenant = () => {
        setValues({
            ...values,
            isTenant: !values.isTenant,
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextInputField
                label="Name"
                name="name"
                placeholder="Jane"
                value={values.name}
                onChange={handleChange}
                validationMessage={errors.name}
                inputHeight={40}
                required
                autoFocus
            />
            <div className="divider" />
            <TextInputField
                label="Surname"
                name="surname"
                placeholder="Doe"
                value={values.surname}
                onChange={handleChange}
                validationMessage={errors.surname}
                inputHeight={40}
                required
                autoFocus
            />
            <div className="divider" />
            <TextInputField
                label="Email"
                name="email"
                placeholder="johndoe@email.com"
                value={values.email}
                onChange={handleChange}
                validationMessage={errors.email}
                inputHeight={40}
                required
                autoFocus
                type="email"
            />
            <div className="divider" />
            <TextInputField
                label="Mobile No."
                name="mobileNo"
                placeholder="123 345 5678"
                value={values.mobileNo}
                onChange={handleChange}
                validationMessage={errors.mobileNo}
                inputHeight={40}
                required
                autoFocus
            />
            <div className="divider" />
            <Checkbox
                checked={values.isTenant}
                label="Is this user a tenant?"
                onChange={handleIsTenant}
            />
            <div className="divider" />
            <SelectField
                name="role"
                label={
                    <Pane display="flex" gap={5} alignItems="center">
                        <Text fontSize={14} color="#101840">
                            Role *
                        </Text>
                        <Tooltip
                            content="Assign a role (Access & Rights) to this user"
                            position="right"
                        >
                            <InfoSignIcon
                                size={12}
                                color="#101840"
                                marginTop={2}
                            />
                        </Tooltip>
                    </Pane>
                }
                value={values.roleId}
                inputHeight={40}
                onChange={handleRole}
                validationMessage={errors.role}
                marginBottom={12}
            >
                <option value="" disabled>
                    Select Role
                </option>
                {roles.length ? (
                    roles.map((role) => (
                        <option key={role.id} value={role.id}>
                            {role.name}
                        </option>
                    ))
                ) : (
                    <option disabled>No Roles</option>
                )}
            </SelectField>
            <AddNewTextButton>
                <Text onClick={() => setShowRolesDialog(true)} marginTop={8}>
                    Edit Roles
                </Text>
            </AddNewTextButton>
            <div className="divider" />

            <Pane>
                <Pane display="flex" gap={5} alignItems="center">
                    <Text fontSize={14} color="#101840">
                        Properties
                    </Text>
                    <Tooltip
                        content="Assign individual properties to this user"
                        position="right"
                    >
                        <InfoSignIcon size={12} color="#101840" marginTop={2} />
                    </Tooltip>
                </Pane>
                <SelectMenu
                    isMultiSelect
                    label="Property"
                    inputHeight={40}
                    onSelect={onPropertySelect}
                    onDeselect={onPropertyDeselect}
                    options={propertyOptions}
                    selected={values.propertyIds}
                    title="Select Properties"
                    filterPlaceholder="Search Properties"
                >
                    <Button
                        justifyContent="space-between"
                        display="flex"
                        flex={11}
                        width="calc(100%)"
                        height={40}
                        type="button"
                        marginTop={8}
                    >
                        <Text
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                        >
                            {propertyButtonText}
                        </Text>
                    </Button>
                </SelectMenu>
            </Pane>
            <div className="divider" />
            <Pane>
                <Pane display="flex" gap={5} alignItems="center">
                    <Text fontSize={14} color="#101840">
                        Property Group(s)
                    </Text>
                    <Tooltip
                        content="Assign groups of properties to this user"
                        position="right"
                    >
                        <InfoSignIcon size={12} color="#101840" marginTop={2} />
                    </Tooltip>
                </Pane>
                <SelectMenu
                    isMultiSelect
                    label="Property Group"
                    inputHeight={40}
                    onSelect={onPropertyGroupSelect}
                    onDeselect={onPropertyGroupDeselect}
                    options={propertyGroupOptions}
                    selected={values.propertyGroupIds}
                    title="Select Property Groups"
                    filterPlaceholder="Search Property Groups"
                >
                    <Button
                        justifyContent="space-between"
                        display="flex"
                        flex={11}
                        width="calc(100%)"
                        height={40}
                        marginTop={8}
                        marginBottom={12}
                        type="button"
                    >
                        <Text
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                        >
                            {propertyGroupButtonText}
                        </Text>
                    </Button>
                </SelectMenu>
                <AddNewTextButton marginBottom={4}>
                    <Text
                        onClick={() => setShowPropertyGroupsDialog(true)}
                        marginTop={8}
                    >
                        Edit Property Groups
                    </Text>
                </AddNewTextButton>
                {!!errors.propertyGroupIds && (
                    <Text
                        lineHeight="18px"
                        fontSize="12px"
                        color="#D14343"
                        display="flex"
                        alignItems="center"
                    >
                        <ErrorIcon size={14} marginRight="6px" />{' '}
                        {errors.propertyGroupIds}
                    </Text>
                )}
            </Pane>

            <Pane
                display="flex"
                gap={16}
                justifyContent="stretch"
                marginTop="auto"
                marginY={24}
                bottom={24}
            >
                <Button width="100%" type="button" onClick={close}>
                    Cancel
                </Button>
                <Button width="100%" appearance="primary" isLoading={loading}>
                    Submit
                </Button>
            </Pane>
        </form>
    );
}

const AddNewTextButton = styled(Pane)`
    span {
        color: #5ec090;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

AddUserForm.propTypes = {
    close: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    propertyGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setShowPropertyGroupsDialog: PropTypes.func.isRequired,
    setShowRolesDialog: PropTypes.func.isRequired,
};

export default AddUserForm;
