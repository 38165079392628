import React, { useEffect, useState, useCallback } from 'react';
import {
    Dialog,
    Heading,
    Spinner,
    Pane,
    Text,
    Button,
    PlusIcon,
    TextInputField,
    Tooltip,
    CrossIcon,
    InfoSignIcon,
    TickIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import ComplianceTemplateGroup from './complinace-template-group';
import { complianceTemplateSelector } from '../../reducers/compliance-template-reducer/compliance-template.reducer';
import {
    addComplianceGroup,
    confirmComplianceTemplates,
    getComplianceTemplate,
} from '../../reducers/compliance-template-reducer/compliance-template.actions';

function ComplianceModal({ isShown, type, close }) {
    const [loading, setLoading] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [newGroup, setNewGroup] = useState({
        group: '',
        type,
        order: 4,
        category: [],
        isAdding: false,
    });

    const dispatch = useDispatch();

    const { complianceTemplate } = useSelector(complianceTemplateSelector);

    const getData = useCallback(async () => {
        await dispatch(getComplianceTemplate(type));
        setLoading(false);
    }, [type, dispatch]);

    const addGroup = async ({ key, type: buttonType }) => {
        if (buttonType === 'click' || key === 'Enter') {
            dispatch(addComplianceGroup(newGroup));
            cancelAddGroup();
        }
    };

    const handleNewGroup = async ({ target: { value } }) => {
        setNewGroup((state) => ({
            ...state,
            group: value,
        }));
    };

    const cancelAddGroup = () => {
        setNewGroup({
            group: '',
            type,
            order: 4,
            category: [],
            isAdding: false,
        });
    };

    const handleConfirm = async () => {
        setConfirmLoading(true);
        try {
            dispatch(confirmComplianceTemplates(type));
        } finally {
            setConfirmLoading(false);
            close();
        }
    };

    const closeModal = async () => {
        await dispatch(confirmComplianceTemplates(type));
        setConfirmLoading(false);
        close();
    };

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <Dialog
            width={700}
            title={
                <Pane display="flex" alignItems="center" gap={10}>
                    <Heading textTransform="capitalize">
                        {`Let's set-up your Compliance Document Structure (${type})`}
                    </Heading>
                    <Tooltip content="By setting up this three-level folder structure, you'll be able to easily navigate and manage your compliance documents within our system.">
                        <InfoSignIcon />
                    </Tooltip>
                </Pane>
            }
            isShown={isShown}
            onCloseComplete={closeModal}
            onConfirm={handleConfirm}
            isConfirmLoading={confirmLoading}
            hasCancel={false}
            hasClose={false}
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
        >
            <Pane display="flex" flexDirection="column" gap={20}>
                {newGroup.isAdding ? (
                    <Pane display="flex" width="100%" alignItems="center">
                        <Tooltip content="Press Enter to Confirm">
                            <TextInputField
                                autoFocus
                                padding={0}
                                margin={0}
                                placeholder="Add New Group"
                                onKeyDown={addGroup}
                                inputWidth={400}
                                marginRight={10}
                                marginLeft={30}
                                marginTop={-6}
                                onChange={handleNewGroup}
                            />
                        </Tooltip>
                        <Pane display="flex" alignItems="center">
                            <Pane
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                hoverElevation={1}
                                borderRadius={9999}
                                padding={4}
                                role="button"
                                onClick={addGroup}
                                height={25}
                                marginY="auto"
                                marginRight={8}
                            >
                                <TickIcon
                                    size={20}
                                    color="#47B881"
                                    cursor="pointer"
                                />
                            </Pane>
                            <Pane
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                hoverElevation={1}
                                borderRadius={9999}
                                padding={4}
                                role="button"
                                onClick={cancelAddGroup}
                                height={25}
                                marginY="auto"
                            >
                                <CrossIcon
                                    size={20}
                                    color="#8f95b2"
                                    cursor="pointer"
                                />
                            </Pane>
                        </Pane>
                    </Pane>
                ) : (
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="start"
                        gap={10}
                    >
                        <Button
                            appearance="minimal"
                            iconBefore={<PlusIcon />}
                            marginLeft={30}
                            onClick={() =>
                                setNewGroup({
                                    group: '',
                                    type,
                                    order: 4,
                                    category: [],
                                    isAdding: true,
                                })
                            }
                        >
                            Add Group
                        </Button>
                        <Tooltip content="Here you can create a grouping for your folders. You can give it a name that best represents the overall category or theme of your compliance documents. For Example: 'Maintenance Compliance' or 'Finance Compliance'">
                            <InfoSignIcon size={14} color="#c8cacf" />
                        </Tooltip>
                    </Pane>
                )}
                {loading ? (
                    <Pane
                        display="flex"
                        flexDirection="column"
                        gap={10}
                        marginY={30}
                        alignItems="center"
                    >
                        <Spinner />
                        <Text>Loading...</Text>
                    </Pane>
                ) : (
                    _.orderBy(complianceTemplate, ['order'], 'asc').map(
                        (group) => <ComplianceTemplateGroup group={group} />
                    )
                )}
            </Pane>
        </Dialog>
    );
}

ComplianceModal.propTypes = {
    isShown: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
};

export default ComplianceModal;
