import React from 'react';
import { Alert, Heading, Pane, Spinner, Text } from 'evergreen-ui';
import { useSelector } from 'react-redux';

import { resetPassword } from '../../reducers/auth-reducer/auth.actions';
import { Image } from '../../components/atoms';
import loginImage from '../../assets/images/illustrations/login.svg';

import AuthScreenLayout from '../../components/layouts/auth-screen-layout';

import ResetPasswordForm from '../../components/forms/auth-forms/reset-password-form';
import { defaultLoginModel } from '../../models';
import { authSelector } from '../../reducers/auth-reducer/auth.reducer';

const ResetPasswordPage = () => {
    const {
        isFetchingAuthToken,
        fetchAuthTokenError,
        maintenance: { isUnderMaintenance, message },
    } = useSelector(authSelector);

    const renderHeroComponent = () => (
        <Image src={loginImage} alt="signup illustration" maxWidth="300px" />
    );

    const submitResetForm = resetPassword;

    const renderLoader = () => (
        <Pane
            height={350}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner marginBottom="1rem" />
            <Text size={500}>Resetting password ...</Text>
        </Pane>
    );

    const renderAlert = () => {
        if (!fetchAuthTokenError) {
            return null;
        }

        return (
            <Alert intent="danger" title="Reset Password Failed">
                {fetchAuthTokenError}
            </Alert>
        );
    };

    const renderResetForm = () => {
        if (isUnderMaintenance) {
            return (
                <>
                    <Heading size={600} marginBottom="0.5rem">
                        Welcome to Asset Records!{' '}
                        <span role="img" aria-label="waving">
                            👋
                        </span>
                    </Heading>
                    <Text size={500}>{message}</Text>
                </>
            );
        }
        return (
            <>
                <Heading size={600} marginBottom="0.5rem">
                    Welcome to Asset Records!{' '}
                    <span role="img" aria-label="waving">
                        👋
                    </span>
                </Heading>
                <>
                    <Text size={500} marginBottom={16}>
                        Fill in email, to reset Password
                    </Text>
                    {renderAlert()}
                    <ResetPasswordForm
                        submitForm={submitResetForm}
                        initialValues={defaultLoginModel({ email: '' })}
                    />
                </>
            </>
        );
    };

    return (
        <AuthScreenLayout heroComponent={renderHeroComponent()}>
            <Pane
                display="flex"
                flexDirection="column"
                maxWidth="400px"
                width="100%"
            >
                {isFetchingAuthToken ? renderLoader() : renderResetForm()}
            </Pane>
        </AuthScreenLayout>
    );
};

export default ResetPasswordPage;
