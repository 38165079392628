import React, { useMemo, useCallback, useState } from 'react';
import {
    Card,
    SearchInput,
    Pane,
    EditIcon,
    TrashIcon,
    MoreIcon,
    Heading,
    Text,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../../theme/theme';
import Options from '../atoms/table-options-popup';
import { deleteUser } from '../../reducers/user-management-reducer/user-management.actions';
import { errorToast, successToast } from '../toasts';
import { useUserAccess } from '../../context/user-access-context';

function UserManagementTable({ users, openEditUserSidebar }) {
    const dispatch = useDispatch();

    const { currentRights } = useUserAccess();

    const [search, setSearch] = useState('');

    const handleSearch = ({ target: { value } }) => setSearch(value);

    const data = useMemo(
        () =>
            users.filter(
                ({ name, email, mobileNo }) =>
                    name.toLowerCase().includes(search.toLowerCase()) ||
                    email.toLowerCase().includes(search.toLowerCase()) ||
                    mobileNo.toLowerCase().includes(search.toLowerCase())
            ),
        [search, users]
    );

    const options = useMemo(
        () => [
            {
                label: 'Edit',
                icon: {
                    Icon: EditIcon,
                    props: { size: 18, color: '#696f8c' },
                },
                hasRight: currentRights?.update,
                onClick: (user) => {
                    openEditUserSidebar(user, 'editUser');
                },
            },
            {
                label: 'Delete',
                icon: {
                    Icon: TrashIcon,
                    props: { size: 18, color: '#d14343' },
                },
                hasRight: currentRights?.delete,
                onClick: async (user) => {
                    try {
                        dispatch(deleteUser(user.id));
                        successToast(
                            `Successfully deleted user: ${user.fullName}`
                        );
                    } catch (error) {
                        errorToast(
                            `An Error Occurred: ${get(
                                error,
                                'response.data.message',
                                `Failed to delete user: ${user.fullName}`
                            )}`
                        );
                    }
                },
            },
        ],
        [dispatch, openEditUserSidebar, currentRights]
    );

    const renderOptions = useCallback(
        ({ row: { original } }) => (
            <Options
                key={`${original.id}-${original.name}}`}
                options={options}
                selectedItem={original}
            />
        ),
        [options]
    );

    const renderRole = useCallback(
        ({ row: { original } }) => (
            <Text textTransform="capitalize">
                {original.userAccess?.role?.name || 'N/A'}
            </Text>
        ),
        []
    );

    const columns = useMemo(
        () => [
            {
                Header: 'NAME & SURNAME',
                accessor: 'fullName',
            },
            {
                Header: 'CELLPHONE NUMBER',
                accessor: 'mobileNo',
            },
            {
                Header: 'EMAIL ADDRESS',
                accessor: 'email',
            },
            {
                Header: 'ROLE',
                accessor: 'role',
                Cell: renderRole,
            },
            {
                Header: <MoreIcon marginX="auto" color="#8f95b2" />,
                accessor: 'options',
                Cell: renderOptions,
            },
        ],
        [renderOptions, renderRole]
    );

    const table = useTable({
        columns,
        data,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        table;

    return (
        <Card
            display="flex"
            flex={1}
            flexDirection="column"
            borderRadius={8}
            background="white"
            height="calc(100vh - 250px)"
            overflow="auto"
        >
            <Pane
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="1.125rem"
                borderBottom={`1px solid ${colors.neutral500}`}
            >
                <Heading
                    fontSize={22}
                    fontWeight="700"
                    color={colors.primary500}
                >
                    Users
                </Heading>
                <Pane display="flex" gap={16} alignItems="center">
                    <SearchInput
                        margin={0}
                        value={search}
                        onChange={handleSearch}
                        placeholder="Search Users"
                    />
                </Pane>
            </Pane>
            <StyledTable>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                            }}
                                        >
                                            <div>{column.render('Header')}</div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {data.length ? (
                            rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <div className="empty-row">No Users</div>
                            </tr>
                        )}
                    </tbody>
                </table>
            </StyledTable>
        </Card>
    );
}

const StyledTable = styled.div`
    table {
        border: '1px solid ${colors.neutral500}';
        padding: 0;
        width: 100%;
        border-collapse: collapse;
        position: relative;
    }
    th {
        background-color: #fafbff;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        width: 168px;
        height: 60px;
        color: #696f8c;
        align-items: center;
        gap: 8px;
        padding: 0 10px;
        border-right: 0.5px solid #d8dae5;
    }
    tr,
    .date {
        width: 168px;
        height: 60px;
        font-size: 14px;
    }
    td {
        border: 0.4579px solid #d8dae5;
        text-align: center;
        color: #696f8c;
        position: relative;
        border-left: none;
        border-right: none;
    }
    div {
        display: flex;
        align-items: center;
    }
    .empty-row {
        display: flex;
        width: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        height: 60px;
        border: 1px solid ${colors.neutral500};
        border-top: none;
    }
    thead {
        border-bottom: 1px solid ${colors.neutral500};
    }
    tbody: {
        height: 200px;
    }
`;

UserManagementTable.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    openEditUserSidebar: PropTypes.func.isRequired,
};

export default UserManagementTable;
