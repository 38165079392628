import _ from 'lodash';
import {
    Button,
    Heading,
    Menu,
    Pane,
    Popover,
    Position,
    Spinner,
    Text,
    Tooltip,
} from 'evergreen-ui';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import UploadManagerDialog from '../../components/molecules/upload-manager-dialog';
import DocumentUploadRequestSidebar from '../../components/organisms/document-upload-request-sidebar/document-upload-request-sidebar';
import CreateContactSidebar from '../../components/organisms/create-contact-sidebar/create-contact-sidebar';
import { colors } from '../../theme/theme';
import { Image } from '../../components/atoms';
import documentUpload from '../../assets/images/illustrations/document-upload.svg';
import { SelectedComplianceItem } from './selected-complianceI-item-context';
import useWindowDimensions from '../../hooks/use-window-dimensions';
import DocumentCard from './document-card';
import DocumentDialog from '../../components/organisms/document-dialog/document-dialog';
import useQueryParams from '../../hooks/use-query-params';
import { useUserAccess } from '../../context/user-access-context';

const DocumentsList = () => {
    const { groupId, categoryId, typeId } = useQueryParams();
    const {
        selectedComplianceItem,
        isLoadingSelectedComplianceItemDocuments,
        selectedComplianceItemDocuments,
        selectedBatchId,
        setSelectedBatchId,
    } = useContext(SelectedComplianceItem);

    const { width, height } = useWindowDimensions();
    const { currentRights: rights } = useUserAccess();
    const [isSelectedDocumentDialogShown, setIsSelectedDocumentDialogShown] =
        useState(false);
    const [isUploadDialogShown, setIsUploadDialogShown] = useState(false);
    const [isDocumentRequestSidebarShown, setIsDocumentRequestSidebarShown] =
        useState(false);
    const [isCreateContactSidebarShown, setIsCreateContactSidebarShown] =
        useState(false);
    const [
        shouldShowDocumentRequestSidebar,
        setShouldShowDocumentRequestSidebar,
    ] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams({ modalName: '' });

    useEffect(() => {
        const modalName = searchParams.get('modalName');
        if (modalName === 'uploadModal') {
            setIsUploadDialogShown(true);
        } else if (modalName === 'requestDocument') {
            setIsDocumentRequestSidebarShown(true);
        }
    }, [searchParams]);

    useEffect(
        () => setSelectedBatchId(null),
        [groupId, categoryId, typeId, setSelectedBatchId]
    );

    const handleUrlParamSet = useCallback(
        (id) => {
            const nextSearchParams = new URLSearchParams(searchParams);
            const value = id;
            nextSearchParams.set('modalName', value);
            setSearchParams(nextSearchParams);
        },
        [setSearchParams, searchParams]
    );

    const handleUrlParamDelete = useCallback(() => {
        const nextSearchParams = new URLSearchParams(searchParams);
        nextSearchParams.delete('modalName');
        setSearchParams(nextSearchParams);
    }, [setSearchParams, searchParams]);

    const filteredDocuments = useMemo(() => {
        if (!selectedBatchId) {
            return selectedComplianceItemDocuments;
        }

        return _.filter(
            selectedComplianceItemDocuments,
            (document) => document.batchId === selectedBatchId
        );
    }, [selectedComplianceItemDocuments, selectedBatchId]);

    const showSelectedDocumentDialog = () => {
        setIsSelectedDocumentDialogShown(true);
    };

    const showUploadDialog = (id) => {
        handleUrlParamSet(id);
        setIsUploadDialogShown(true);
    };

    const handleCloseUploadDialg = () => {
        handleUrlParamDelete();
        setIsUploadDialogShown(false);
    };

    const showDocumentRequestSidebar = (id) => {
        handleUrlParamSet(id);
        setIsDocumentRequestSidebarShown(true);
    };

    const handleCloseDocumentRequest = () => {
        handleUrlParamDelete();
        setIsDocumentRequestSidebarShown(false);
    };

    const showCreateContactSidebar = (id) => {
        handleUrlParamSet(id);
        setIsCreateContactSidebarShown(true);
        setShouldShowDocumentRequestSidebar(true);
    };

    const onCreateContactSidebarClose = () => {
        if (shouldShowDocumentRequestSidebar) {
            handleUrlParamDelete();
            setShouldShowDocumentRequestSidebar(false);
            showDocumentRequestSidebar('requestDocument');
        }
    };

    const renderContentMenu = ({ close }) => (
        <ContentMenu
            close={close}
            showDocumentRequestSidebar={showDocumentRequestSidebar}
            showUploadDialog={showUploadDialog}
        />
    );

    const renderAddNewButton = () => {
        if (!rights?.create) {
            return null;
        }
        if (!selectedComplianceItem || !selectedComplianceItem.uploadRequest) {
            return (
                <Popover
                    position={Position.BOTTOM_RIGHT}
                    content={renderContentMenu}
                >
                    <Button
                        appearance="primary"
                        onClick={() => showUploadDialog('uploadModal')}
                    >
                        + Add New
                    </Button>
                </Popover>
            );
        }

        return (
            <Tooltip
                content="Can't add a new document. There is already a pending upload request."
                position={Position.BOTTOM_RIGHT}
            >
                <AddButton
                    appearance="primary"
                    isDisabled={selectedComplianceItem?.uploadRequest}
                >
                    + Add New
                </AddButton>
            </Tooltip>
        );
    };

    const renderHeader = () => (
        <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            paddingTop={14}
            paddingBottom={14}
            paddingX={24}
            position="sticky"
            top={0}
        >
            <Pane>
                <Heading size={800} fontSize={32} color="#0C2138">
                    {_.get(selectedComplianceItem, 'name')}
                </Heading>
            </Pane>
            {!_.isEmpty(selectedComplianceItemDocuments) &&
                renderAddNewButton()}
        </Pane>
    );

    const renderRequestDocument = () => {
        if (!(rights?.create || rights?.update)) {
            return null;
        }

        if (!selectedComplianceItem) {
            return (
                <Text fontSize={16} fontWeight={600} lineHeight={6}>
                    Select a Compliance Type to get Started
                </Text>
            );
        }

        if (!selectedComplianceItem.uploadRequest) {
            return (
                <Button
                    appearance="primary"
                    width={300}
                    height={40}
                    marginTop={30}
                    onClick={() =>
                        showDocumentRequestSidebar('requestDocument')
                    }
                >
                    Request Document
                </Button>
            );
        }

        return (
            <Tooltip content="Can't request another document. There is already a pending upload request.">
                <AddButton
                    appearance="primary"
                    width={300}
                    height={40}
                    marginTop={30}
                    isDisabled
                >
                    Request Document
                </AddButton>
            </Tooltip>
        );
    };

    const renderUploadDocument = () => {
        if (!selectedComplianceItem || !rights?.create) {
            return null;
        }

        if (!selectedComplianceItem.uploadRequest) {
            return (
                <Button
                    width={300}
                    height={40}
                    marginTop={8}
                    onClick={() => showUploadDialog('uploadModal')}
                >
                    Upload Document
                </Button>
            );
        }

        return (
            <Tooltip content="Can't upload document. There is already a pending upload request.">
                <UploadButton width={300} height={40} marginTop={8} isDisabled>
                    Upload Document
                </UploadButton>
            </Tooltip>
        );
    };

    const renderDocumentList = () => {
        if (isLoadingSelectedComplianceItemDocuments) {
            return (
                <Pane
                    display="flex"
                    flex={1}
                    width={width - 80 - 320 - 313}
                    height={height - 64 - 127}
                    flexDirection="column"
                    backgroundColor={colors.white}
                    border="0.5px solid #C1C4D6"
                    borderRadius={4}
                    overflow="hidden"
                    overflowY="auto"
                >
                    <Pane
                        height="100%"
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Spinner />
                        <Text marginTop="1rem">Loading...</Text>
                    </Pane>
                </Pane>
            );
        }

        if (selectedComplianceItem && !selectedComplianceItem.applicable) {
            return (
                <Pane
                    display="flex"
                    flex={1}
                    width="100%"
                    flexDirection="column"
                    backgroundColor={colors.white}
                    border="0.5px solid #C1C4D6"
                    borderRadius={4}
                    overflow="hidden"
                >
                    {renderHeader()}
                    <Pane
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Text>Compliance item is not applicable.</Text>
                    </Pane>
                </Pane>
            );
        }

        if (_.isEmpty(selectedComplianceItemDocuments)) {
            return (
                <Pane
                    display="flex"
                    flex={1}
                    width="100%"
                    flexDirection="column"
                    backgroundColor={colors.white}
                    border="0.5px solid #C1C4D6"
                    borderRadius={4}
                    overflow="hidden"
                >
                    {renderHeader()}
                    <Pane
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image
                            src={documentUpload}
                            alt="document upload illustration"
                            maxWidth="200px"
                        />
                        {renderRequestDocument()}
                        {renderUploadDocument()}
                    </Pane>
                </Pane>
            );
        }

        return (
            <Pane
                flex={1}
                backgroundColor={colors.white}
                border="0.5px solid #C1C4D6"
                borderRadius={4}
                height="auto"
                overflow="auto"
            >
                <Pane
                    display="flex"
                    flex={1}
                    width="100%"
                    flexDirection="column"
                    backgroundColor={colors.white}
                >
                    {renderHeader()}
                </Pane>
                <Pane
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-evenly"
                    flex={1}
                    overflowY="auto"
                >
                    {_.map(filteredDocuments, (document) => (
                        <DocumentCard
                            key={`document-${document.id}`}
                            document={document}
                            showSelectedDocumentDialog={
                                showSelectedDocumentDialog
                            }
                        />
                    ))}
                </Pane>
            </Pane>
        );
    };

    return (
        <>
            {renderDocumentList()}
            {isUploadDialogShown && (
                <UploadManagerDialog
                    isShown={isUploadDialogShown}
                    setIsShown={handleCloseUploadDialg}
                />
            )}
            {isDocumentRequestSidebarShown && (
                <DocumentUploadRequestSidebar
                    isShown={isDocumentRequestSidebarShown}
                    setIsShown={handleCloseDocumentRequest}
                    showCreateContactsSidebar={showCreateContactSidebar}
                />
            )}
            {isCreateContactSidebarShown && (
                <CreateContactSidebar
                    isShown={isCreateContactSidebarShown}
                    setIsShown={setIsCreateContactSidebarShown}
                    onClose={onCreateContactSidebarClose}
                />
            )}
            <DocumentDialog
                isShown={isSelectedDocumentDialogShown}
                setIsShown={setIsSelectedDocumentDialogShown}
                rights={rights}
            />
        </>
    );
};

const ContentMenu = ({
    close,
    showDocumentRequestSidebar,
    showUploadDialog,
}) => (
    <Menu>
        <Menu.Group>
            <Menu.Item
                onSelect={() => {
                    showDocumentRequestSidebar('requestDocument');
                    close();
                }}
            >
                Request Document
            </Menu.Item>
            <Menu.Item
                onSelect={() => {
                    showUploadDialog('uploadModal');
                    close();
                }}
            >
                Upload Document
            </Menu.Item>
        </Menu.Group>
    </Menu>
);
ContentMenu.propTypes = {
    close: PropTypes.func.isRequired,
    showDocumentRequestSidebar: PropTypes.func.isRequired,
    showUploadDialog: PropTypes.func.isRequired,
};

DocumentsList.defaultProps = {
    isTenant: false,
};

export default DocumentsList;

const AddButton = styled(Button)`
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    background-color: ${(props) => (props.isDisabled ? '#b7ecce' : '#46b68d')};
    border-color: ${(props) => (props.isDisabled ? '#b7ecce' : '#5EC090')};

    &:not([disabled]):hover {
        cursor: not-allowed;
        background-color: ${(props) =>
            props.isDisabled ? '#b7ecce' : '#5EC090'};
        border-color: ${(props) => (props.isDisabled ? '#b7ecce' : '#5EC090')};
    }

    &:not([disabled]):focus {
        cursor: not-allowed;
        background-color: ${(props) =>
            props.isDisabled ? '#b7ecce' : '#5EC090'};
        border-color: ${(props) => (props.isDisabled ? '#b7ecce' : '#5EC090')};
    }

    &:not([disabled]):active {
        cursor: not-allowed;
        background-color: ${(props) =>
            props.isDisabled ? '#b7ecce' : '#5EC090'};
        border-color: ${(props) => (props.isDisabled ? '#b7ecce' : '#5EC090')};
    }

    &:hover {
        cursor: not-allowed;
        background-color: ${(props) =>
            props.isDisabled ? '#b7ecce' : '#5EC090'};
        border-color: ${(props) => (props.isDisabled ? '#b7ecce' : '#5EC090')};
    }
`;

const UploadButton = styled(Button)`
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    color: ${(props) => (props.isDisabled ? '#c1c4d6' : '#474d66')};
    border-color: ${(props) => (props.isDisabled ? '#E6E8F0' : '#c1c4d6')};
    background-color: white;

    &:not([disabled]):hover {
        cursor: not-allowed;
        color: ${(props) => (props.isDisabled ? '#c1c4d6' : '#474d66')};
        border-color: ${(props) => (props.isDisabled ? '#E6E8F0' : '#c1c4d6')};
        background-color: white;
    }

    &:not([disabled]):focus {
        cursor: not-allowed;
        color: ${(props) => (props.isDisabled ? '#c1c4d6' : '#474d66')};
        border-color: ${(props) => (props.isDisabled ? '#E6E8F0' : '#c1c4d6')};
        background-color: white;
    }

    &:not([disabled]):active {
        cursor: not-allowed;
        color: ${(props) => (props.isDisabled ? '#c1c4d6' : '#474d66')};
        border-color: ${(props) => (props.isDisabled ? '#E6E8F0' : '#c1c4d6')};
        background-color: white;
    }

    &:hover {
        cursor: not-allowed;
        color: ${(props) => (props.isDisabled ? '#c1c4d6' : '#474d66')};
        border-color: ${(props) => (props.isDisabled ? '#E6E8F0' : '#c1c4d6')};
        background-color: white;
    }
`;
