import React from 'react';
import {
    Pane,
    Tooltip,
    TextInputField,
    CrossIcon,
    TickIcon,
} from 'evergreen-ui';

import PropTypes from 'prop-types';

function AddForm({ add, placeholder, handleChange, cancelAdd }) {
    return (
        <Pane
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
        >
            <Tooltip content="Press Enter to Confirm">
                <TextInputField
                    autoFocus
                    padding={0}
                    margin={0}
                    placeholder={placeholder}
                    onKeyDown={add}
                    inputWidth={270}
                    width={270}
                    marginRight={10}
                    marginTop={-6}
                    onChange={handleChange}
                />
            </Tooltip>
            <Pane display="flex" alignItems="center">
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    hoverElevation={1}
                    borderRadius={9999}
                    padding={4}
                    role="button"
                    onClick={add}
                    height={25}
                    marginY="auto"
                    marginRight={8}
                >
                    <TickIcon size={20} color="#47B881" cursor="pointer" />
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    hoverElevation={1}
                    borderRadius={9999}
                    padding={4}
                    role="button"
                    onClick={cancelAdd}
                    height={25}
                    marginY="auto"
                >
                    <CrossIcon size={20} color="#8f95b2" cursor="pointer" />
                </Pane>
            </Pane>
        </Pane>
    );
}

AddForm.propTypes = {
    add: PropTypes.func.isRequired,
    placeholder: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    cancelAdd: PropTypes.func.isRequired,
};

export default AddForm;
