import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Pane, TextInputField } from 'evergreen-ui';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { successToast } from '../../toasts';
import { loginModel, loginShape } from '../../../models';

// Validation Schema
const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const ResetPasswordForm = ({ initialValues, submitForm }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFormSubmission = async (
        values,
        { setSubmitting, setFieldError }
    ) => {
        setSubmitting(true);

        try {
            await dispatch(submitForm(loginModel(values)));
            successToast('Reset link email has been sent');
            navigate('/login');
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message || error.message;
            setFieldError('email', errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmission}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Pane marginTop="16px">
                        <TextInputField
                            label="Email"
                            type="email"
                            name="email"
                            placeholder="john@example.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && errors.email}
                            validationMessage={touched.email && errors.email}
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    setFieldTouched('email', true, true);
                                    if (!errors.email && values.email) {
                                        handleSubmit();
                                    }
                                }
                            }}
                        />
                    </Pane>
                    <Button
                        appearance="primary"
                        marginTop={8}
                        type="submit"
                        isLoading={isSubmitting}
                        height={40}
                        width="100%"
                        disabled={isSubmitting}
                    >
                        Reset Password
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

ResetPasswordForm.propTypes = {
    initialValues: loginShape.isRequired,
    submitForm: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
