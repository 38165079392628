import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Pane, Spinner, Text } from 'evergreen-ui';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../reducers/asset-reducer/asset.actions';
import AssetOverview from '../../components/organisms/asset-overview/asset-overview';
import { SelectedComplianceItemProvider } from './selected-complianceI-item-context';
import AssetDetailsSidebar from '../../components/organisms/assets-list/compliance-details-sidebar';
import { getAllContactsForBusiness } from '../../reducers/contacts-reducer/contacts.actions';
import EditAssetSidebar from '../../components/organisms/edit-asset-sidebar/edit-asset-sidebar';
import AssetsHeader from './asset-header-page';
import AssetDelete from '../../components/organisms/asset-archiving/AssetDeletion';
import AssetArchiving from '../../components/organisms/asset-archiving/AssetArchiving';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import { maintenanceSelector } from '../../reducers/maintenance-reducer/maintenance-reducer';
import { taskSelector } from '../../reducers/task-reducer/task.reducer';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import { useUserAccess } from '../../context/user-access-context';
import { getTasks } from '../../reducers/task-reducer/task.actions';
import { getAssetRegisterData } from '../../reducers/maintenance-reducer/maintenance-actions';
import ExportToExcel from '../../components/organisms/asset-exporting/export-json-xlsx';
import { parseComplianceData } from '../../components/organisms/asset-exporting/export-json-utils';

const AssetPage = () => {
    const { assetId, buildingId, tenantId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams({ modalName: '' });
    const { currentRights } = useUserAccess();

    const { create, update } = useMemo(
        () =>
            currentRights || {
                create: false,
                update: false,
            },
        [currentRights]
    );

    const dispatch = useDispatch();

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const { assetRegister } = useSelector(maintenanceSelector);
    const { tasks } = useSelector(taskSelector);

    const [isEditAssetSidebarShown, setIsEditAssetSidebarShown] =
        useState(false);
    const [loading, setLoading] = useState(true);
    const [showMigrationSideBar, setShowMigrationSideBar] = useState(false);
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
    const [openDeleteAsset, setOpenDeleteAsset] = useState(false);
    const [openExport, setOpenExport] = useState(false);

    useEffect(() => {
        const modalName = searchParams.get('modalName');
        setOpenArchiveDialog(modalName === 'archive');
        setOpenDeleteAsset(modalName === 'delete');
        setShowMigrationSideBar(modalName === 'migration');
    }, [searchParams]);

    const getData = useCallback(async () => {
        if (
            tenantId &&
            tenantId !== 'undefined' &&
            !currentTenant &&
            currentTenant !== null
        ) {
            await dispatch(getTenant(tenantId));
        }
        if (
            buildingId &&
            buildingId !== 'undefined' &&
            !currentBuilding &&
            currentBuilding !== null
        ) {
            await dispatch(getBuilding(buildingId));
        }
        if (
            assetId &&
            assetId !== 'undefined' &&
            !currentAsset &&
            currentAsset !== null
        ) {
            await dispatch(getAsset(assetId, true, true, true));
        }
        await Promise.all([
            dispatch(getAllContactsForBusiness()),
            dispatch(getNotifications()),
            dispatch(getAssetRegisterData(false)),
            dispatch(getTasks()),
        ]);
        setLoading(false);
    }, [
        tenantId,
        currentTenant,
        buildingId,
        currentBuilding,
        assetId,
        currentAsset,
        dispatch,
    ]);

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset,
        [currentTenant, currentBuilding, currentAsset]
    );

    const filteredMaintenanceData = useMemo(() => {
        const buildingIdTest = asset?.id;

        if (buildingIdTest) {
            return assetRegister.filter(({ buildings_id: buildingIds }) => {
                if (Array.isArray(buildingIds)) {
                    return buildingIds.includes(String(buildingIdTest));
                }
                return String(buildingIds) === String(buildingIdTest);
            });
        }

        return assetRegister;
    }, [assetRegister, asset?.id]);

    const filteredTaskData = useMemo(() => {
        const buildingIdTest = asset?.id;

        if (buildingIdTest) {
            return tasks.filter(({ buildings_id: buildingIds }) => {
                if (Array.isArray(buildingIds)) {
                    return buildingIds.includes(String(buildingIdTest));
                }
                return String(buildingIds) === String(buildingIdTest);
            });
        }

        return tasks;
    }, [tasks, asset?.id]);

    const filteredComplianceData = useMemo(() => {
        const parseData = parseComplianceData(asset?.complianceGroups);

        return parseData;
    }, [asset?.complianceGroups]);

    useEffect(() => {
        getData();
    }, [getData]);

    const handleUrlParamSet = useCallback(
        (id) => {
            const nextSearchParams = new URLSearchParams(searchParams);
            const value = id;
            nextSearchParams.set('modalName', value);
            setSearchParams(nextSearchParams);
        },
        [setSearchParams, searchParams]
    );

    const handleUrlParamDelete = useCallback(() => {
        const nextSearchParams = new URLSearchParams(searchParams);
        nextSearchParams.delete('modalName');
        setSearchParams(nextSearchParams);
    }, [setSearchParams, searchParams]);

    const closeEditAssetSidebar = () => {
        setIsEditAssetSidebarShown(false);
    };

    const showEditAssetSidebar = () => {
        setIsEditAssetSidebarShown(true);
    };

    const handleOpenSideBar = useCallback(
        (id) => {
            handleUrlParamSet(id);
            setShowMigrationSideBar(true);
        },
        [handleUrlParamSet]
    );

    const handleCloseSideBar = () => {
        handleUrlParamDelete();
        setShowMigrationSideBar(false);
    };

    const handleOpenArchive = useCallback(
        (id) => {
            handleUrlParamSet(id);
            setOpenArchiveDialog(true);
        },
        [handleUrlParamSet]
    );

    const handleCloseArchive = () => {
        handleUrlParamDelete();
        setOpenArchiveDialog(false);
    };

    const handleOpenDelete = useCallback(
        (id) => {
            handleUrlParamSet(id);
            setOpenDeleteAsset(true);
        },
        [handleUrlParamSet]
    );

    const handleCloseDelete = () => {
        handleUrlParamDelete();
        setOpenDeleteAsset(false);
    };

    const handleOpenExport = useCallback(
        (id) => {
            handleUrlParamSet(id);
            setOpenExport(true);
        },
        [handleUrlParamSet]
    );

    const handleCloseExport = () => {
        handleUrlParamDelete();
        setOpenExport(false);
    };

    if (loading) {
        return (
            <Pane
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Spinner />
                <Text marginTop="1rem">Loading...</Text>
            </Pane>
        );
    }

    return (
        <Pane display="flex" width="100%">
            <Pane
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                height="100%"
                width="100%"
            >
                <Pane
                    display="flex"
                    flex={1}
                    width="75%"
                    height="100%"
                    justifyContent="space-between"
                    position="relative"
                >
                    <Pane display="flex" flexDirection="column" width="100%">
                        <AssetsHeader
                            asset={asset}
                            handleOpenSideBar={handleOpenSideBar}
                            handleOpenArchive={handleOpenArchive}
                            handleOpenDelete={handleOpenDelete}
                            handeOpenExport={handleOpenExport}
                        />
                        <AssetOverview />
                    </Pane>
                </Pane>
                <Pane display="flex" width="25%" height="100%">
                    <AssetDetailsSidebar
                        showMigrationSideBar={showMigrationSideBar}
                        showEditAssetSidebar={showEditAssetSidebar}
                        create={create}
                        update={update}
                        handleCloseSideBar={handleCloseSideBar}
                    />
                </Pane>
            </Pane>
            {(create || update) && (
                <EditAssetSidebar
                    isShown={isEditAssetSidebarShown}
                    asset={{ ...asset }}
                    close={closeEditAssetSidebar}
                />
            )}
            <AssetArchiving
                asset={asset}
                onOpen={openArchiveDialog}
                handleCloseArchive={handleCloseArchive}
            />
            <AssetDelete
                asset={asset}
                onOpen={openDeleteAsset}
                handCloseDelete={handleCloseDelete}
            />
            <ExportToExcel
                data={{
                    compliances: filteredComplianceData,
                    maintenance_items: filteredMaintenanceData,
                    tasks_items: filteredTaskData,
                }}
                fileName={asset?.assetName || ''}
                isDialogShown={openExport}
                setIsDialogShown={handleCloseExport}
            />
        </Pane>
    );
};

export default (props) => (
    <SelectedComplianceItemProvider>
        <AssetPage {...props} />
    </SelectedComplianceItemProvider>
);
