import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Button,
    Heading,
    Pane,
    Paragraph,
    SideSheet,
    PeopleIcon,
    Avatar,
    Card,
    TextareaField,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { contactsSelector } from '../../../reducers/contacts-reducer/contacts.reducer';
import ContactItem from './contact-item';
import { Collapsible } from '../../atoms';
import { uploadRequestService } from '../../../services';
import useQueryParams from '../../../hooks/use-query-params';
import { successToast } from '../../toasts';
import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../../reducers/asset-reducer/asset.actions';
import { getAllContactsForBusiness } from '../../../reducers/contacts-reducer/contacts.actions';
import { useUserAccess } from '../../../context/user-access-context';

const DocumentUploadRequestSidebar = ({
    isShown,
    setIsShown,
    showCreateContactsSidebar,
    requestType,
    maintenanceItemId,
    propertyId,
}) => {
    const { contacts } = useSelector(contactsSelector);
    const { allRights } = useUserAccess();

    const contactRights = useMemo(
        () =>
            allRights?.contacts || {
                create: false,
                update: false,
                delete: false,
            },
        [allRights]
    );

    const dispatch = useDispatch();
    const { assetId: parkId, buildingId, tenantId } = useParams();
    const { typeId } = useQueryParams();
    const [isContactStep, setIsContactStep] = useState(true);
    const [selectedContact, setSelectedContact] = useState(null);
    const [uploadNote, setUploadNote] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        dispatch(getAllContactsForBusiness());
    }, [isShown, dispatch]);

    const closeSidebar = useCallback(() => {
        setIsShown();
    }, [setIsShown]);

    const showAddContactSidebar = useCallback(
        (id) => {
            closeSidebar();
            showCreateContactsSidebar(id);
        },
        [closeSidebar, showCreateContactsSidebar]
    );

    const onContactSelect = (contact) => {
        setSelectedContact(contact);
        setIsContactStep(false);
    };

    const onBackPress = () => {
        setIsContactStep(true);
    };

    const onChangeNote = (value) => {
        setUploadNote(value.nativeEvent.target.value);
    };

    const sendUploadRequest = async () => {
        setIsLoading(true);
        const assetId =
            requestType === 'maintenance'
                ? propertyId
                : tenantId || buildingId || parkId;
        try {
            await uploadRequestService.sendUploadRequest({
                complianceTypeId: typeId,
                note: uploadNote,
                contactId: selectedContact.id,
                assetId,
                spaceId: null,
                isTenant: false,
                requestType,
                maintenanceItemId,
            });
            if (tenantId) {
                dispatch(getTenant(tenantId));
            }
            if (buildingId) {
                dispatch(getBuilding(buildingId));
            }
            if (parkId) {
                dispatch(getAsset(parkId, false, true));
            }
            successToast(
                `Successfully sent upload request to ${selectedContact.firstName} ${selectedContact.lastName}`
            );
            closeSidebar();
        } catch (error) {
            setRequestError(
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setIsLoading(false);
        }
    };

    const renderEmptyContacts = () => {
        if (!_.isEmpty(contacts)) {
            return null;
        }

        return (
            <Pane
                height="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                paddingBottom="25%"
            >
                <Pane
                    backgroundColor="#EDEFF5"
                    width={72}
                    height={72}
                    borderRadius={36}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <PeopleIcon color="#C1C4D6" size={32} />
                </Pane>
                <Heading color="#696f8c" marginTop={16} marginBottom={0}>
                    No Contacts Loaded
                </Heading>
                <Paragraph
                    color="#696f8c"
                    fontWeight="400"
                    fontSize={14}
                    marginTop={10}
                    marginBottom={0}
                >
                    You don&apos;t have any contacts to send an upload request
                    to.
                </Paragraph>
                {contactRights?.create && (
                    <Button
                        appearance="primary"
                        marginTop={26}
                        onClick={() => showAddContactSidebar('addContact')}
                    >
                        + Add Contact
                    </Button>
                )}
            </Pane>
        );
    };

    const renderContacts = () => {
        if (_.isEmpty(contacts)) {
            return null;
        }

        return (
            <Pane display="flex" flexDirection="column">
                <Collapsible isOpen={isContactStep}>
                    <Pane
                        display="flex"
                        justifyContent="space-between"
                        padding={16}
                        alignItems="center"
                    >
                        <Heading>Select a Contact</Heading>
                        {contactRights?.create && (
                            <Button
                                width={100}
                                appearance="primary"
                                onClick={() =>
                                    showAddContactSidebar('addContact')
                                }
                            >
                                + Add Contact
                            </Button>
                        )}
                    </Pane>
                    <Pane>
                        {_.map(contacts, (contact) => (
                            <ContactItem
                                key={contact.id}
                                contact={contact}
                                onContactSelect={onContactSelect}
                            />
                        ))}
                    </Pane>
                </Collapsible>
                <Collapsible isOpen={!isContactStep}>
                    {selectedContact && (
                        <Card
                            display="flex"
                            alignItems="center"
                            padding={16}
                            backgroundColor="#ffffff"
                            marginY={12}
                            borderRadius={0}
                            elevation={1}
                        >
                            <Avatar
                                name={`${selectedContact.firstName} ${selectedContact.lastName}`}
                                size={50}
                            />
                            <Pane marginLeft={15}>
                                <Heading
                                    color="#282828"
                                    fontSize={13}
                                    fontWeight={600}
                                >
                                    {selectedContact.firstName}{' '}
                                    {selectedContact.lastName}
                                </Heading>
                                <Paragraph
                                    fontSize={12}
                                    color="#696F8C"
                                    lineHeight={1.5}
                                >
                                    {selectedContact.email}
                                </Paragraph>
                                <Paragraph
                                    fontSize={12}
                                    color="#696F8C"
                                    lineHeight={1.5}
                                >
                                    {selectedContact.mobileNumber}
                                </Paragraph>
                            </Pane>
                        </Card>
                    )}
                    <TextareaWrapper padding={16}>
                        <TextareaField
                            label="Request Notes"
                            placeholder="Add any extra comments that will be sent with the request"
                            resize="vertical"
                            onChange={onChangeNote}
                            isInvalid={!!requestError}
                            validationMessage={requestError}
                        />
                        <Button
                            appearance="primary"
                            height={40}
                            type="submit"
                            width="100%"
                            onClick={sendUploadRequest}
                            isLoading={isLoading}
                        >
                            Send Request
                        </Button>
                        <Button
                            appearance="minimal"
                            height={40}
                            type="submit"
                            width="100%"
                            marginTop={4}
                            onClick={onBackPress}
                        >
                            Back
                        </Button>
                    </TextareaWrapper>
                </Collapsible>
            </Pane>
        );
    };

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={closeSidebar}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
            shouldCloseOnEscapePress={false}
            width={400}
        >
            <Pane
                zIndex={1}
                flexShrink={0}
                elevation={0}
                backgroundColor="white"
            >
                <Pane padding={16}>
                    <Heading size={600}>Document Upload Request</Heading>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="auto" background="tint1">
                {renderEmptyContacts()}
                {renderContacts()}
            </Pane>
        </SideSheet>
    );
};

DocumentUploadRequestSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,
    requestType: PropTypes.string,
    setIsShown: PropTypes.func.isRequired,
    showCreateContactsSidebar: PropTypes.func.isRequired,
    maintenanceItemId: PropTypes.string,
    propertyId: PropTypes.string,
};

DocumentUploadRequestSidebar.defaultProps = {
    requestType: 'compliance',
    maintenanceItemId: '',
    propertyId: '',
};

export default DocumentUploadRequestSidebar;

const TextareaWrapper = styled.div`
    padding: 16px;

    & > div > textarea {
        resize: vertical;
    }
`;
