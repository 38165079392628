import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Heading, Pane, PlusIcon, Text } from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams as useSearchModalParams } from 'react-router-dom';
import { colors } from '../../theme/theme';
import { getAssetRegisterData } from '../../reducers/maintenance-reducer/maintenance-actions';
import Breadcrumbs from '../../components/molecules/bread-crumbs';
import PageLoading from '../../components/molecules/page-loading';
import PieChart from '../../components/maintenance/MaintenancePieChart';
import ServicedAssetsIcon from '../../assets/images/icons/maintenance-icons/assets-serviced.svg';
import ServiceDueAssetsIcon from '../../assets/images/icons/maintenance-icons/assets-service-due.svg';
import AssetWarrantyIcon from '../../assets/images/icons/maintenance-icons/assets-under-warranty.svg';
import AssetsOverdue from '../../assets/images/icons/maintenance-icons/overdue-assets.svg';
import BarGraph from '../../components/maintenance/MaintenanceBarGraph';
import MaintenanceAssetRegisterTable from '../../components/maintenance/maintenance-asset-register-table';
import {
    maintenanceSelector,
    setDateRangeAction,
    setPageAction,
    setViewAssetSidebar,
    setLoadingAction,
} from '../../reducers/maintenance-reducer/maintenance-reducer';
import OverviewCard from '../../components/molecules/overview-card';
import AddAssetSidebar from '../../components/organisms/asset-register/asset-register-sidebar';
import AssetInfoSideBar from '../../components/molecules/asset-info';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import DatePicker from '../../components/organisms/date-picker/date-picker';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import { useSearchParams } from '../../context/search-params-context';
import { useUserAccess } from '../../context/user-access-context';

const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const MaintenanceOverviewPage = () => {
    const dispatch = useDispatch();

    const {
        isLoadingParams,
        currentAssetId,
        currentBuildingId,
        currentTenantId,
    } = useSearchParams();

    const { currentRights } = useUserAccess();

    const { create, update } = useMemo(
        () =>
            currentRights || {
                create: false,
                update: false,
            },
        [currentRights]
    );

    const {
        maintenanceCategoriesGraphData,
        assetRegister,
        scheduledMaintenanceData,
        assetRegisterPagination,
        overviewData,
        dateRange,
        viewAssetSidebar,
        page,
        loading,
    } = useSelector(maintenanceSelector);

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const [searchParams, setSearchParams] = useSearchModalParams({
        modalName: '',
    });

    const [assetSidebar, setAssetSidebar] = useState({
        isShown: false,
        isEditing: false,
        itemToEdit: null,
    });

    const getData = useCallback(
        async (shouldSetLoading = false) => {
            if (isLoadingParams) {
                return;
            }
            try {
                await Promise.all([
                    await dispatch(getAssetRegisterData(shouldSetLoading)),
                    await dispatch(getNotifications()),
                ]);
            } finally {
                dispatch(setLoadingAction(false));
            }
        },
        [dispatch, isLoadingParams]
    );

    useEffect(() => {
        const modalName = searchParams.get('modalName');
        if (modalName === 'addAsset') {
            setAssetSidebar({
                isEditing: false,
                isShown: true,
                itemToEdit: null,
            });
        }
    }, [searchParams]);

    const closeAssetSidebar = () => {
        const nextSearchParams = new URLSearchParams(searchParams);
        nextSearchParams.delete('modalName');
        setSearchParams(nextSearchParams);
        setAssetSidebar({ isEditing: false, isShown: false, itemToEdit: null });
    };

    const openAddAssetSidebar = (id) => {
        const nextSearchParams = new URLSearchParams(searchParams);
        const value = id;
        nextSearchParams.set('modalName', value);
        setSearchParams(nextSearchParams);
        setAssetSidebar({ isEditing: false, isShown: true, itemToEdit: null });
    };

    const openEditAssetSidebar = (item) => {
        setAssetSidebar({ isEditing: true, isShown: true, itemToEdit: item });
    };

    const viewAsset = (asset) => {
        dispatch(setViewAssetSidebar({ asset, isShown: true }));
    };

    const closeViewAssetSidebar = () => {
        dispatch(setViewAssetSidebar({ asset: null, isShown: false }));
    };

    const pieChartData = useMemo(() => {
        if (!maintenanceCategoriesGraphData) {
            return { datasets: [] };
        }
        return {
            labels: Object.keys(maintenanceCategoriesGraphData) || [],
            datasets: [
                {
                    label: 'Assets under management',
                    data: Object.values(maintenanceCategoriesGraphData),
                    backgroundColor: [
                        '#70b0ff',
                        '#DE2C2C',
                        '#FF9162',
                        '#7c69db',
                        '#FFD079',
                        '#52BD94',
                        '#9DB5FF',
                    ],
                    borderColor: [
                        '#70b0ff',
                        '#DE2C2C',
                        '#FF9162',
                        '#7c69db',
                        '#FFD079',
                        '#52BD94',
                        '#9DB5FF',
                    ],
                    borderWidth: 1,
                },
            ],
        };
    }, [maintenanceCategoriesGraphData]);

    const barGraphData = useMemo(() => {
        if (!scheduledMaintenanceData) {
            return { datasets: [] };
        }
        const reorderedMonths = MONTHS.slice(
            new Date().getMonth(),
            MONTHS.length
        ).concat(MONTHS.slice(0, new Date().getMonth()));

        return {
            labels: reorderedMonths,
            datasets: [
                {
                    label: 'Scheduled Maintenance',
                    data: reorderedMonths.map((item) => {
                        if (!scheduledMaintenanceData[item]) {
                            return 0;
                        }
                        return scheduledMaintenanceData[item];
                    }),
                    backgroundColor: ['#A3E6CD'],
                    borderColor: ['#A3E6CD'],
                    borderWidth: 1,
                    barThickness: 20,
                },
            ],
        };
    }, [scheduledMaintenanceData]);

    const setTablePage = useCallback(
        async (tablePage) => {
            dispatch(setPageAction(tablePage));
            dispatch(getAssetRegisterData());
        },
        [dispatch]
    );

    const pagination = useMemo(
        () => ({
            page,
            setTablePage,
            paginationInfo: assetRegisterPagination,
        }),
        [page, setTablePage, assetRegisterPagination]
    );

    useEffect(
        () => dispatch(setDateRangeAction({ startDate: null, endDate: null })),
        [dispatch]
    );

    useEffect(() => {
        getData();
    }, [getData, currentAssetId, currentBuildingId, currentTenantId]);

    return loading || isLoadingParams ? (
        <PageLoading />
    ) : (
        <Pane
            display="flex"
            width="95%"
            maxWidth={1440}
            marginX="auto"
            paddingTop={24}
        >
            <Pane
                display="flex"
                flexDirection="column"
                backgroundColor={colors.neutral300}
                paddingX={20}
                width="100%"
                gap={24}
            >
                <Pane
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    gap={24}
                >
                    <Pane width="100%" display="flex" justifyContent="start">
                        <Breadcrumbs
                            selectedBuildingName={currentAsset?.name}
                        />
                    </Pane>
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        gap={24}
                    >
                        <Heading fontSize={24} fontWeight={700} color="#333333">
                            Maintenance
                        </Heading>
                        <Pane
                            display="flex"
                            gap={12}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <DatePicker
                                selectsRange
                                dateRange={dateRange}
                                setDateRangeAction={setDateRangeAction}
                                getData={() => getData(false, false)}
                            />
                            {(currentAsset ||
                                currentBuilding ||
                                currentTenant) &&
                                create && (
                                    <Button
                                        className="button-add-asset-maintenace-overview"
                                        appearance="primary"
                                        iconBefore={<PlusIcon />}
                                        onClick={() =>
                                            openAddAssetSidebar('addAsset')
                                        }
                                    >
                                        Add new Asset
                                    </Button>
                                )}
                        </Pane>
                    </Pane>
                    <Pane
                        display="flex"
                        flexDirection="row"
                        gap={16}
                        width="100%"
                        alignItems="center"
                    >
                        <OverviewCard
                            heading={overviewData.serviced}
                            text="Total Assets Serviced"
                            icon={ServicedAssetsIcon}
                        />
                        <OverviewCard
                            heading={overviewData.overdue}
                            text="Assets overdue for service"
                            icon={AssetsOverdue}
                        />
                        <OverviewCard
                            heading={overviewData.due}
                            text="Assets due for service"
                            icon={ServiceDueAssetsIcon}
                        />
                        <OverviewCard
                            heading={
                                <>
                                    <Text
                                        fontSize={16}
                                        fontWeight="700"
                                        color="#0C2138"
                                    >
                                        {
                                            overviewData.warrantyData
                                                ?.under_warranty
                                        }
                                    </Text>
                                    <Text
                                        fontSize={16}
                                        fontWeight="700"
                                        color="#8F95B2"
                                    >
                                        {` / ${
                                            overviewData.warrantyData
                                                ?.total_assets || '0'
                                        }`}
                                    </Text>
                                </>
                            }
                            text="Assets under warranty"
                            icon={AssetWarrantyIcon}
                        />
                    </Pane>
                    <Pane display="flex" width="100%" gap={16}>
                        <PieChart
                            data={pieChartData}
                            title="Maintenance per Category"
                        />
                        <BarGraph
                            data={barGraphData}
                            title="Scheduled Maintenance"
                        />
                    </Pane>
                    <Pane width="100%" marginBottom={24}>
                        <MaintenanceAssetRegisterTable
                            data={assetRegister}
                            setShowEditAssetSidebar={openEditAssetSidebar}
                            setShowAddAssetSidebar={openAddAssetSidebar}
                            getTableData={() =>
                                dispatch(getAssetRegisterData())
                            }
                            pagination={pagination}
                            viewAsset={viewAsset}
                        />
                    </Pane>
                </Pane>
            </Pane>
            {assetSidebar.isShown && (create || update) && (
                <AddAssetSidebar
                    isShown={assetSidebar.isShown}
                    isEditing={assetSidebar.isEditing}
                    itemToEdit={assetSidebar.itemToEdit}
                    close={closeAssetSidebar}
                    selectedBuilding={currentAsset}
                />
            )}
            {viewAssetSidebar.isShown && (
                <AssetInfoSideBar
                    isShown={viewAssetSidebar.isShown}
                    asset={viewAssetSidebar.asset}
                    close={closeViewAssetSidebar}
                />
            )}
        </Pane>
    );
};

export default MaintenanceOverviewPage;
