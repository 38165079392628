import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Pane, Heading, Button, Badge, PlusIcon, Text } from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, Doughnut } from 'react-chartjs-2';
import { useSearchParams as useSearchModalParams } from 'react-router-dom';
import TasksTable from '../../components/task/tasks-table';
import { colors } from '../../theme/theme';
import PageLoading from '../../components/molecules/page-loading';
import OverviewCard from '../../components/molecules/overview-card';
import Breadcrumbs from '../../components/molecules/bread-crumbs';
import ChartCard from '../../components/molecules/chart-card';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import completedTasks from '../../assets/images/icons/completed-tasks.svg';
import overdueTasks from '../../assets/images/icons/overdue-tasks.svg';
import totalTasks from '../../assets/images/icons/total-tasks.svg';
import newTasks from '../../assets/images/icons/new-tasks.svg';
import TaskSideSheet from '../../components/task/task-sidesheet';
import {
    getTasks,
    getTasksCategories,
    deleteTask as deleteTaskAction,
} from '../../reducers/task-reducer/task.actions';
import {
    setDateRangeAction,
    taskSelector,
    setTaskToViewAction,
    setTablePageAction,
} from '../../reducers/task-reducer/task.reducer';
import DatePicker from '../../components/organisms/date-picker/date-picker';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import { useSearchParams } from '../../context/search-params-context';
import { useUserAccess } from '../../context/user-access-context';

const COST_GRAPH = {
    data: {
        scheduled: {
            color: '#577BF9',
            label: 'Scheduled',
        },
        onceOff: {
            color: '#8F95B2',
            label: 'Once-Off',
        },
    },
    options: {
        responsive: true,
        showScale: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: '80%',
    },
};

const PROGRESS_CHART = {
    data: {
        overdue: { color: '#DE2C2C', label: 'Overdue' },
        pending: { color: '#FF9162', label: 'Pending' },
        'in progress': { color: '#FFD079', label: 'In Progress' },
        completed: { color: '#52BD94', label: 'Completed' },
        notified: { color: '#9DB5FF', label: 'Notified' },
        new: { color: '#7f00ff', label: 'New' },
    },
    options: {
        responsive: true,
        showScale: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: '80%',
    },
};

const OVERVIEW_DATA = {
    newTasks: {
        icon: newTasks,
        text: 'New Tasks Assigned',
    },
    totalTasks: {
        icon: totalTasks,
        text: 'Total Tasks',
    },
    overdueTasks: {
        icon: overdueTasks,
        text: 'Overdue Tasks',
    },
    completedTasks: {
        icon: completedTasks,
        text: 'Completed Tasks',
    },
};

const HeadingItem = ({ alerts, heading }) => (
    <Pane display="flex" gap={8} justifyItems="center" alignItems="center">
        <Heading>{heading}</Heading>
        {alerts > 0 && (
            <Badge fontSize={12} color="red">
                {alerts} High
            </Badge>
        )}
    </Pane>
);

HeadingItem.propTypes = {
    alerts: PropTypes.number.isRequired,
    heading: PropTypes.string.isRequired,
};

function TaskOverviewPage() {
    const {
        isLoadingParams,
        currentAssetId,
        currentBuildingId,
        currentTenantId,
    } = useSearchParams();
    const dispatch = useDispatch();
    const { tasks, overviewData, overAllGraph, costGraph, dateRange } =
        useSelector(taskSelector);
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);
    const { allRights } = useUserAccess();

    const { create, read } = useMemo(
        () =>
            allRights?.tasks || {
                create: false,
                read: false,
            },
        [allRights]
    );

    const [loading, setLoading] = useState(true);

    const [taskSidesheet, setTaskSidesheet] = useState({
        isShown: false,
        isEditing: false,
        isUpdating: false,
        isViewingDocuments: false,
        selectedTask: null,
    });

    const [searchParams, setSearchParams] = useSearchModalParams({
        modalName: '',
    });

    useEffect(() => {
        const modalName = searchParams.get('modalName');
        if (modalName === 'addTask') {
            setTaskSidesheet({
                isShown: true,
                isEditing: false,
                isUpdating: false,
                isViewingDocuments: false,
                selectedTask: null,
            });
        }
    }, [searchParams]);

    const openAddTaskSidesheet = (id) => {
        const nextSearchParams = new URLSearchParams(searchParams);
        const value = id;
        nextSearchParams.set('modalName', value);
        setSearchParams(nextSearchParams);
        setTaskSidesheet({
            isShown: true,
            isEditing: false,
            isUpdating: false,
            isViewingDocuments: false,
            selectedTask: null,
        });
    };

    const openEditTaskSidesheet = (task) => {
        setTaskSidesheet({
            isShown: true,
            isEditing: true,
            isUpdating: false,
            isViewingDocuments: false,
            selectedTask: task,
        });
    };

    const openUpdateTaskSidesheet = (task) => {
        setTaskSidesheet({
            isShown: true,
            isEditing: false,
            isUpdating: true,
            isViewingDocuments: false,
            selectedTask: task,
        });
    };

    const openViewTasksDocumentsSidesheet = async (task, id) => {
        const nextSearchParams = new URLSearchParams(searchParams);
        const value = id;
        nextSearchParams.set('modalName', value);
        setSearchParams(nextSearchParams);
        dispatch(setTaskToViewAction(task));
        setTaskSidesheet({
            isShown: true,
            isEditing: false,
            isUpdating: false,
            isViewingDocuments: true,
            selectedTask: task,
        });
    };

    const closeTaskSidesheet = async () => {
        const nextSearchParams = new URLSearchParams(searchParams);
        nextSearchParams.delete('modalName');
        setSearchParams(nextSearchParams);
        dispatch(setTaskToViewAction(null));
        setTaskSidesheet({
            isShown: false,
            isEditing: false,
            isUpdating: false,
            isViewingDocuments: false,
            selectedTask: null,
        });
    };

    const deleteTask = useCallback(
        async (id) => {
            await dispatch(deleteTaskAction(id));
        },
        [dispatch]
    );

    const getData = useCallback(async () => {
        if (isLoadingParams) {
            return;
        }
        try {
            await Promise.all([
                await dispatch(getTasks()),
                await dispatch(getTasksCategories()),
                await dispatch(getNotifications()),
            ]);
        } finally {
            setLoading(false);
        }
    }, [dispatch, isLoadingParams]);

    const costData = useMemo(() => {
        if (!costGraph) {
            return {
                datasets: [],
            };
        }
        const labels = Object.keys(costGraph).map(
            (key) => COST_GRAPH.data[key].label
        );
        return {
            labels,
            datasets: [
                {
                    labels,
                    data: Object.values(costGraph),
                    backgroundColor: Object.keys(costGraph).map(
                        (key) => COST_GRAPH.data[key].color
                    ),
                },
            ],
        };
    }, [costGraph]);

    const costLegend = useMemo(
        () =>
            Object.keys(costGraph).map((key) => ({
                label: COST_GRAPH.data[key].label,
                color: COST_GRAPH.data[key].color,
                value: costGraph[key],
            })),
        [costGraph]
    );

    const progressData = useMemo(() => {
        if (!overAllGraph || !read) {
            return {
                datasets: [],
            };
        }
        const labels = Object.keys(overAllGraph).map((key) => {
            if (key === 'completionRate' || key === 'Remainder') {
                return null;
            }
            return PROGRESS_CHART.data[key].label;
        });

        return {
            labels,
            datasets: [
                {
                    labels,
                    data: Object.keys(overAllGraph)
                        .filter((key) => key !== 'completionRate')
                        .map((key) => ({
                            label: PROGRESS_CHART.data[key].label,
                            color: PROGRESS_CHART.data[key].color,
                            value: overAllGraph[key],
                        })),
                    backgroundColor: Object.keys(overAllGraph)
                        .filter((key) => key !== 'completionRate')
                        .map((key) => PROGRESS_CHART.data[key].color),
                },
            ],
        };
    }, [overAllGraph, read]);

    const progressLegend = useMemo(
        () =>
            Object.keys(overAllGraph)
                .filter(
                    (key) => key !== 'Remainder' && key !== 'completionRate'
                )
                .map((key) => ({
                    label: PROGRESS_CHART.data[key].label,
                    color: PROGRESS_CHART.data[key].color,
                    value: overAllGraph[key],
                })),

        [overAllGraph]
    );

    useEffect(() => {
        dispatch(setDateRangeAction({ startDate: null, endDate: null }));
    }, [dispatch]);

    useEffect(() => {
        if (read) {
            getData();
        }
    }, [currentAssetId, currentBuildingId, currentTenantId, read, getData]);

    return loading || isLoadingParams ? (
        <PageLoading />
    ) : (
        <Pane
            display="flex"
            width="95%"
            maxWidth={1440}
            marginX="auto"
            paddingY={24}
        >
            <Pane
                display="flex"
                flexDirection="column"
                backgroundColor={colors.neutral300}
                paddingX={20}
                gap={24}
                width="100%"
            >
                <Breadcrumbs />
                <Pane
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Heading fontSize={24} fontWeight={700} color="#333333">
                        My Tasks
                    </Heading>
                    <Pane display="flex" gap={8} alignItems="center">
                        <DatePicker
                            selectsRange
                            dateRange={dateRange}
                            setDateRangeAction={setDateRangeAction}
                            getData={async () =>
                                Promise.all([
                                    dispatch(setTablePageAction(1)),
                                    dispatch(getTasks()),
                                    dispatch(getTasksCategories()),
                                ])
                            }
                        />
                        {(currentAsset || currentBuilding || currentTenant) &&
                            create && (
                                <Button
                                    className="button-add-task-overview"
                                    appearance="primary"
                                    iconBefore={<PlusIcon />}
                                    onClick={() =>
                                        openAddTaskSidesheet('addTask')
                                    }
                                >
                                    Add New Task
                                </Button>
                            )}
                    </Pane>
                </Pane>
                <Pane
                    display="flex"
                    flexDirection="row"
                    gap={16}
                    alignItems="center"
                >
                    {Object.keys(overviewData).map((key) => {
                        const { count, alerts } = overviewData[key];
                        return (
                            <OverviewCard
                                key={key}
                                heading={
                                    <HeadingItem
                                        heading={
                                            count >= 0
                                                ? count
                                                : overviewData[key]
                                        }
                                        alerts={alerts || 0}
                                    />
                                }
                                text={OVERVIEW_DATA[key].text}
                                icon={OVERVIEW_DATA[key].icon}
                            />
                        );
                    })}
                </Pane>

                <Pane
                    display="flex"
                    gap={16}
                    justifyItems="stretch"
                    paddingBottom={24}
                >
                    <Pane width="100%">
                        <TasksTable
                            data={tasks}
                            getData={getData}
                            openAddTaskSidesheet={openAddTaskSidesheet}
                            openEditTaskSidesheet={openEditTaskSidesheet}
                            openUpdateTaskSidesheet={openUpdateTaskSidesheet}
                            openViewTasksDocumentsSidesheet={
                                openViewTasksDocumentsSidesheet
                            }
                            closeTaskSidesheet={closeTaskSidesheet}
                            deleteTask={deleteTask}
                        />
                    </Pane>
                    <Pane
                        display="flex"
                        gap={24}
                        flexDirection="column"
                        width="50%"
                    >
                        <ChartCard heading="Type" legend={costLegend}>
                            <Bar
                                options={COST_GRAPH.options}
                                data={costData}
                                updateMode="resize"
                                width={800}
                                height={500}
                            />
                        </ChartCard>
                        <ChartCard
                            heading="Overall Progress"
                            filterOptions
                            legend={progressLegend}
                            centerText={
                                <Text
                                    fontWeight={700}
                                    fontSize={22}
                                    color="#8f95b2"
                                    position="absolute"
                                    textAlign="center"
                                >
                                    {Math.floor(overAllGraph?.completionRate) ||
                                        0}
                                    % <br />
                                    <Text color="#8f95b2" fontWeight={700}>
                                        Completion
                                    </Text>
                                </Text>
                            }
                        >
                            <Doughnut
                                options={PROGRESS_CHART.options}
                                data={progressData}
                                updateMode="resize"
                                width={500}
                                height={500}
                            />
                        </ChartCard>
                    </Pane>
                </Pane>
            </Pane>
            {taskSidesheet.isShown && (
                <TaskSideSheet
                    task={taskSidesheet.selectedTask}
                    isShown={taskSidesheet.isShown}
                    isEditing={taskSidesheet.isEditing}
                    isUpdating={taskSidesheet.isUpdating}
                    isViewingDocuments={taskSidesheet.isViewingDocuments}
                    close={closeTaskSidesheet}
                />
            )}
        </Pane>
    );
}

export default TaskOverviewPage;
