import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Pane,
    Heading,
    Button,
    PlusIcon,
    Text,
    Tooltip,
    InfoSignIcon,
    RecordIcon,
} from 'evergreen-ui';
import { Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, debounce } from 'lodash';
import { useSearchParams as userSearchUrlParams } from 'react-router-dom';
import { colors } from '../../theme/theme';
import PageLoading from '../../components/molecules/page-loading';
import OverviewCard from '../../components/molecules/overview-card';
import ChartCard from '../../components/molecules/chart-card';
import buildingsInPortfolio from '../../assets/images/icons/ buildings-in-portfolio.svg';
import completedTasks from '../../assets/images/icons/completed-tasks.svg';
import assetsInPortfolio from '../../assets/images/icons/assets-in-portfolio.svg';
import assetsDueForService from '../../assets/images/icons/assets-due-for-service.svg';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import Breadcrumbs from '../../components/molecules/bread-crumbs';
import {
    taskSelector,
    setDateRangeAction as setMaintenanceDateRange,
} from '../../reducers/task-reducer/task.reducer';
import { getTasks } from '../../reducers/task-reducer/task.actions';
import {
    maintenanceSelector,
    setPageAction,
    setViewAssetSidebar,
    setDateRangeAction as setTasksDateRange,
    setGetAssetRegisterDataAction,
} from '../../reducers/maintenance-reducer/maintenance-reducer';
import { getAssetRegisterData } from '../../reducers/maintenance-reducer/maintenance-actions';
import MaintenanceAssetRegisterTable from '../../components/maintenance/maintenance-asset-register-table';
import AddAssetSidebar from '../../components/organisms/asset-register/asset-register-sidebar';
import AssetInfoSideBar from '../../components/molecules/asset-info';
import CreateAssetSidebarForm from '../../components/organisms/create-asset-sidebar-form/create-asset-sidebar-form';
import ComplianceChartLegend from '../../components/molecules/compliance-chart-legend';
import { maintenanceService } from '../../services';
import DatePicker from '../../components/organisms/date-picker/date-picker';
import { useSearchParams } from '../../context/search-params-context';
import { useUserAccess } from '../../context/user-access-context';

const PROGRESS_CHART = {
    data: {
        overdue: { color: '#DE2C2C', label: 'Overdue' },
        pending: { color: '#FF9162', label: 'Pending' },
        'in progress': { color: '#FFD079', label: 'In Progress' },
        completed: { color: '#52BD94', label: 'Completed' },
        notified: { color: '#9DB5FF', label: 'Notified' },
        new: { color: '#7f00ff', label: 'New' },
    },
};

const CHART_OPTIONS = {
    compliance: {
        responsive: true,
        showScale: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        fontColor: 'white',
        cutout: '60%',
        circumference: 180,
        rotation: -90,
    },
    tasks: {
        responsive: true,
        showScale: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: '80%',
    },
};

const SORT_BY = [
    'overdue',
    'due',
    'upload requested',
    'pending',
    'in progress',
    'notified',
    'new',
    'up to date',
];

function PortfolioOverviewPage() {
    const [loading, setLoading] = useState(false);

    const { allRights, modules } = useUserAccess();

    const {
        isLoadingParams,
        currentAssetId,
        currentBuildingId,
        currentTenantId,
    } = useSearchParams();

    const [searchParams, setSearchParams] = userSearchUrlParams({
        modalName: '',
    });

    const [assetSidebar, setAssetSidebar] = useState({
        isShown: false,
        isEditing: false,
        itemToEdit: null,
    });
    const [showCreatePropertySidebar, setShowCreatePropertySidebar] =
        useState(false);

    const dispatch = useDispatch();

    const { currentAsset, currentBuilding, currentTenant, assets } =
        useSelector(assetSelector);
    const { overAllGraph, dateRange } = useSelector(taskSelector);
    const { assetRegister, assetRegisterPagination, page, viewAssetSidebar } =
        useSelector(maintenanceSelector);

    const { children } = useMemo(
        () => currentBuilding || currentAsset || [],
        [currentAsset, currentBuilding]
    );

    const setTablePage = useCallback(
        async (tablePage) => {
            dispatch(setPageAction(tablePage));
            dispatch(getAssetRegisterData());
        },
        [dispatch]
    );

    const pagination = useMemo(
        () => ({
            page,
            setTablePage,
            paginationInfo: assetRegisterPagination,
        }),
        [page, setTablePage, assetRegisterPagination]
    );

    const searchMaintenanceItems = debounce(async (_search) => {
        dispatch(setPageAction(1));
        const maintenanceItems = await maintenanceService.getAssetRegisterData(
            dateRange,
            !currentAsset && !currentBuilding && !currentTenant,
            _search,
            1,
            null,
            currentTenant?.id || currentBuilding?.id || currentAsset?.id
        );
        dispatch(setGetAssetRegisterDataAction(maintenanceItems));
    }, 300);

    const handleSearch = useCallback(
        async (_search) => {
            searchMaintenanceItems(_search);
        },
        [searchMaintenanceItems]
    );

    useEffect(() => {
        const modalName = searchParams.get('modalName');
        if (modalName === 'addAsset') {
            setAssetSidebar({
                isEditing: false,
                isShown: true,
                itemToEdit: null,
            });
        } else if (modalName === 'addProperty') {
            setShowCreatePropertySidebar(true);
        }
    }, [searchParams]);

    const handleUrlParamSet = useCallback(
        (id) => {
            const nextSearchParams = new URLSearchParams(searchParams);
            const value = id;
            nextSearchParams.set('modalName', value);
            setSearchParams(nextSearchParams);
        },
        [setSearchParams, searchParams]
    );

    const handleUrlParamDelete = useCallback(() => {
        const nextSearchParams = new URLSearchParams(searchParams);
        nextSearchParams.delete('modalName');
        setSearchParams(nextSearchParams);
    }, [setSearchParams, searchParams]);

    const openAddAssetSidebar = useCallback(
        (id) => {
            handleUrlParamSet(id);
            setAssetSidebar({
                isEditing: false,
                isShown: true,
                itemToEdit: null,
            });
        },
        [handleUrlParamSet, setAssetSidebar]
    );

    const closeAssetSidebar = useCallback(() => {
        handleUrlParamDelete();
        setAssetSidebar({ isEditing: false, isShown: false, itemToEdit: null });
    }, [handleUrlParamDelete, setAssetSidebar]);

    const openEditAssetSidebar = (item) => {
        setAssetSidebar({ isEditing: true, isShown: true, itemToEdit: item });
    };

    const viewAsset = (asset) => {
        dispatch(setViewAssetSidebar({ asset, isShown: true }));
    };

    const closeViewAssetSidebar = () => {
        dispatch(setViewAssetSidebar({ asset: null, isShown: false }));
    };

    const closeCreatePropertySidebar = useCallback(() => {
        handleUrlParamDelete();
        setShowCreatePropertySidebar(false);
    }, [setShowCreatePropertySidebar, handleUrlParamDelete]);

    const openCreatePropertySidebar = useCallback(
        (id) => {
            handleUrlParamSet(id);
            setShowCreatePropertySidebar(true);
        },
        [setShowCreatePropertySidebar, handleUrlParamSet]
    );

    const handleParamRemoveOnClose = () => {
        handleUrlParamDelete();
        setShowCreatePropertySidebar(false);
    };

    const overviewItems = useMemo(() => {
        const totalTasks =
            (overAllGraph.completed || 0) +
            (overAllGraph.overdue || 0) +
            (overAllGraph['in progress'] || 0) +
            (overAllGraph.new || 0) +
            (overAllGraph.pending || 0);

        let currentType = 'Portfolio';
        let currentChildrenType = 'Properties';
        let currentAmount = assets.length;

        if (currentAsset) {
            currentType = 'Park';
            currentChildrenType = 'Buildings';
            currentAmount = currentAsset?.children?.length;
        }
        if (currentBuilding) {
            currentType = 'Building';
            currentChildrenType = 'Tenants';
            currentAmount = currentBuilding?.children?.length;
        }
        if (currentTenant) {
            currentType = 'Portfolio';
            currentChildrenType = 'Tenants';
            currentAmount = assets.filter(
                ({ type }) => type === 'tenant'
            ).length;
        }

        return [
            {
                text: `${currentChildrenType} in this ${currentType}`,
                icon: buildingsInPortfolio,
                heading: currentAmount,
            },
            {
                text: 'Completed Tasks',
                icon: completedTasks,
                heading: (
                    <Text fontWeight={600} fontSize={16}>
                        {overAllGraph.completed || 0}{' '}
                        <Text
                            fontWeight={600}
                            fontSize={16}
                            color={totalTasks ? colors.neutral500 : 'black'}
                        >
                            {!!totalTasks && `/ ${totalTasks}`}
                        </Text>
                    </Text>
                ),
            },
            {
                text: `Assets in ${
                    currentType === 'Portfolio' ? 'my' : 'this'
                } ${currentType}`,
                icon: assetsInPortfolio,
                heading: assetRegister.length,
            },
            {
                text: 'Assets due for service',
                icon: assetsDueForService,
                heading: assetRegister.filter(
                    ({ status }) => status.title === 'due'
                ).length,
            },
        ];
    }, [
        assets,
        currentAsset,
        currentBuilding,
        currentTenant,
        assetRegister,
        overAllGraph,
    ]);

    const overallProgress = useMemo(() => {
        let properties = assets;
        const progress = {
            completed: 0,
            notStarted: 0,
            total: 0,
            uploadsRequested: 0,
        };
        if (currentAsset) {
            properties = [...children, currentAsset];
        }
        if (currentBuilding) {
            properties = [...children, currentBuilding];
        }
        if (currentTenant) {
            properties = [currentTenant];
        }

        properties.forEach((property) => {
            progress.completed += property.progress.completed;
            progress.notStarted += property.progress.notStarted;
            progress.total += property.progress.total;
            progress.uploadsRequested += property.progress.uploadsRequested;
        });

        const completedPercentage = Math.ceil(
            (progress.completed / progress.total) * 100
        );
        const uploadsRequestedPercentage = Math.ceil(
            (progress.uploadsRequested / progress.total) * 100
        );
        const notStartedPercentage = Math.ceil(
            (progress.notStarted / progress.total) * 100
        );

        const complianceGroups = {};

        properties.forEach((property) => {
            const groupedProgress = cloneDeep(property.groupedProgress);

            Object.keys(groupedProgress).forEach((group) => {
                if (complianceGroups[group]) {
                    complianceGroups[group].completed +=
                        groupedProgress[group].completed;
                    complianceGroups[group].notStarted +=
                        groupedProgress[group].notStarted;
                    complianceGroups[group].total +=
                        groupedProgress[group].total;
                    complianceGroups[group].uploadsRequested +=
                        groupedProgress[group].uploadsRequested;
                } else {
                    complianceGroups[group] = groupedProgress[group];
                }
            });
        });

        const complianceLegend = Object.keys(complianceGroups).map((key) => ({
            value: Math.ceil(
                (complianceGroups[key].completed /
                    complianceGroups[key].total) *
                    100
            ),
            uploadsRequested: Math.ceil(
                (complianceGroups[key].uploadsRequested /
                    complianceGroups[key].total) *
                    100
            ),
            label: key,
        }));

        return {
            progress: {
                ...progress,
                completedPercentage,
                uploadsRequestedPercentage,
                notStartedPercentage,
            },
            complianceLegend,
        };
    }, [assets, currentAsset, currentBuilding, currentTenant, children]);

    const complianceData = useMemo(() => {
        if (!overallProgress) {
            return { datasets: [] };
        }
        return {
            labels: ['Completed', 'Not Started'],
            datasets: [
                {
                    labels: ['Completed', 'Not Started'],
                    data: [
                        overallProgress.progress.completed,
                        overallProgress.progress.total -
                            overallProgress.progress.completed,
                    ],
                    backgroundColor: ['#474d66', '#d8dae5'],
                },
            ],
        };
    }, [overallProgress]);

    const complianceHeading = useMemo(
        () => (
            <Pane display="flex" alignItems="center" gap={6}>
                <Text fontSize={22} fontWeight="700" color={colors.primary500}>
                    Buildings Compliance
                </Text>
                <Tooltip
                    appearance="card"
                    content={
                        <Pane>
                            <Pane display="flex" gap={6} alignItems="center">
                                <RecordIcon color="#474d66" />
                                <Text>Progress</Text>
                            </Pane>
                            <Pane display="flex" gap={6} alignItems="center">
                                <RecordIcon color="#ff9262" />
                                <Text>Uploads Requested</Text>
                            </Pane>
                        </Pane>
                    }
                >
                    <InfoSignIcon color={colors.primary500} />
                </Tooltip>
            </Pane>
        ),
        []
    );

    const taskData = useMemo(() => {
        if (!overAllGraph || !allRights?.tasks?.read) {
            return { datasets: [] };
        }
        const labels = Object.keys(overAllGraph).map((key) => {
            if (key === 'completionRate' || key === 'Remainder') {
                return null;
            }
            return PROGRESS_CHART.data[key].label;
        });

        return {
            labels,
            datasets: [
                {
                    labels,
                    data: Object.keys(overAllGraph)
                        .filter((key) => key !== 'completionRate')
                        .map((key) => ({
                            label: PROGRESS_CHART.data[key].label,
                            color: PROGRESS_CHART.data[key].color,
                            value: overAllGraph[key],
                        })),
                    backgroundColor: Object.keys(overAllGraph)
                        .filter((key) => key !== 'completionRate')
                        .map((key) => PROGRESS_CHART.data[key].color),
                },
            ],
        };
    }, [overAllGraph, allRights]);

    const taskLegend = useMemo(
        () =>
            allRights?.tasks?.read
                ? Object.keys(overAllGraph)
                      .filter(
                          (key) =>
                              key !== 'Remainder' && key !== 'completionRate'
                      )
                      .map((key) => ({
                          label: PROGRESS_CHART.data[key].label,
                          color: PROGRESS_CHART.data[key].color,
                          value: overAllGraph[key],
                      }))
                : [],

        [allRights, overAllGraph]
    );

    const taskCenterText = useMemo(
        () => (
            <Text
                fontWeight={800}
                fontSize={36}
                color="#8f95b2"
                position="absolute"
                textAlign="center"
            >
                {Object.keys(overAllGraph).filter(
                    (key) => key !== 'Remainder' && key !== 'completionRate'
                ).length ? (
                    <>
                        {Math.floor(overAllGraph?.completionRate) || 0}%
                        <br />
                        <Text
                            color="#8f95b2"
                            fontSize={24}
                            fontWeight={700}
                            lineHeight={2}
                        >
                            Completion
                        </Text>
                    </>
                ) : (
                    'No Tasks'
                )}
            </Text>
        ),
        [overAllGraph]
    );

    const complianceCenterText = useMemo(
        () => (
            <Text
                fontWeight={800}
                fontSize={36}
                color="#8f95b2"
                position="absolute"
                textAlign="center"
                bottom="40%"
            >
                {Math.floor(overallProgress.progress.completedPercentage) || 0}%
            </Text>
        ),
        [overallProgress]
    );

    const maintenanceSchedule = useMemo(
        () =>
            [...assetRegister].sort(
                ({ status: { title } }, { status: { title: nextTitle } }) => {
                    if (SORT_BY.indexOf(title) < SORT_BY.indexOf(nextTitle)) {
                        return -1;
                    }
                    if (SORT_BY.indexOf(title) > SORT_BY.indexOf(nextTitle)) {
                        return 1;
                    }
                    return 0;
                }
            ),
        [assetRegister]
    );

    const getData = useCallback(
        async (shouldSetLoading = true) => {
            if (!isLoadingParams) {
                if (shouldSetLoading) {
                    setLoading(true);
                }
                try {
                    await Promise.all([
                        await dispatch(getTasks()),
                        await dispatch(getAssetRegisterData()),
                    ]);
                } finally {
                    setLoading(false);
                }
            }
        },
        [dispatch, isLoadingParams]
    );
    const handleDateRangeChange = useCallback(
        async (dates) => {
            await Promise.all([
                dispatch(
                    setMaintenanceDateRange({
                        startDate: dates[0] ? Date.parse(dates[0]) : null,
                        endDate: dates[1] ? Date.parse(dates[1]) : null,
                    })
                ),
                dispatch(
                    setTasksDateRange({
                        startDate: dates[0] ? Date.parse(dates[0]) : null,
                        endDate: dates[1] ? Date.parse(dates[1]) : null,
                    })
                ),
            ]);
        },
        [dispatch]
    );

    useEffect(() => {
        handleDateRangeChange([null, null]);
    }, [handleDateRangeChange]);

    useEffect(() => {
        getData();
    }, [getData, currentAssetId, currentBuildingId, currentTenantId]);

    let propertyTypeName;

    if (currentBuilding) {
        propertyTypeName = 'Tenant';
    } else if (currentAsset) {
        propertyTypeName = 'Building';
    } else {
        propertyTypeName = 'Property';
    }

    return loading || isLoadingParams ? (
        <PageLoading />
    ) : (
        <>
            <Pane
                display="flex"
                width="95%"
                maxWidth={1440}
                marginX="auto"
                paddingTop={24}
            >
                <Pane
                    display="flex"
                    flexDirection="column"
                    gap={24}
                    paddingX={20}
                    width="100%"
                    flex={1}
                >
                    <Breadcrumbs />
                    <Pane
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Heading fontSize={24} fontWeight={700} color="#333333">
                            Overview
                        </Heading>
                        <Pane
                            display="flex"
                            gap={12}
                            justifyContent="space-between"
                        >
                            <DatePicker
                                selectsRange
                                dateRange={dateRange}
                                getData={() => getData(false)}
                                handleDateRange={handleDateRangeChange}
                            />
                            {!currentTenant && allRights?.info?.create && (
                                <Button
                                    className="button-add-new-property-overview"
                                    appearance="primary"
                                    iconBefore={<PlusIcon />}
                                    onClick={() =>
                                        openCreatePropertySidebar(
                                            `add${propertyTypeName}`
                                        )
                                    }
                                >
                                    Add new{' '}
                                    {/* eslint-disable-next-line
                                    no-nested-ternary */}
                                    {currentBuilding
                                        ? 'Tenant'
                                        : currentAsset
                                        ? 'Building'
                                        : 'Property'}
                                </Button>
                            )}
                        </Pane>
                    </Pane>
                    <Pane
                        display="flex"
                        flexDirection="row"
                        gap={16}
                        alignItems="center"
                    >
                        {overviewItems.map(({ text, icon, heading }) => (
                            <OverviewCard
                                heading={heading}
                                text={text}
                                icon={icon}
                            />
                        ))}
                    </Pane>
                    <Pane
                        display="flex"
                        gap={16}
                        justifyItems="stretch"
                        flexDirection="column"
                    >
                        <Pane display="flex" gap={24}>
                            {modules.compliance && (
                                <ChartCard
                                    heading={complianceHeading}
                                    legend={
                                        <ComplianceChartLegend
                                            complianceDetails={
                                                overallProgress.complianceLegend
                                            }
                                        />
                                    }
                                    height={500}
                                    centerText={complianceCenterText}
                                >
                                    <Pane
                                        display="flex"
                                        padding={20}
                                        maxWidth={400}
                                        maxHeight={350}
                                    >
                                        <Doughnut
                                            options={CHART_OPTIONS.compliance}
                                            data={complianceData}
                                            updateMode="resize"
                                            width={300}
                                            height={300}
                                        />
                                    </Pane>
                                </ChartCard>
                            )}
                            {modules.tasks && (
                                <ChartCard
                                    height={500}
                                    filterOptions
                                    heading="Tasks"
                                    legend={taskLegend}
                                    centerText={taskCenterText}
                                >
                                    <Pane
                                        display="flex"
                                        padding={20}
                                        maxWidth={400}
                                        maxHeight={350}
                                    >
                                        <Doughnut
                                            options={CHART_OPTIONS.tasks}
                                            data={taskData}
                                            updateMode="resize"
                                            width={300}
                                            height={300}
                                        />
                                    </Pane>
                                </ChartCard>
                            )}
                        </Pane>
                        {modules.maintenance && (
                            <Pane
                                display="flex"
                                flex={1}
                                width="100%"
                                marginBottom={64}
                            >
                                <MaintenanceAssetRegisterTable
                                    data={maintenanceSchedule}
                                    title="My Maintenance Schedule"
                                    setShowEditAssetSidebar={
                                        openEditAssetSidebar
                                    }
                                    setShowAddAssetSidebar={openAddAssetSidebar}
                                    handleSearch={handleSearch}
                                    getTableData={() =>
                                        dispatch(getAssetRegisterData())
                                    }
                                    pagination={pagination}
                                    viewAsset={viewAsset}
                                />
                            </Pane>
                        )}
                    </Pane>
                </Pane>
            </Pane>
            {assetSidebar.isShown && (
                <AddAssetSidebar
                    isShown={assetSidebar.isShown}
                    isEditing={assetSidebar.isEditing}
                    itemToEdit={assetSidebar.itemToEdit}
                    close={closeAssetSidebar}
                    selectedBuilding={currentAsset}
                />
            )}
            {viewAssetSidebar.isShown && (
                <AssetInfoSideBar
                    isShown={viewAssetSidebar.isShown}
                    asset={viewAssetSidebar.asset}
                    close={closeViewAssetSidebar}
                />
            )}
            {showCreatePropertySidebar && (
                <CreateAssetSidebarForm
                    isShown={showCreatePropertySidebar}
                    close={closeCreatePropertySidebar}
                    onClose={handleParamRemoveOnClose}
                />
            )}
        </>
    );
}

export default PortfolioOverviewPage;
