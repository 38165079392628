import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
    Pane,
    Heading,
    Card,
    Text,
    SelectField,
    Spinner,
    Pagination,
    MoreIcon,
    SearchInput,
    InfoSignIcon,
    Tooltip,
} from 'evergreen-ui';
import { useTable } from 'react-table';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { colors } from '../../theme/theme';
import ContactStepper from './contact-stepper';
import {
    contactsSelector,
    setPageAction,
    setProfessionFilterAction,
    setSearchAction,
} from '../../reducers/contacts-reducer/contacts.reducer';
import { getBusinessContacts } from '../../reducers/contacts-reducer/contacts.actions';

const PROFESSIONS = [
    'Project Manager',
    'Principle Architect',
    'Principle Consultant',
    'Architect',
    'Interior Designer',
    'Townplanner',
    'Quantity Surveyor',
    'Structural Engineer',
    'Electrical Engineer',
    'Mechanical Engineer',
    'Fire Consultant',
];

function ContactsTable({
    data,
    handleEditBusinessContact,
    handleAddStaffContact,
    handleEditStaffContact,
    openConfirmModal,
    openRelativeBuildingsModal,
}) {
    const dispatch = useDispatch();

    const { pagination, page, professionFilter, search } =
        useSelector(contactsSelector);
    const [loading, setLoading] = useState(false);

    const relativeBuildingsHeader = useCallback(
        () => (
            <Pane display="flex" gap={8} alignItems="center">
                <Text fontSize={16} fontWeight={600}>
                    Relative Buildings
                </Text>
                <Tooltip
                    content="Show buildings that this contact is involved with."
                    showDelay={500}
                >
                    <InfoSignIcon size={14} />
                </Tooltip>
            </Pane>
        ),
        []
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Company Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Phone',
                accessor: 'mobileNumber',
            },
            {
                Header: relativeBuildingsHeader,
                accessor: 'relativeBuildings',
            },
            {
                Header: <MoreIcon color="#8f95b2" />,
                accessor: 'seen',
            },
        ],
        [relativeBuildingsHeader]
    );

    const tableInstance = useTable({
        columns,
        data,
    });

    const debounceSearch = debounce(
        (value) => dispatch(setSearchAction(value)),
        250
    );

    const handleSearchChange = ({ target: { value } }) =>
        debounceSearch(`${value ? `${value}:*` : ''}`);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            await dispatch(getBusinessContacts());
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    const handleFilterChange = async ({ target: { value } }) => {
        dispatch(setProfessionFilterAction(value));
    };

    const handlePrevPage = () => {
        if (page > 1) {
            dispatch(setPageAction(page - 1));
        }
    };

    const handleNextPage = () => {
        if (page < pagination.pageTotal) {
            dispatch(setPageAction(page + 1));
        }
    };

    const handlePageChange = (_page) => {
        dispatch(setPageAction(_page));
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    useEffect(() => {
        getData();
    }, [getData, page, search, professionFilter]);

    return (
        <Card
            elevation={1}
            backgroundColor="white"
            borderRadius={10}
            height="100%"
            margin={1}
            flex={1}
            display="flex"
            flexDirection="column"
            maxHeight={812}
            marginBottom={20}
        >
            <Pane
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="1.125rem"
                borderBottom={`1px solid ${colors.neutral500}`}
            >
                <Heading
                    fontSize={22}
                    fontWeight="700"
                    color={colors.primary500}
                >
                    Contacts
                </Heading>
                <Pane display="flex" gap={16} alignItems="center">
                    <SearchInput
                        placeholder="Search"
                        width={200}
                        marginTop={4}
                        height={30}
                        onChange={handleSearchChange}
                    />
                    <SelectField
                        name="profession"
                        onChange={handleFilterChange}
                        marginY={-5}
                        marginTop={-10}
                        value={professionFilter}
                    >
                        <option value="">All Professions</option>
                        {PROFESSIONS.map((profession) => (
                            <option value={profession} key={profession}>
                                {profession}
                            </option>
                        ))}
                    </SelectField>
                </Pane>
            </Pane>
            <StyledTable>
                <table
                    {...getTableProps()}
                    style={{
                        width: '100%',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        padding: 0,
                        borderSpacing: 0,
                        borderCollapse: 'collapse',
                        border: 'none',
                    }}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    borderBottom: `1px solid ${colors.neutral500}`,
                                    backgroundColor: '#F9FAFC',
                                    height: 55,
                                }}
                            >
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        style={{
                                            minWidth:
                                                index ===
                                                headerGroup.headers.length - 1
                                                    ? 40
                                                    : 160,
                                            maxWidth:
                                                index ===
                                                headerGroup.headers.length - 1
                                                    ? 100
                                                    : 'none',
                                            display: 'flex',
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            outline: 'none',
                                        }}
                                        {...column.getHeaderProps()}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                color: '#3A3541DE',
                                                justifyContent:
                                                    index ===
                                                    headerGroup.headers.length -
                                                        1
                                                        ? 'center'
                                                        : 'start',
                                                borderRight:
                                                    index !==
                                                    headerGroup.headers.length -
                                                        1
                                                        ? `1px solid ${colors.neutral500}`
                                                        : null,
                                                paddingLeft:
                                                    index ===
                                                    headerGroup.headers.length -
                                                        1
                                                        ? 0
                                                        : 15,
                                            }}
                                        >
                                            {column.render('Header')}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody
                        {...getTableBodyProps()}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: 685,
                            overflowY: 'auto',
                        }}
                    >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {loading ? (
                            <tr style={{ display: 'flex' }}>
                                <td
                                    style={{
                                        display: 'flex',
                                        borderBottom: `1px solid ${colors.neutral500}`,
                                        flex: 1,
                                        justifyItems: 'stretch',
                                        height: 80,
                                    }}
                                >
                                    <Pane
                                        display="flex"
                                        width="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Spinner />
                                    </Pane>
                                </td>
                            </tr>
                        ) : rows.length ? (
                            rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        style={{ display: 'flex' }}
                                    >
                                        <td
                                            style={{
                                                display: 'flex',
                                                borderBottom: `1px solid ${colors.neutral500}`,
                                                flex: 1,
                                                justifyItems: 'stretch',
                                            }}
                                        >
                                            <ContactStepper
                                                business={row.original}
                                                handleEditBusinessContact={
                                                    handleEditBusinessContact
                                                }
                                                handleAddStaffContact={
                                                    handleAddStaffContact
                                                }
                                                handleEditStaffContact={
                                                    handleEditStaffContact
                                                }
                                                openConfirmModal={
                                                    openConfirmModal
                                                }
                                                openRelativeBuildingsModal={
                                                    openRelativeBuildingsModal
                                                }
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr style={{ display: 'flex' }}>
                                <td
                                    style={{
                                        display: 'flex',
                                        borderBottom: `1px solid ${colors.neutral500}`,
                                        flex: 1,
                                        justifyItems: 'stretch',
                                        height: 80,
                                    }}
                                >
                                    <Pane
                                        display="flex"
                                        width="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Text textAlign="center">
                                            No Available Contacts
                                        </Text>
                                    </Pane>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </StyledTable>
            {pagination.pageTotal > 1 && (
                <Pagination
                    marginLeft="auto"
                    marginRight={10}
                    marginY={15}
                    page={page}
                    totalPages={69}
                    onPageChange={handlePageChange}
                    onPreviousPage={handlePrevPage}
                    onNextPage={handleNextPage}
                />
            )}
        </Card>
    );
}

const StyledTable = styled.div`
    overflow: auto;

    tr {
        width: 100%;
    }
`;

ContactsTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleAddStaffContact: PropTypes.func.isRequired,
    handleEditStaffContact: PropTypes.func.isRequired,
    handleEditBusinessContact: PropTypes.func.isRequired,
    openConfirmModal: PropTypes.func.isRequired,
    openRelativeBuildingsModal: PropTypes.func.isRequired,
};

export default ContactsTable;
