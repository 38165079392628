import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import {
    Dialog,
    Pane,
    Text,
    Select,
    Tooltip,
    InfoSignIcon,
    Spinner,
} from 'evergreen-ui';

import {
    transformObject,
    flattenObjectWithAliasKeys,
    splitArrayByTextLength,
} from './export-json-utils';
import { assetExportModel } from '../../../models';
import { documentService } from '../../../services';
import { errorToast } from '../../toasts';

const ExportToExcel = ({ data, fileName, isDialogShown, setIsDialogShown }) => {
    const [dropdownValue, setDropdownValue] = useState('No');
    const [isLoading, setIsLoading] = useState(false);

    const handleExport = useCallback(async () => {
        setIsLoading(true);
        const workbook = XLSX.utils.book_new();

        try {
            const sheetPromises = Object.keys(data).map(async (sheetName) => {
                const flattenedData = await Promise.all(
                    data[sheetName].map(async (item) => {
                        const { result } = flattenObjectWithAliasKeys(item);
                        const modelData = assetExportModel(result);
                        const parsedData = transformObject(modelData);

                        let documentLinkArray = [];
                        if (
                            dropdownValue === 'Yes' &&
                            (item?.documents?.length ||
                                item?.task_documents?.length)
                        ) {
                            const itemArray =
                                item.documents || item.task_documents;

                            try {
                                documentLinkArray = await Promise.all(
                                    itemArray.map(async (doc) => {
                                        const response =
                                            await documentService.getFileDownloadLink(
                                                {
                                                    fileKey: doc.file_key,
                                                }
                                            );
                                        return response.url || response;
                                    })
                                );
                            } catch (error) {
                                errorToast(error.message);
                            }
                        }

                        const [documentLinksPart1, documentLinksPart2] =
                            splitArrayByTextLength(documentLinkArray);

                        const documentLink1 = documentLinksPart1
                            .map((link) => link.downloadLink)
                            .join('\n');

                        const documentLink2 = documentLinksPart2
                            .map((link) => link.downloadLink)
                            .join('\n');

                        return {
                            ...parsedData,
                            documentLink1: documentLink1 || 'No Links',
                            documentLink2: documentLink2 || '',
                        };
                    })
                );

                const sortedData = flattenedData.sort((a, b) => {
                    const firstColumnKey = Object.keys(a)[0];

                    const isDateColumn =
                        !Number.isNaN(Date.parse(a[firstColumnKey])) &&
                        !Number.isNaN(Date.parse(b[firstColumnKey]));

                    if (isDateColumn) {
                        return (
                            new Date(b[firstColumnKey]) -
                            new Date(a[firstColumnKey])
                        );
                    }

                    return String(a[firstColumnKey]).localeCompare(
                        String(b[firstColumnKey])
                    );
                });

                const worksheet = XLSX.utils.json_to_sheet(sortedData, {
                    raw: true,
                });
                XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
            });

            await Promise.all(sheetPromises);

            const timestamp = new Date().getTime();
            const fullFileName = `${fileName}-${timestamp}.xlsx`;

            XLSX.writeFile(workbook, fullFileName);
        } catch (error) {
            errorToast(error.message);
        } finally {
            setIsLoading(false);
            setIsDialogShown(false);
            setDropdownValue('No');
        }
    }, [data, dropdownValue, fileName, setIsDialogShown]);

    const handleDialogClose = useCallback(() => {
        setDropdownValue('No');
        setIsDialogShown(false);
    }, [setIsDialogShown]);

    return (
        <Pane>
            <Dialog
                isShown={isDialogShown}
                title="Confirm Export"
                intent="success"
                onCloseComplete={handleDialogClose}
                confirmLabel="Export"
                onConfirm={handleExport}
            >
                {isLoading ? (
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Spinner size={24} />
                        <Text marginLeft={8}>Exporting...</Text>
                    </Pane>
                ) : (
                    <>
                        <Text>
                            Are you sure you want to export the data to an Excel
                            file? The file will be named{' '}
                            <strong>{fileName}.xlsx</strong>.
                        </Text>
                        <Pane marginTop={16}>
                            <Pane
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text>
                                    Would you like to export the assets with all
                                    of its associated documents?
                                </Text>
                                <Tooltip content="By exporting the asset with all of its associated documents, a link to access the document will be generated. Note: Anyone with this link would have access to your private documents.">
                                    <InfoSignIcon size={14} color="#c8cacf" />
                                </Tooltip>
                            </Pane>
                            <Select
                                width="100%"
                                value={dropdownValue}
                                onChange={(e) =>
                                    setDropdownValue(e.target.value)
                                }
                            >
                                <option value="">Please select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Select>
                        </Pane>
                    </>
                )}
            </Dialog>
        </Pane>
    );
};

ExportToExcel.propTypes = {
    data: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    isDialogShown: PropTypes.bool.isRequired,
    setIsDialogShown: PropTypes.func.isRequired,
};

export default ExportToExcel;
