import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Pane,
    Paragraph,
    ChevronRightIcon,
    Text,
    EditIcon,
    TrashIcon,
    Tooltip,
    InfoSignIcon,
} from 'evergreen-ui';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { colors } from '../../../../theme/theme';
import { Collapsible } from '../../../atoms';
import AddForm from '../forms-templates/AddForm';
import EditForm from '../forms-templates/EditForm';

import ComplianceTemplateType from '../compliance-type-template-form/ComplianceTemplateType';

import {
    addComplianceType,
    deleteComplianceCategory,
    renameComplianceCategory,
} from '../../../../reducers/compliance-template-reducer/compliance-template.actions';

function ComplianceTemplateCategory({
    category,
    groupId,
    editingCategory,
    setEditingCategory,
}) {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [addingComplianceType, setAddingComplianceType] = useState({
        addingType: false,
        categoryName: null,
        typeName: '',
    });
    const [newCatName, setNewCatName] = useState(category.name);

    const handleNewTypeValue = ({ target: { value } }) => {
        setAddingComplianceType((state) => ({
            ...state,
            typeName: value,
        }));
    };

    const cancelAddType = () => {
        setAddingComplianceType({
            addingType: false,
            categoryName: null,
            typeName: null,
        });
    };

    const newName = useRef();

    const setEditValue = ({ target: { value } }) => {
        setNewCatName(value);
        newName.current = value;
    };

    const editCategory = (e) => {
        e.stopPropagation();
        setEditingCategory(category.name);
    };

    const handleCategoryEdit = ({ key, type: buttonType }) => {
        if (buttonType === 'click' || key === 'Enter') {
            dispatch(
                renameComplianceCategory(groupId, category.name, newCatName)
            );
            cancelEdit();
        }
    };

    const handleAddType = ({ key, type: buttonType }) => {
        if (buttonType === 'click' || key === 'Enter') {
            const { categoryName, typeName } = addingComplianceType;
            dispatch(addComplianceType(groupId, categoryName, typeName));
            cancelAddType();
        }
    };

    const cancelEdit = (e) => {
        if (e) {
            e.stopPropagation();
        }
        setNewCatName(category.name);
        setEditingCategory(null);
    };

    const deleteCategory = (e, categoryName) => {
        e.stopPropagation();
        return dispatch(deleteComplianceCategory(groupId, categoryName));
    };

    const toggleCollapsible = () => setIsOpen((state) => !state);

    return (
        <Pane
            borderBottom={`${isOpen ? '1px solid #696f8c' : 'none'}`}
            marginLeft={30}
            paddingRight={5}
        >
            <Pane>
                <Pane
                    display="flex"
                    justifyContent="space-between"
                    paddingTop={12}
                    alignItems="center"
                    cursor="pointer"
                    onClick={toggleCollapsible}
                >
                    <Category display="flex" alignItems="center" width="100%">
                        <ChevronRightIcon
                            color={colors.gray800}
                            marginRight={6}
                            transform={isOpen && 'rotate(90deg)'}
                            transition="transform 250ms"
                        />

                        {editingCategory ? (
                            <Pane paddingTop={15}>
                                <EditForm
                                    value={category.name}
                                    handleEdit={handleCategoryEdit}
                                    cancelEdit={cancelEdit}
                                    ref={newName}
                                    onChangeValue={setEditValue}
                                />
                            </Pane>
                        ) : (
                            <Paragraph
                                size={600}
                                fontWeight="700"
                                fontSize={14}
                                color="#0C2138"
                            >
                                {category.name}
                            </Paragraph>
                        )}
                        {!editingCategory && (
                            <Pane display="flex" gap={5} marginLeft="auto">
                                <Pane display="flex" gap={10}>
                                    <Pane
                                        alignItems="center"
                                        justifyContent="center"
                                        padding={8}
                                        className="edit-contact"
                                        hoverElevation={1}
                                        role="button"
                                        onClick={editCategory}
                                    >
                                        <EditIcon
                                            color="#696f8c"
                                            className="edit-contact"
                                        />
                                    </Pane>
                                </Pane>
                                <Pane display="flex" gap={10}>
                                    <Pane
                                        alignItems="center"
                                        justifyContent="center"
                                        padding={8}
                                        className="delete-contact"
                                        hoverElevation={1}
                                        role="button"
                                        onClick={(e) =>
                                            deleteCategory(e, category.name)
                                        }
                                    >
                                        <TrashIcon
                                            color="#c60b0b"
                                            className="delete-contact"
                                        />
                                    </Pane>
                                </Pane>
                            </Pane>
                        )}
                    </Category>
                </Pane>
            </Pane>
            <Collapsible isOpen={isOpen}>
                {_.map(
                    _.orderBy(category.type, ['order'], 'desc'),
                    (type, typeIndex) => (
                        <ComplianceTemplateType
                            key={`${type.id}-${typeIndex}`}
                            type={type}
                            groupId={groupId}
                            categoryName={category.name}
                        />
                    )
                )}
                {addingComplianceType.addingType ? (
                    <Pane paddingLeft={25} paddingBottom={28}>
                        <AddForm
                            add={handleAddType}
                            placeholder="Adding Type"
                            handleChange={handleNewTypeValue}
                            cancelAdd={cancelAddType}
                        />
                    </Pane>
                ) : (
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="start"
                        gap={10}
                    >
                        <AddTypeButton
                            marginLeft={25}
                            marginRight={30}
                            marginTop={14}
                            marginBottom={28}
                            onClick={() =>
                                setAddingComplianceType({
                                    groupId,
                                    categoryName: category.name,
                                    typeName: '',
                                    addingType: true,
                                })
                            }
                        >
                            <Text>+ Add Compliance Type</Text>
                        </AddTypeButton>
                        <Tooltip content="Here you can further define Compliance Types. These Types represent specific document types or subcategories related to the Compliance Category. Consider the different document types you frequently in each Compliance Category. For Example, under the Compliance Category 'Financial Reporting', you might have types like 'Balance Sheets', 'Income Statements' or 'Audit Reports'.">
                            <InfoSignIcon
                                size={14}
                                marginBottom={15}
                                color="#c8cacf"
                            />
                        </Tooltip>
                    </Pane>
                )}
            </Collapsible>
        </Pane>
    );
}

ComplianceTemplateCategory.propTypes = {
    category: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    groupId: PropTypes.string.isRequired,
    editingCategory: PropTypes.bool.isRequired,
    setEditingCategory: PropTypes.func.isRequired,
};

const Category = styled(Pane)`
    height: 40px;
    .edit-contact,
    .delete-contact {
        display: none;
        margin-left: auto;
        border-radius: 4px;
        background-color: #f5f6fa;
        cursor: pointer;
    }

    &:hover {
        .edit-contact {
            display: flex;
        }
        .delete-contact {
            display: flex;
        }
    }
`;

const AddTypeButton = styled(Pane)`
    span {
        color: #5ec090;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export default ComplianceTemplateCategory;
