import _ from 'lodash';

export const assetModel = (assetObject = {}, portfolioObject = {}) => ({
    id: _.get(assetObject, 'id', '').toString(),
    assetName: _.get(assetObject, 'name', ''),
    assetArchive: _.get(assetObject, 'archived'),
    assetUnArchiving: _.get(assetObject, 'archived_restoring'),
    erfNumber: _.get(assetObject, 'erfnumber', ''),
    buildingManagerIds: _.get(assetObject, 'building_manager_id', []),
    landlordContactId: String(
        _.get(assetObject, 'linked_landlord_contact_id', []).length > 0
            ? _.get(assetObject, 'linked_landlord_contact_id')[0]
            : ''
    ),
    grossBuildingArea: _.get(assetObject, 'gba') || '',
    grossLettableArea: _.get(assetObject, 'gla') || '',
    builtFar: _.get(assetObject, 'Built_FAR') || '',
    type: _.get(assetObject, 'type', ''),
    permissibleFar: _.get(assetObject, 'permissible_FAR') || '',
    builtCoverage: _.get(assetObject, 'Built_coverage') || '',
    permissibleCoverage: _.get(assetObject, 'permissible_coverage') || '',
    buildingUse: _.get(assetObject, 'use', ''),
    bucketId: _.get(assetObject, 'bucket_id', ''),
    complianceGroups: _.orderBy(
        _.map(_.get(assetObject, 'compliance_group'), (group) => ({
            id: _.get(group, 'id'),
            createdAt: _.get(group, 'created_at'),
            name: _.get(group, 'name'),
            order:
                _.get(group, 'order', 0) === 0
                    ? Number.MAX_SAFE_INTEGER
                    : _.get(group, 'order', 0),
            spacesId: _.get(group, 'spaces_id'),
            complianceCategories: _.orderBy(
                _.map(_.get(group, 'compliance_category'), (category) => ({
                    id: _.get(category, 'id'),
                    createdAt: _.get(category, 'created_at'),
                    name: _.get(category, 'name'),
                    order:
                        _.get(category, 'order', 0) === 0
                            ? Number.MAX_SAFE_INTEGER
                            : _.get(category, 'order', 0),
                    complianceTypes: _.map(
                        _.get(category, 'compliance_types'),
                        (type) => ({
                            id: _.get(type, 'id'),
                            complianceCategoryId: _.get(category, 'id'),
                            createdAt: _.get(type, 'createdAt'),
                            name: _.get(type, 'name'),
                            completed: !_.get(type, 'upload_pending'),
                            applicable: _.get(type, 'applicable'),
                            isDefault: _.get(type, 'is_default'),
                            uploadRequest: assetUploadRequestModel(
                                _.find(
                                    _.get(
                                        assetObject,
                                        'asset_uploadrequests',
                                        []
                                    ),
                                    (uploadRequest) => {
                                        const uploadRequestModel =
                                            assetUploadRequestModel(
                                                uploadRequest
                                            );
                                        return (
                                            uploadRequestModel.complianceTypeId ===
                                            _.get(type, 'id')
                                        );
                                    }
                                )
                            ),
                        })
                    ),
                })),
                ['order', 'createdAt'],
                ['asc', 'asc']
            ),
        })),
        ['order', 'createdAt'],
        ['asc', 'asc']
    ),
    uploadRequests: _.map(
        _.get(assetObject, 'asset_uploadrequests', []),
        (uploadRequest) => assetUploadRequestModel(uploadRequest)
    ),
    address: {
        addressLine1: _.get(assetObject, 'address.address1', ''),
        addressLine2: _.get(assetObject, 'address.address2', ''),
        suburb: _.get(assetObject, 'address.suburb', ''),
        city: _.get(assetObject, 'address.city', ''),
        province: _.get(assetObject, 'address.province', ''),
        postalCode: _.get(assetObject, 'address.postalcode', ''),
        country: _.get(assetObject, 'address.country', ''),
        lat: _.get(assetObject, 'address.location.data.lat'),
        long: _.get(assetObject, 'address.location.data.lng'),
    },
    portfolioName: _.get(assetObject, 'portfolio.name')
        ? _.get(assetObject, 'portfolio.name')
        : _.get(portfolioObject, 'name', ''),
    portfolioId: _.get(portfolioObject, 'id', '')
        ? _.get(portfolioObject, 'id')
        : _.get(assetObject, 'portfolio_id', ''),
    storey: _.get(assetObject, 'storey', ''),
    images: _.map(_.get(assetObject, 'images', []), (image) => ({
        fileId: _.get(image, 'file_id'),
        thumbnailId: _.get(image, 'thumbnail_id'),
        isFeatured: _.get(image, 'is_featured'),
        assetId: _.get(assetObject, 'bucket_id'),
        url: _.get(image, 'url'),
    })),
    children: _.map(_.get(assetObject, 'children', []), (child) => {
        _.map(_.get(child, 'children', []), (_child) => assetModel(_child));
        return assetModel(child);
    }),
    progress: _.get(assetObject, 'progress', {}),
    parentId: _.get(assetObject, 'parentId', ''),
    groupedProgress: _.get(assetObject, 'groupedProgress', {}),
});

export const assetBasicDetailsModel = (assetObject = {}) => ({
    propertyType: _.get(assetObject, 'propertyType', 'park'),
    parentId: _.get(assetObject, 'parentId', ''),
    buildingUse: _.get(assetObject, 'buildingUse', ''),
    portfolioType: _.get(assetObject, 'portfolioType', ''),
    name: _.get(assetObject, 'name', ''),
    erfNumber: _.get(assetObject, 'erfNumber', ''),
    buildingManagerIds: _.get(assetObject, 'buildingManagerIds', []),
    landlordContactId: _.get(assetObject, 'landlordContactId', ''),
    linkedToPark: _.get(assetObject, 'linkedToPark', false),
});

export const assetAddressModel = (assetObject = {}) => ({
    addressLine1: _.get(assetObject, 'addressLine1', ''),
    addressLine2: _.get(assetObject, 'addressLine2', ''),
    suburb: _.get(assetObject, 'suburb', ''),
    city: _.get(assetObject, 'city', ''),
    province: _.get(assetObject, 'province', ''),
    postalCode: _.get(assetObject, 'postalCode', ''),
    country: _.get(assetObject, 'country', ''),
    lat: _.get(assetObject, 'lat', 0),
    long: _.get(assetObject, 'long', 0),
});

export const assetPropertiesModel = (assetObject = {}) => ({
    buildingUse: _.get(assetObject, 'buildingUse', ''),
    permissibleCoverage: _.get(assetObject, 'permissibleCoverage', ''),
    builtCoverage: _.get(assetObject, 'builtCoverage', ''),
    permissibleFar: _.get(assetObject, 'permissibleFar', ''),
    builtFar: _.get(assetObject, 'builtFar', ''),
    grossLettableArea: _.get(assetObject, 'grossLettableArea', ''),
    grossBuildingArea: _.get(assetObject, 'grossBuildingArea', ''),
    storey: _.get(assetObject, 'storey', ''),
});

export const assetApiModel = (assetObject = {}) => ({
    asset_id: _.get(assetObject, 'id', ''),
    name: _.get(assetObject, 'name', ''),
    portfolio_id: _.get(assetObject, 'portfolioId', ''),
    address: {
        address1: _.get(assetObject, 'address.addressLine1', ''),
        address2: _.get(assetObject, 'address.addressLine2', ''),
        suburb: _.get(assetObject, 'address.suburb', ''),
        city: _.get(assetObject, 'address.city', ''),
        province: _.get(assetObject, 'address.province', ''),
        postalcode: _.get(assetObject, 'address.postalCode', ''),
        country: _.get(assetObject, 'address.country', ''),
        location: {
            type: 'point',
            data: {
                lat: _.get(assetObject, 'address.lat', 0).toString(),
                lng: _.get(assetObject, 'address.long', 0).toString(),
            },
        },
    },
    storey: _.get(assetObject, 'storey', 'single'),
    qtyfloors: _.get(assetObject, 'numberOfFloors', ''),
    gla: _.get(assetObject, 'grossLettableArea', ''),
    gba: _.get(assetObject, 'grossBuildingArea', ''),
    erfnumber: _.get(assetObject, 'erfNumber', ''),
    building_manager_id: _.get(assetObject, 'buildingManagerIds', []),
    linked_landlord_contact_id: assetObject?.landlordContactId
        ? [_.get(assetObject, 'landlordContactId')]
        : [],
    use: _.get(assetObject, 'buildingUse', ''),
    erf_size: _.get(assetObject, 'erfSize', ''),
    Built_FAR: _.get(assetObject, 'builtFar', ''),
    permissible_FAR: _.get(assetObject, 'permissibleFar', ''),
    Built_coverage: _.get(assetObject, 'builtCoverage', ''),
    permissible_coverage: _.get(assetObject, 'permissibleCoverage', ''),
    Parking_Bays_Lettable: _.get(assetObject, 'parkingBaysLettable'),
    parking_bays_public: _.get(assetObject, 'parkingBaysPublic'),
    type: _.get(assetObject, 'propertyType', ''),
    parentId: _.get(assetObject, 'parentId', ''),
    parent_ref_id: _.get(assetObject, 'parentId', ''),
    linkedto_park: _.get(assetObject, 'linkedToPark', ''),
});

export const assetUploadRequestModel = (assetUploadRequestObject) => {
    if (!assetUploadRequestObject) {
        return null;
    }

    return {
        id: _.get(assetUploadRequestObject, 'id', ''),
        createdAt: _.get(assetUploadRequestObject, 'created_at', ''),
        contact: {
            id: _.get(
                assetUploadRequestObject,
                'contacts_business_staff.id',
                ''
            ),
            firstName: _.get(
                assetUploadRequestObject,
                'contacts_business_staff.name',
                ''
            ),
            lastName: _.get(
                assetUploadRequestObject,
                'contacts_business_staff.surname',
                ''
            ),
            email: _.get(
                assetUploadRequestObject,
                'contacts_business_staff.email',
                ''
            ),
            mobileNumber: _.get(
                assetUploadRequestObject,
                'contacts_business_staff.mobileno',
                ''
            ),
            profession: _.get(
                assetUploadRequestObject,
                'contacts_business_staff.profesion',
                ''
            ),
            professionalCode: _.get(
                assetUploadRequestObject,
                'contacts_business_staff.profesional_code',
                ''
            ),
        },
        companyName: _.get(
            assetUploadRequestObject,
            'contacts_business_staff.contacts_businessname.comp_name'
        ),
        complianceTypeId: _.get(
            assetUploadRequestObject,
            'compliance_type_id',
            ''
        ),
        requester: _.get(assetUploadRequestObject, 'requester', ''),
        email: _.get(assetUploadRequestObject, 'email', ''),
        status: _.get(assetUploadRequestObject, 'status', ''),
        note: _.get(assetUploadRequestObject, 'note', ''),
    };
};

export const assetExportModel = (assetObject = {}) => {
    const idFields = [
        'rrule',
        'building_bucket_url',
        'task_documents_file_key',
        'task_documents_thumbnail_key',
        'task_documents_friendly_name',
        'task_documents_file_extension',
        'task_documents_file_mime_type',
        'task_documents_move_status',
        'task_documents_move_error',
        'documents_file_key',
        'documents_thumbnail_key',
        'documents_friendly_name',
        'documents_file_extension',
        'documents_file_mime_type',
        'documents_move_status',
        'documents_move_error',
        'repeatJobKey',
        'building_address_location_type',
        'building_images_is_featured',
        'documents_contacts_business_staff_contacts_businessname_officemobile',
        'documents_contacts_business_staff_contacts_businessname_profesion',
        'documents_contacts_business_staff_contacts_businessname_inactive',
        'created_by',
        'documents_contacts_business_staff_contacts_businessname_address_address1',
        'documents_contacts_business_staff_contacts_businessname_address_address2',
        'documents_contacts_business_staff_contacts_businessname_address_suburb',
        'documents_contacts_business_staff_contacts_businessname_address_city',
        'documents_contacts_business_staff_contacts_businessname_address_province',
        'documents_contacts_business_staff_contacts_businessname_address_postalcode',
        'documents_contacts_business_staff_contacts_businessname_address_country',
        'documents_contacts_business_staff_contacts_businessname_address_location',
        'documents_user_name',
        'documents_user_surname',
        'documents_user_email',
        'documents_user_mobileno',
        'documents_user_created_at',
    ];
    const datetimeFields = [
        'created_at',
        'due_date',
        'completed_date',
        'updated_at',
        'last_service_date',
        'next_service_date',
        'warranty_expire',
        'installation_date',
        'type_created_at',
        'service_provider_staff_created_at',
        'service_provider_staff_business_created_at',
        'documents_created_at',
        'task_updates_created_at',
        'documents_user_created_at',
        'assigned_user_created_at',
        'tasks_categories_created_at',
        'task_types_created_at',
        'building_created_at',
        'task_documents_created_at',
        'Comliance Group Created At',
        'Comliance Category Created At',
        'documents_contacts_business_staff_contacts_businessname_created_at',
        'documents_contacts_business_staff_created_at',
    ];

    const processObject = (obj) =>
        Object.entries(obj).reduce((acc, [key, value]) => {
            if (key.toLowerCase().includes('id')) return acc;
            if (idFields.includes(key)) return acc;

            if (datetimeFields.includes(key)) {
                acc[key] = value ? new Date(value).toLocaleDateString() : null;
                return acc;
            }

            if (typeof value === 'object' && value !== null) {
                acc[key] = Array.isArray(value)
                    ? value.map(processObject)
                    : processObject(value);
                return acc;
            }

            acc[key] = value;
            return acc;
        }, {});

    return processObject(assetObject);
};
