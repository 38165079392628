import networkService from '../network-service/network.service';
import documentUrls from './document-service-urls';
import {
    setDocumentUploadSuccessfullyModel,
    setUpdateDocumentModel,
    deleteDocumentModel,
} from '../../../models';
import { API_ACCESS_TOKEN_KEY } from '../../../local-storage-keys';

function getUploadUrl(assetId, fileName, thumbnailExtension) {
    const url = documentUrls.getUploadUrl();
    const data = { assetId, fileName, thumbnailExtension };
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(
                API_ACCESS_TOKEN_KEY
            )}`,
        },
    };
    return networkService.makePost(url, data, config);
}

function setDocumentUploadedSuccessfully(successfulUpload) {
    const url = documentUrls.documentUploadSuccessUrl();
    const data = setDocumentUploadSuccessfullyModel(successfulUpload);
    return networkService.makePost(url, data);
}

function getComplianceTypeDocuments(complianceTypeId) {
    const url = documentUrls.getComplianceTypeDocumentsUrl();
    const config = {
        params: {
            compliance_type_id: complianceTypeId,
            request_type: 'compliance',
        },
    };
    return networkService.makeGet(url, config);
}

function getFilesDownloadLink(files) {
    const url = documentUrls.getFilesDownloadLinkUrl();
    const data = files;
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(
                API_ACCESS_TOKEN_KEY
            )}`,
        },
    };
    return networkService.makePost(url, data, config);
}

function getFileDownloadLink(files) {
    const url = documentUrls.getFileDownloadLink();
    const data = files;
    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${localStorage.getItem(
    //             API_ACCESS_TOKEN_KEY
    //         )}`,
    //     },
    // };
    return networkService.makePost(url, data);
}

function updateDocument(document) {
    const url = documentUrls.updateDocumentFriendlyName();
    const data = setUpdateDocumentModel(document);
    return networkService.makePut(url, data);
}

function deleteDocument(document) {
    const url = documentUrls.deleteDocument();
    const data = deleteDocumentModel(document);

    return networkService.makeDelete(url, data, { params: { ...data } });
}

export default {
    getUploadUrl,
    setDocumentUploadedSuccessfully,
    getComplianceTypeDocuments,
    getFilesDownloadLink,
    updateDocument,
    deleteDocument,
    getFileDownloadLink,
};
