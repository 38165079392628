import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EditIcon, TrashIcon, Paragraph, Pane } from 'evergreen-ui';
import { useDispatch } from 'react-redux';

import EditForm from '../forms-templates/EditForm';

import {
    deleteComplianceType,
    renameComplianceType,
} from '../../../../reducers/compliance-template-reducer/compliance-template.actions';

function ComplianceTemplateType({ type, groupId, categoryName }) {
    const dispatch = useDispatch();

    const [editType, setEditType] = useState({
        isEditing: false,
        originalType: '',
        newType: '',
    });

    const handleTypeChange = ({ target: { value } }) => {
        setEditType((state) => ({
            ...state,
            newType: value,
        }));
    };

    const handleEnter = ({ key, type: buttonType }) => {
        if (buttonType === 'click' || key === 'Enter') {
            dispatch(
                renameComplianceType(
                    groupId,
                    categoryName,
                    editType.originalType,
                    editType.newType
                )
            );
            cancelEditType();
        }
    };

    const setEditingType = () => {
        setEditType({
            isEditing: true,
            originalType: type,
            newType: '',
        });
    };

    const deleteType = () => {
        dispatch(deleteComplianceType(groupId, categoryName, type));
    };

    const cancelEditType = () => {
        setEditType({
            isEditing: false,
            newType: '',
            originalType: '',
        });
    };

    return (
        <TypeWrapper>
            <Pane display="flex" paddingY={6} alignItems="center">
                {editType.isEditing ? (
                    <Pane>
                        <EditForm
                            value={type}
                            handleEdit={handleEnter}
                            cancelEdit={cancelEditType}
                            onChangeValue={handleTypeChange}
                        />
                    </Pane>
                ) : (
                    <Paragraph
                        lineHeight="40px"
                        size={400}
                        color="#474D66"
                        fontSize={12}
                    >
                        {type}
                    </Paragraph>
                )}
                {!editType.isEditing && (
                    <Pane display="flex" gap={5} marginLeft="auto">
                        <Pane display="flex" gap={10}>
                            <Pane
                                alignItems="center"
                                justifyContent="center"
                                padding={8}
                                className="edit-contact"
                                hoverElevation={1}
                                role="button"
                                onClick={setEditingType}
                            >
                                <EditIcon
                                    color="#696f8c"
                                    className="edit-contact"
                                />
                            </Pane>
                        </Pane>
                        <Pane display="flex" gap={10}>
                            <Pane
                                alignItems="center"
                                justifyContent="center"
                                padding={8}
                                className="delete-contact"
                                hoverElevation={1}
                                role="button"
                                onClick={deleteType}
                            >
                                <TrashIcon
                                    color="#c60b0b"
                                    className="delete-contact"
                                />
                            </Pane>
                        </Pane>
                    </Pane>
                )}
            </Pane>
        </TypeWrapper>
    );
}

ComplianceTemplateType.propTypes = {
    type: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
};

const TypeWrapper = styled(Paragraph)`
    cursor: pointer;
    margin-left: 20px;

    .edit-contact,
    .delete-contact {
        display: none;
        margin-left: auto;
        border-radius: 4px;
        background-color: #f5f6fa;
        cursor: pointer;
    }

    &:hover {
        .edit-contact {
            display: flex;
        }
        .delete-contact {
            display: flex;
        }
    }
`;

export default ComplianceTemplateType;
